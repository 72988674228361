import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import TestPlanBuildListPage from "./TestPlanBuildListPage";
import TestPlanBuildLogs from "./TestPlanBuildLogs";

const TestPlanBuildPage = function ({ project, testPlanId, buildId }) {
  const [currentView, setCurrentView] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (testPlanId && buildId) {
      setCurrentView("testPlanBuildLogs");
    } else if (testPlanId) {
      setCurrentView("testPlanBuildList");
    }
  }, [testPlanId, buildId]);

  let content = null;
  if (currentView === "testPlanBuildList") {
    content = (
      <TestPlanBuildListPage
        project={project}
        testPlanId={testPlanId}
      />
    );
  } else if (currentView === "testPlanBuildLogs") {
    content = (
      <TestPlanBuildLogs
        project={project}
        testPlanId={testPlanId}
        buildId={buildId}
      />
    );
  } else {
    // content = <div>Loading...</div>;
  }

  return <Box>{content}</Box>;
};

export default TestPlanBuildPage;
