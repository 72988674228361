import {createSlice} from '@reduxjs/toolkit';

const testSuiteBuildSlice = createSlice({
  name: "testSuiteBuildSlice",
  initialState: {
    isLoading: false,
    errors: {},
    total: 0,
    totalPages: 0,
    testSuiteBuildList: [],
    builds: {},
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    setBuilds: (state, action) => {
      return {
        ...state,
        builds: {
          ...state.builds,
          ...{ ...action.payload },
        },
      };
    },
    setTestSuiteBuildList: (state, action) => {
      return {
        ...state,
        total: action?.payload?.total,
        totalPages: action?.payload?.total_pages,
        testSuiteBuildList: [...action?.payload?.test_suite_builds]
      }
    }
  },
});

export const {
    updateLoadError,
    setLoadingState,
    setSavingState,
    setBuilds,
    setTestSuiteBuildList
} = testSuiteBuildSlice.actions;

export default testSuiteBuildSlice.reducer;