import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'
import MainLayout from "../../layouts/MainLayout";
import KeyValueTable from "../Common/KeyValueTable";
import EditDetail from './EditDetail';

import {
  updateVariableSet,
  getVariableSet,
} from "../../redux-store/variableSetReducers/variableSetActions";
import {
  deleteVariable,
  createVariable,
  updateVariable,
} from "../../redux-store/variableReducers/variableActions";

const VariableSet = function ({ project, variableSetId }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.variableSet.isLoading);
  const [title, setTitle] = useState("");
  const [isEnv, setIsEnv] = useState(false);
  const [variables, setVariables] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedItemContent, setSelectedItemContent] = useState(null);
  const variableSetDetail = useSelector(state => state.variableSet.selectedVariableSetDetail);

  const drawerItems = [
    { icon: 'article', title: 'Details', id: 'detail' }
  ];

  useEffect(() => {
    getVariableSetDetail();
  }, []);

  useEffect(() => {
    if (variableSetDetail) {
      const { variables: fetchedVariables, title: fetchedTitle, is_environment } = variableSetDetail;
      const formattedVariables = fetchedVariables?.map((v) => formatVariableResponse(v));
      setTitle(fetchedTitle || "");
      setIsEnv(!!is_environment)
      setVariables(formattedVariables);
    }
  }, [variableSetDetail])

  const onDeleteVariable = async (variableId) => {
    await dispatch(deleteVariable({ variableId: variableId }));

    setVariables((prev) =>
      prev.filter((variable) => variable.id !== variableId)
    );
  };

  const addNewVariable = useCallback((variable) => {
    if (variable) {
      const formattedVariable = formatVariableResponse(variable);
      setVariables((prev) => [...prev, formattedVariable]);
    }
  }, []);

  const onCreateVariable = useCallback(
    async (variable) => {
      const data = {
        resourceId: variableSetId,
        resourceType: "VariableSet",
        var_name: variable.var_name,
        var_value: variable.var_value,
      };
      const newVariableResponse = await dispatch(createVariable(data));
      addNewVariable(newVariableResponse.payload.variable);
    },
    [dispatch, variableSetId, addNewVariable]
  );

  const onSaveNewVariable = useCallback(
    (variable) => {
      if (variable.var_name && variable.var_value) onCreateVariable(variable);
    },
    [onCreateVariable]
  );

  const updateVariableValue = useCallback(
    async ({ id, var_value }) => {
      const variableResponse = await dispatch(
        updateVariable({
          variableValue: var_value,
          variableId: id,
        })
      );
      const { variable: updatedVariable } = variableResponse.payload;
      setVariables((prev) =>
        prev.map((variable) =>
          variable.id === id ? updatedVariable : variable
        )
      );
    },
    [dispatch, setVariables]
  );

  const getVariableSetDetail = async () => {
    await dispatch(getVariableSet({ variableSetId }));
  };

  const formatVariableResponse = ({ id, var_name, var_value }) => ({
    id,
    var_name,
    var_value,
    isNew: false,
    isEdit: false,
  });

  const renderDrawerItem = ({selectedItem, onDrawerClose}) => {
    const props = { projectId: project.id, variableSetId: variableSetId };
    switch (selectedItem) {
      case 'detail':
        setSelectedItemContent(<EditDetail {...props} closeDrawer={onDrawerClose} />);
        return false;
      default:
        setSelectedItemContent(null);
        return false;
    }
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText={title}
      subtitleText={isEnv ? "Environment variable set" : "Non-environment variable set"}
      drawerItems={drawerItems}
      renderDrawerItem={renderDrawerItem}
      enableSideDrawer={true}
      sx={{ width: "calc(100% - 100px)" }}
      selectedItemContent={selectedItemContent}
    >
      <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "auto", px: "24px", color: "black"}}>
        <h4>All variables</h4>
        <h6>Key must be unique • Press enter to add/update set</h6>
        <KeyValueTable
          allItems={variables}
          onUpdateItem={updateVariableValue}
          onCreateItem={onSaveNewVariable}
          onDeleteItem={onDeleteVariable}
          itemName="variable"
          sxTableContainer={{borderRadius: "0px"}}
          sxCell={{fontSize: "13px"}}
        />
        {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
      </Box>
    </MainLayout>
  );
};

export default VariableSet;
