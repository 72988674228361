import React from 'react';
import { Box, Card, CardContent, Button } from "@mui/material";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CustomButton from '../Common/CustomButton';

const DeleteScenarioModal = ({onClose, onDelete, onArchive}) => {
  return (
    <Box className="modal-overlay">
        <Card variant="outlined" sx={{width: "550px"}}>
            <CardContent>
                <div style={{width: "calc(100% - 50px)", display: "inline-block"}}>
                    <WarningAmberOutlinedIcon sx={{color: "orange", verticalAlign: "text-bottom", mr: 2}} />
                    <span style={{fontSize: "24px"}}>Delete Test Scenario? Archive instead!</span>
                </div>
                <div style={{width: "50px", display: "inline-block", textAlign: "right", verticalAlign: "top"}}>
                    <CloseIcon onClick={onClose} sx={{cursor: "pointer"}} />
                </div>
                <div style={{marginTop: "16px"}}>
                    <p>We recommend archiving Test Scenarios instead of deleting.</p>
                    <p>Archiving disables the Test Scenario from all Test Suites & Builds, but you can always access it in ‘Archived’ Test Scenarios for later reference.</p>
                    <p>Are you sure you want to permanently delete this Test Scenario?</p>
                </div>
                <div>
                    <Button variant="contained" sx={{mr: 2}} color="error" onClick={onDelete}>
                        <DeleteOutlineIcon sx={{mr: 1}} />
                        Delete Permanently
                    </Button>
                    <CustomButton variant="contained" onClick={onArchive}>
                        <Inventory2OutlinedIcon sx={{mr: 1}}/>
                        Archive
                    </CustomButton>
                </div>
            </CardContent>
        </Card>
    </Box>
  )
}

export default DeleteScenarioModal;
