import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Chip,
  Stack,
  Divider,
  Avatar,
} from "@mui/material";
import {
  ExpandMore,
  Person,
  Visibility,
  CheckCircleOutline,
  ErrorOutline,
  CallMissedOutgoingOutlined,
  HourglassTopOutlined
} from "@mui/icons-material";
import CustomButton from "../Common/CustomButton";

import "../../styles/components/Common/BuildCard.scss";

const buildNumberPrefix = {
  "testPlan": "TR-000",
  "testSuite": "TR-000",
  "testScenario": "TR-000"
}

const BuildCard = ({ build={}, onExpand, isExpanded, onViewBuild, buildType}) => {
  const renderMessage = () => {
    let msg = [];
    if (build.total_count >= 0) {
      msg.push(
        <span style={{ marginRight: "4px" }} key="total_count">
          {build.total_count} {buildType === "testScenario" ? " total cases" : " total scenarios"}
        </span>
      );
    }
    if (build.passed_count > 0) {
      msg.push(
        <span style={{ color: "#2CAC0C", marginRight: "4px" }} key="passed_count">
          {build.passed_count} passed
        </span>
      );
    }
    if (build.failed_count > 0) {
      msg.push(
        <span style={{ color: "#CE4141", marginRight: "4px" }} key="failed_count">
          {build.failed_count} failed
        </span>
      );
    }
    if (build.skipped_count > 0) {
      msg.push(
        <span style={{ color: "grey", marginRight: "4px" }} key="skipped_count">
          {build.skipped_count} skipped
        </span>
      );
    }
    return msg;
  };

  const renderAvatar = () => {
    const createdBy = build?.created_by?.name || build?.created_by?.email || "";
    if (!createdBy) return null;
    return (
      <>
        {build?.triggered_via && (
          <Divider
            sx={{ borderWidth: "1px", height: "30px", borderColor: "#4d4d4d" }}
          />
        )}
        <Avatar
          alt={createdBy}
          src={createdBy}
          sx={{ width: 24, height: 24 }}
        />
        <Typography variant="body1">{createdBy}</Typography>
      </>
    );
  };

  const renderBuildDetail = () => {
    return (
      <>
        <div className="section">
          <h5>Environment</h5>
          <Typography sx={{ textTransform: "capitalize", fontSize: "14px" }}>
            {build?.environment_set?.title}
          </Typography>
        </div>
        <div className="section">
          <h6>Source</h6>
          <Typography>{build?.source_code}</Typography>
          <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
            {build?.triggered_via && (
              <Chip
                sx={{ mr: 1, textTransform: "capitalize" }}
                label={build?.triggered_via}
              />
            )}
            {renderAvatar()}
          </Stack>
        </div>
        <div className="section">
          <h6>Start time</h6>
          <span>
            {build?.started_at ? new Date(build.started_at * 1000).toUTCString() : "-"}
          </span>
        </div>
        <div className="section">
          <h6>Execution Time</h6>
          <span>
            {build?.execution_time
              ? `${build.execution_time.toFixed(2)} seconds`
              : "-"}
          </span>
        </div>
        <div className="section">
          <h6>Message</h6>
          <div style={{ fontWeight: 600 }}>{renderMessage()}</div>
        </div>
        <div className="section">
          <CustomButton
            sx={{ mr: 2 }}
            variant="outlined"
            color="primary"
            disabled={false}
            onClick={() => {
              onViewBuild(build.id);
            }}
          >
            <Visibility sx={{ mr: 1 }} />
            View Detail
          </CustomButton>
        </div>
      </>
    );
  };

  const getStatus = (status) => {
    let content = null;
    if (status === "fail") {
      content = (
        <div style={{ color: "#CE4141", fontWeight: 600 }}>
          <ErrorOutline sx={{ color: "#CE4141", mr: 1 }} />
          Failed
        </div>
      )
    } else if (status === "pass") {
      content = (
        <div style={{ color: "#2CAC0C", fontWeight: 600 }}>
          <CheckCircleOutline sx={{ color: "#2CAC0C", mr: 1 }} />
          Passed
        </div>
      )
    } else if (status === "queued" || status === "running") {
      content = (
        <div style={{ color: "gray", fontWeight: 600 }}>
          <HourglassTopOutlined sx={{ color: "gray", mr: 1 }} />
          {status === "queued" ? "Queued" : "Running"}
        </div>
      )
    } else if (status === "skipped") {
      content = (
        <div style={{ color: "gray", fontWeight: 600 }}>
          <CallMissedOutgoingOutlined sx={{ color: "gray", mr: 1 }} />
          Skipped
        </div>
      )
    }
    return content;
  }

  return (
    <Box sx={{ px: "16px" }} className="buildCard">
      <Accordion key={build.id} sx={{ my: 1 }} expanded={isExpanded} onChange={() => {onExpand(build.id)}}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="build-content"
          id={build.id}
        >
          <Typography sx={{ width: "calc(100% - 100px)", fontWeight: 500 }}>
            {buildNumberPrefix[buildType]}{build.build_number}
          </Typography>
          {getStatus(build?.status)}
        </AccordionSummary>
        <AccordionDetails>{renderBuildDetail()}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BuildCard;
