import React, { createContext, useContext, useState } from 'react';

const GuestUserContext = createContext();

export const useGuestUser = () => useContext(GuestUserContext);

export const GuestUserProvider = ({ children }) => {
  const [isGuestUser, setIsGuestUser] = useState(false);

  return (
    <GuestUserContext.Provider value={{ isGuestUser, setIsGuestUser }}>
      {children}
    </GuestUserContext.Provider>
  );
};
