import { createSlice } from '@reduxjs/toolkit';

const variableSetSlice = createSlice({
  name: 'variableSetSlice',
  initialState: {
    isLoading: false,
    errors: {},
    total: 0,
    totalPages: 0,
    allVariableSets: [],
    selectedVariableSetDetail: {}
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    setTestRules: (state, action) => {
      return {
        ...state,
        testRules: {
          ...state.testRules,
          [action.payload.testCaseSetId]: action.payload.testRules,
        },
      };
    },
    setAllVariableSets: (state, action) => {
      return {
        ...state,
        allVariableSets: action.payload || [],
      };
    },
    updateSelectedVariableSetDetail: (state, action) => {
      return {
        ...state,
        selectedVariableSetDetail: { ...action.payload.variableSetDetail },
      };
    },
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setTestRules,
  setAllVariableSets,
  updateSelectedVariableSetDetail
} = variableSetSlice.actions;

export default variableSetSlice.reducer;
