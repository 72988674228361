import React, { useRef } from "react";
import { Box } from "@mui/material";

const JsonBody = ({ jsonData }) => {
  const contentRef = useRef(null);
  let value;
  try {
    if (!jsonData) {
      value = JSON.stringify({});
    } else if (typeof jsonData === "string") {
      const jsonParsed = JSON.parse(jsonData);
      value = JSON.stringify(jsonParsed, null, 2);
    } else {
      value = JSON.stringify(jsonData, null, 2);
    }
  } catch (e) {
    value = JSON.stringify({ response: jsonData });
  }
  return (
    <Box sx={{ p: 1 }}>
      <pre ref={contentRef}>{value}</pre>
    </Box>
  );
};

export default JsonBody;
