import { useState, useEffect, useContext } from 'react';
import {
  Box,Tabs,Tab,Typography,IconButton,TableContainer,Table,TableHead,TableBody,TableRow,TableCell,Divider,Snackbar 
} from '@mui/material';
import {
  ContentCopyRounded
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import LoadableOverlay from '../Common/LoadingOverlay';
import AppContext from '../../AppContext';
import JsonPrettyViewer from 'components/Common/JSONPrettyViewer';

const ResponseBodyComponent = ({ entity }) => {
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const [currentResponse, setCurrentResponse] = useState(entity?.response?.body) || {};
  const responseStatus = entity?.response?.status || 200;
  const responseTime = entity?.response?.time || 0;

  const [format, setSelectedFormat] = useState('JSON');
  const [selectedTab, setSelectedTab] = useState('pretty');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  const { sendClicked, setSendClicked } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentResponse(entity?.response?.body || {});
  }, [entity]);

  useEffect(() => {
    if (sendClicked) {
      setSendClicked(false);
    }
  }, [sendClicked, selectedSidebarState]);

  const tabsData = [
    { label: "Pretty", component: "pretty" },
    { label: "Raw", component: "raw" },
    { label: "Response Headers", component: "response_headers" }
  ];

  const copyToClipboard = () => {
    let content;

    if (selectedTab === 'pretty') {
      content = JSON.stringify(currentResponse, null, 2);
    } else if (selectedTab === 'raw') {
      content = typeof currentResponse === 'object' ? JSON.stringify(currentResponse) : currentResponse;
    } else if (selectedTab === 'preview') {
      content = typeof currentResponse === 'object' ? JSON.stringify(currentResponse) : currentResponse;
    } else {
      content = ''; // No content for headers tab
    }

    navigator.clipboard.writeText(content).then(() => {
      setSnackbarMessage('Copied to clipboard!'); 
      setSnackbarOpen(true); 
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); 
  };

  const getStatusColor = (status) => {
    if (status >= 200 && status < 300) {
      return { backgroundColor: '#d4edda', color: '#155724' }; // Green background for success
    } else if (status >= 400 && status < 600) {
      return { backgroundColor: '#f8d7da', color: '#721c24' }; // Red background for error
    }
    return {};
  };

  const ResponseHeadersTable = () => {
    const headers = entity?.response?.headers;

    return (
      <TableContainer sx={{ borderRadius: '5px', marginBottom: 5, overflowX: 'hidden', overflowY: 'auto' }}>
        <Table size="small" aria-label="simple" sx={{ border: '1px solid', width: '100%', tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'Inter, Sans-serif', fontWeight: '550', fontSize: '11px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Name</TableCell>
              <TableCell sx={{ fontFamily: 'Inter, Sans-serif', fontWeight: '550', fontSize: '11px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {headers && Object.entries(headers).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell sx={{ fontFamily: 'Inter, Sans-serif', fontWeight: '500', fontSize: '11px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{key}</TableCell>
                <TableCell sx={{ fontFamily: 'Inter, Sans-serif', fontWeight: '500', fontSize: '11px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box style={{ width: '100%', padding: '4px 11px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            sx={{ height: '32px', minHeight: '32px' }}
          >
            {tabsData.map((tab) => (
              <Tab
                key={tab.component}
                label={tab.label}
                value={tab.component}
                sx={{
                  textTransform: 'capitalize',
                  height: '30px',
                  minHeight: '30px',
                  color: '#575757 !important',
                  backgroundColor: 'transparent',
                  fontSize: '11px',
                  fontFamily: 'Inter, Sans-serif'
                }}
              />
            ))}
          </Tabs>

          <IconButton onClick={copyToClipboard} size="small" sx={{ marginLeft: 2 }}>
            <ContentCopyRounded fontSize="small" />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{ ...getStatusColor(responseStatus), fontFamily: 'Inter, Sans-serif', fontWeight: '500', fontSize: '11px', paddingRight: '8px', paddingLeft: '8px', paddingTop: '2px', paddingBottom: '2px', borderRadius: '4px' }}>
            Status Code: {responseStatus}
          </Typography>

          <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 'auto', height: '20px', borderColor: 'gray' }} />

          <Typography variant="body2" sx={{ fontFamily: 'Inter, Sans-serif', fontWeight: '500', fontSize: '11px' }}>
            Time: {responseTime} ms
          </Typography>
        </Box>
      </Box>

      <Box mt={2} style={{ height: '100%', maxHeight: '44vh', width: '100%', overflowY: 'auto' }}>
        {selectedTab === 'pretty' && <JsonPrettyViewer data={currentResponse} />}
        {selectedTab === 'raw' && (
        <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-all', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>
          {typeof currentResponse === 'object' ? JSON.stringify(currentResponse) : currentResponse}
        </pre> )}
        {selectedTab === 'response_headers' && (
          <Box style={{ overflowX: 'hidden' }}>
            <ResponseHeadersTable />
          </Box>
        )}
      </Box>

      <LoadableOverlay isLoading={isLoading} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ResponseBodyComponent;
