import React, { useState, useMemo, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import Request from "./Request/index";
import Response from "./Response/index";
import { AppProvider } from '../../AppContext';
import { useDispatch, useSelector } from "react-redux";
import { getEntity } from "../../redux-store/currentUserActions";

const Samples = ({ state, api, response }) => {
  const dispatch = useDispatch();
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const [sendClicked, setSendClicked] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState({});

  const entities = useSelector((state) => state.user.entities);
  const entity = useMemo(() => {
    return entities?.[selectedSidebarState?.id] || api;
  }, [selectedSidebarState?.id, entities]);

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        await dispatch(getEntity({ id: selectedSidebarState?.id }));
      } catch (error) {
        console.error('Error fetching entity:', error);
      }
    };

    if (selectedSidebarState?.id) {
      if (!entity) {
        fetchEntity();
      }

      if (!response) setSelectedResponse(api.response);
    }
  }, [selectedSidebarState, entity, dispatch]);

  return (
    <AppProvider value={{ sendClicked, setSendClicked }}>
      <Box display="flex" flexDirection="column" width="100%" maxHeight="calc(100% - 46px)">
        {/* <Box width="100%" sx={{ padding: '5px', position: 'relative', maxHeight: "calc(100% - 5px)" }}> */}
        <Box flexGrow={0} sx={{ padding: '5px' }}>
          <Request state={state} api={api} entity={entity} response={response || selectedResponse} />
          <Divider orientation='horizontal' />
        </Box>
        <Box flexGrow={1} sx={{ padding: '5px', overflow: 'auto' }}>
          <Response state={state} entity={entity} response={response || selectedResponse} />
        </Box>
        {/* </Box> */}
      </Box>
    </AppProvider>
  );
};

export default Samples;
