import React from 'react';
import { useHistory } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CTAAccountDialog = ({ open, handleClose }) => {
  const history = useHistory();

  const redirectToSignUp = () => {    
    const currentLocation = history.location.pathname + history.location.search;
    history.push(`/?redirect=${encodeURIComponent(currentLocation)}`);
  };

  const redirectToSignIn = () => {    
    const currentLocation = history.location.pathname + history.location.search;
    history.push(`/login?redirect=${encodeURIComponent(currentLocation)}`);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {"Create an Account"}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create an account to try out this collection
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ redirectToSignUp }>Sign in</Button>
        <Button onClick={ redirectToSignIn }>Sign up</Button>
      </DialogActions>
    </Dialog>
  )
};

export default CTAAccountDialog;
