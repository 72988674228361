import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import BuildHistory from "../Common/BuildHistory";

const TestSuiteBuildList = function({ testSuiteBuildList, testSuiteId, pageNumber, buildId }) {
    const isLoading = useSelector(state => state.testSuite.isLoading);
    const history = useHistory();

    useEffect(() => {
        history.push(`/test-suites/${testSuiteId}/build/?page=${pageNumber}`);
    }, [pageNumber])

    const onViewBuild = async (id) => {
        history.push(`/test-suites/${testSuiteId}/build/${id}/?page=${pageNumber}`);
    }

    const onViewSource = () => {
        history.push(`/test-suites/${testSuiteId}/`);
    }

    return (
        <Box>
            <BuildHistory buildList={testSuiteBuildList} onViewBuild={onViewBuild} onViewSource={onViewSource} isLoading={isLoading} />
        </Box>
    )
}

export default TestSuiteBuildList;
