import React, {useState, useEffect, useRef} from 'react';
import { connect, useDispatch } from 'react-redux';
import {Box, Stack, Chip} from "@mui/material";
import { LocalOffer, FiberManualRecord, Close } from '@mui/icons-material';
import CustomFilter from '../Common/CustomFilter';

import { saveAppliedFilters } from '../../redux-store/buildReportReducers/buildReportSlice';

const BuildReportFilters = ({
  sourceType="testScenario", initialFilters, defaultFilters,
  allowedFilters=["tags", "status", "environment"],
  tags, environments
}) => {
  const dispatch = useDispatch();
  const statuses = ["fail", "pass"];
  const [appliedFilters, setAppliedFilters] = useState(initialFilters);
  const isInitial = useRef(true);

  useEffect(() => {
    if (isInitial.current) {
      setAppliedFilters(initialFilters);
    }
    isInitial.current = false;
  }, []);

  const updateAppliedFilters = async (filters, reset=false) => {
    if (reset) {
      setAppliedFilters(prevState => defaultFilters);
      await dispatch(saveAppliedFilters({appliedFilters: defaultFilters, sourceType: sourceType}));
    } else {
      setAppliedFilters(prevState => ({...prevState, ...filters}));
      await dispatch(saveAppliedFilters({appliedFilters: {...appliedFilters, ...filters}, sourceType: sourceType}));
    }
  }

  const onResetFilters = () => {
    updateAppliedFilters({}, true);
  };

  const hasAppliedFilters = appliedFilters?.tags?.length || appliedFilters?.status || appliedFilters?.environmentSetId;

  return (
     <Box>
        <Stack sx={{ px: "20px" }} direction="row" spacing={1}>
          {
            allowedFilters.indexOf("tags") !== -1 ? (
              <CustomFilter
                id="tags-filter"
                label="Tags"
                icon={<LocalOffer />}
                isAdditionalFilter={false}
                searchInputPlaceholder="Select one or more options"
                options={tags || []}
                appliedFilters={appliedFilters.tags || []}
                setAppliedFilters={(tags) =>
                  updateAppliedFilters({tags: tags})
                }
              />
            ) : null
          }
          <CustomFilter
            id="status-filter"
            label="Status"
            type="radio"
            icon={<FiberManualRecord />}
            options={statuses || []}
            appliedFilter={appliedFilters.status}
            isAdditionalFilter={false}
            setAppliedFilter={(status) =>
              updateAppliedFilters({status: status})
            }
          />
          <CustomFilter
            id="environment-filter"
            label="Environment"
            type="radio"
            icon={<FiberManualRecord />}
            options={environments || []}
            isAdditionalFilter={false}
            appliedFilter={appliedFilters.environmentSetId}
            setAppliedFilter={(environmentSetId) =>
              updateAppliedFilters({environmentSetId: environmentSetId})
            }
          />
          {hasAppliedFilters ? (
            <Chip
              label="Reset all filters"
              deleteIcon={<Close />}
              onClick={onResetFilters}
              onDelete={onResetFilters}
              size="small"
              sx={{background: "rgb(229 229 229)", border: hasAppliedFilters ? "1px solid #6241d4" : "none", borderRadius: "4px", fontWeight: 500, padding: "14px 0"}}
            />
          ) : null}
        </Stack>
     </Box>
  )
}

function mapStateToProps(state, ownProps){
  const sourceType = ownProps.sourceType || "testScenario";
  const tags = state[sourceType]?.[`${sourceType}Tags`];
  const environments = state.variableSet.allVariableSets;
  return {
    ...ownProps,
    tags: tags,
    environments: environments
  }
}

export default connect(mapStateToProps)(BuildReportFilters);
