import React from 'react'
import { Box, Tab, Tabs } from '@mui/material';

const TabsComponent = ({sx={}, handleTabChange, activeTab, tabLabels, customTabStyle, activeTabStyle, tabsStyle, tabSubText=""}) => {
    const getTabStyle = (index) => ({
        textTransform: 'none',
        color: activeTab === index ? '#6241D4' : '#575757',
        // backgroundColor: activeTab === index ? '#D27FFF' : '#fff',
        width: `${Math.floor(100/tabLabels.length)}%`,
        borderColor: activeTab === index ? '#6241D4' : '#575757',
        ...customTabStyle,
        ...(activeTab === index ? activeTabStyle : {})
    });

    const tabStyle = {
        height: '32px',
        minHeight: '32px',
        textTransform: 'none',
        color: '#575757',
        backgroundColor: 'transparent',
        fontSize: "14px",
        fontWeight: 600
    };

    return (
        <Box sx={{width: "auto", ...sx}}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="grey"
                sx={{
                    marginTop: '10px',
                    height: '32px',
                    minHeight: '32px',
                    ...tabsStyle
                }}
                TabIndicatorProps={{
                    style: { background: '#6241D4' }
                }}
            >
                {tabLabels.map((label, index) => {
                    const style = {
                        ...tabStyle,
                        ...getTabStyle(index),
                    }
                    return (
                        <Tab
                            key={label}
                            label={`${label}${index === activeTab ? tabSubText : ""}`}
                            style={style}
                        />
                    )
                })}
            </Tabs>
        </Box>
    )
}

export default TabsComponent;
