import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  Typography,
  TextField,
  Button,
  Checkbox
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import './ChatWindow.css'; 
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CustomCheckbox from 'components/Common/CustomCheckbox';

const ChatWindow = ({ testScenarioData, onSendBtnClick, onGoToTestScenarioClick }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [saveForLater, setSaveForLater] = useState(false); // state for the checkbox
  const [loadingText, setLoadingText] = useState('');
  const [dotCount, setDotCount] = useState(1);
  const [loadingIndex, setLoadingIndex] = useState(0);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current && testScenarioData?.messages?.length >= 3) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [testScenarioData?.messages]);

  useEffect(() => {
    const loadingMsgs = [
      "Validating the test cases input",
      "Analyzing the test cases dependencies",
      "Optimizing... thinking faster than a quantum computer",
      "Running some magic under the hood to make this more efficient",
      "The code elves are working hard to finish this up!",
      "Good things take time, like this analysis!",
    ];

    setLoadingText(loadingMsgs[loadingIndex]);

    const interval = setInterval(() => {
      setLoadingIndex((prev) => {
        const nextIndex = (prev + 1) % loadingMsgs.length; 
        setLoadingText(loadingMsgs[nextIndex]); 
        return nextIndex;
      });
    }, 5000);
  
    const dotInterval = setInterval(() => {
      setDotCount((prev) => (prev < 3 ? prev + 1 : 1));
    }, 500);
  
    return () => {
      clearInterval(interval);
      clearInterval(dotInterval); 
    };
  }, [testScenarioData?.text_count_index, testScenarioData?.total_test_case_count]);

  const handleSend = () => {
    if (inputMessage.trim()) {
      onSendBtnClick(inputMessage.trim(), saveForLater); 
      setInputMessage('');
      setSaveForLater(false);
    }
  };

  const renderLoadingText = () => (
    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray', height: '1.5rem', margin: 0 }}>
      {loadingText}
      <span className="loading-dots">
        {[...Array(dotCount)].map((_, index) => (
          <span key={index} className={`dot dot-${index + 1}`}></span>
        ))}
      </span>
    </Typography>
  );

  const Marker = () => (
    <span style={{ marginRight: '10px' }}>
      <ChatBubbleIcon style={{ fontSize: '12px', color: 'gray' }} />
    </span>
  );
  
  const renderMessages = () => {
    if (!testScenarioData?.messages?.length) return null;
  
    return testScenarioData.messages.map((message, index) => (
      <ListItem
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center', 
          paddingLeft: '4px',
          paddingRight: '4px',
          paddingBottom: '0px',
          flexWrap: 'wrap',
        }}
      >
        <Marker />
        <Typography
          variant="body1"
          sx={{
            wordBreak: 'break-word',
            flex: 1, 
          }}
        >
          {message}
        </Typography>
      </ListItem>
    ));
  };
  
  const renderLoadingMessage = () => {
    if (!testScenarioData?.loading || testScenarioData?.messages?.length === 0) return null;
    return (
      <ListItem sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 0 }}>
        {renderLoadingText()}
      </ListItem>
    );
  };
  
  const renderUserInput = () => {
    if (!testScenarioData?.user_input_needed) return null;
  
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          padding: '0 8px', 
          marginTop: '12px', 
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
          }}
        >
          <TextField
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            variant="outlined"
            placeholder="Enter required information..."
            fullWidth
            sx={{
              marginRight: 1,
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e0e0e0', 
                  borderWidth: '1px', 
                },
                '&:hover fieldset': {
                  borderColor: '#e0e0e0', 
                  borderWidth: '1px', 
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#e0e0e0', 
                  borderWidth: '1px', 
                },
              },
              '& .MuiInputBase-root': {
                height: '40px',
              },
            }}
          />
          <Button 
            variant="contained" 
            sx={{
              backgroundColor: '#e0e0e0', 
              borderRadius: '4px',
              height: '40px', 
              width: '48px', 
              minWidth: '0', 
              padding: '0', 
              marginLeft: '6px', 
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
            onClick={handleSend}
          >
            <SendIcon sx={{ color: 'primary.main' }} /> 
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
  <Checkbox
    checked={saveForLater}
    onChange={(e) => setSaveForLater(e.target.checked)}
    sx={{
      padding: 0,
      color: 'gray', // Set the color for the unchecked state (outline)
      '&.Mui-checked': {
        color: 'primary.main', // Fill color when checked (primary color)
        border: '1px solid primary.main', // Add a primary border when checked
      },
      '&:hover': {
        bgcolor: 'transparent', // Remove hover effect on checkbox itself
      }
    }}
  />
  <Typography sx={{ color: 'text.secondary', ml: 0.4 }}>
    Save this response in the AI memory
  </Typography>
</Box>
       
      </Box>
    );
  };
  

  const renderChatContent = () => {
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          width: '100%', 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box 
          sx={{ 
            flex: 1, 
            overflowY: 'auto', 
            maxHeight: '600px',
            backgroundColor: '#ffffff',
            padding: '0', 
          }}
        >
          <List sx={{ width: '100%', padding: 0, marginTop: '8px' }}>
            {renderMessages()}
            {renderLoadingMessage()}
            {testScenarioData?.messages?.length >= 3 && (
              <ListItem ref={messagesEndRef} sx={{ paddingLeft: 0, paddingTop: '0', paddingBottom: '0' }} />
            )}
          </List>
        </Box>
        {renderUserInput()}
        {renderGoToTestScenarioButton()}
      </Paper>
    );
  };

  const renderGoToTestScenarioButton = () => {
    if (testScenarioData?.user_input_needed || testScenarioData?.loading) return null;

    return (
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '4px', 
          marginTop: '10px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onGoToTestScenarioClick}
          sx={{
            textTransform: 'none',
          }}
        >
          Go to Test Scenario
        </Button>
      </Box>
    );
  };

  return (
    <>
      {testScenarioData?.loading && (!testScenarioData?.messages || testScenarioData.messages.length === 0) 
        ? (
          <Box sx={{ textAlign: 'left', paddingTop: 2 }}>
            {renderLoadingText()}
          </Box>
        ) 
        : renderChatContent()}
    </>
  );
};

export default ChatWindow;
