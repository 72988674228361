import React from "react";
import {Box} from "@mui/material"

const APIDocs = ({apiDocs}) => {
  return (
    <Box sx={{py: 2, maxHeight: "80vh", overflow: "auto", pre: {"span, p, div, label, li, h1, h2, h3, h4, h5, h6, td, th, tr, input, input *, button, div *, strong": {fontFamily: "Inter, sens-serif !important"}}}}>
      <pre dangerouslySetInnerHTML={{ __html: apiDocs }} style={{ whiteSpace: 'pre-wrap' }} />
      {/* {apiDocs ? apiDocs : "No documentation found!"} */}
    </Box>
  )
}

export default APIDocs;
