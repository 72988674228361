import React, { useState, useRef } from "react";
import { Button, Typography, Box } from '@mui/material';

const Binary = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <Button 
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        size="small"
        style={{ marginLeft: '10px' }}
      >
        Select File
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {selectedFile && (
        <Typography variant="body1" style={{ marginTop: '10px' }}>
          Selected file: {selectedFile.name}
        </Typography>
      )}
    </Box>
  );
};

export default Binary;
