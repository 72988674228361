import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {ArrowDropDown, ArrowRight, KeyboardDoubleArrowRight} from "@mui/icons-material";

const BuildLogStep = ({
  allLogs,
  logId,
  onExpandLog,
  renderChildren,
  isExpanded,
}) => {
  const buildLogs = allLogs; //useSelector(state => state.testScenarioBuild.buildLogs);
  const logs = buildLogs?.[logId];
  const onExpand = (event, id) => {
    onExpandLog(event, id);
  };
  const renderExpandIcon = () => {
    if (!logs.isExpanded) {
      return (
        <ArrowRight
          onClick={(event) => {
            onExpand(event, logs.id);
          }}
          sx={{ cursor: "pointer", height: "30px", width: "30px" }}
        />
      );
    }
    return (
      <ArrowDropDown
        onClick={(event) => {
          onExpandLog(event, logs.id);
        }}
        sx={{ cursor: "pointer", height: "30px", width: "30px" }}
      />
    );
  };

  return (
    <div style={{ padding: "0px", wordWrap: "break-word" }}>
      {logs?.has_child_logs && renderExpandIcon()}
      {!logs?.has_child_logs && <KeyboardDoubleArrowRight sx={{ cursor: "pointer", height: "16px", width: "16px", ml: 2, mr: 1 }} />}
      {/* <SyntaxHighlighter> */}
      <span>{logs?.log_message}</span>
      {/* </SyntaxHighlighter> */}
      {logs?.isExpanded &&
        logs?.childLogIds &&
        renderChildren(logs?.childLogIds)}
    </div>
  );
};

export default BuildLogStep;
