import React, {useEffect, useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, TextField, Tabs, Tab, Typography} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import MainLayout from '../../layouts/MainLayout';
import EntitySelection from '../TestScenarios/EntitySelection.jsx';
import QueryParams from "../Apis/QueryParamsComponent.jsx";
import HeadersComponent from "../Apis/HeadersComponent.jsx";
import BodyComponent from "../Apis/BodyComponent.jsx";
import {LabelButton} from "../Common/CustomButton.jsx";

import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import {getDataSet, createDataSet, updateDataSet} from "../../redux-store/dataSetReducers/dataSetActions.js";
import { fetchFlatCollectionEntities, getEntity, fetchProjectCollections } from '../../redux-store/currentUserActions';
import coreUtils from '../../utils/coreUtils.js';

const DataSetDetailPage = ({projectId, dataSetId, actionType}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {openSnackbar} = useSnackbar();
  const [dataSetDetail, setDataSetDetail] = useState({});
  const savedDetail = useRef({});
  const initialMount = useRef(true);
  const isLoading = useSelector(state => state.dataSet.isLoading);
  const isCollectionsLoading = useSelector(state => state.user.isLoading);
  const [collection, setCollection] = useState(null);
  const [entity, setEntity] = useState(null);
  const collections = useSelector(state => (state.user.collections));
  const allCollectionEntities = useSelector(state => (state.user.flatCollectionEntities));
  const [isNew, setIsNew] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState({});
  const tabLabels = ["Params", "Headers", "Body"];
  const defaultHeaders = [
    { name: "Accept", value: "*/*", readOnly: true },
    { name: "Accept-Encoding", value: "identity", readOnly: true },
    { name: "Connection", value: "keep-alive", readOnly: true },
  ];
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      if (dataSetId && dataSetId !== "new-data-set") {
        getDataSetDetail();
        setIsNew(false);
      } else {
        setIsNew(true);
      }
      if (!collections || collections?.length === 0) {
        getCollections();
      }
    }
  }, []);

  useEffect(() => {
    if (dataSetId && dataSetId !== "new-data-set") {
      getDataSetDetail();
      setIsNew(false);
    } else {
      setIsNew(true);
    }
    if (!collections || collections?.length === 0) {
      getCollections();
    }
  }, [dataSetId]);

  const getCollections = async () => {
    await dispatch(fetchProjectCollections(projectId));
  }

  const getEntities = async (collectionId) => {
    if (!allCollectionEntities || !allCollectionEntities[collectionId])
      await dispatch(fetchFlatCollectionEntities(collectionId));
  }

  const onCollectionChange = async (event, newValue) => {
    setCollection(newValue);
    setEntity(null);
    setErrors({});
    handleReset(true);
    if (newValue) {
      getEntities(newValue.id);
    }
  }

  const onEntityChange = async (event, newValue) => {
    // if (collection?.id) getEntities(collection.id);
    setEntity(newValue);
    setErrors({});
    if (!newValue) {
      handleReset(false);
      setIsModified(false);
      return;
    }
    const response = await dispatch(getEntity({id: newValue?.id}));
    if (response?.payload) {
      const requestDetail = response.payload?.request || {};
      setInitialState({...requestDetail});
      setIsModified(false);
    } else {
      openSnackbar({ message: response?.payload?.error || "Api fetching failed!", severity: 'error' });
    }
  }

  const getDataSetDetail = async () => {
    const response = await dispatch(getDataSet({projectId: projectId, dataSetId: dataSetId}));
    if (response?.payload) {
      setEntity(response.payload?.entity);
      setInitialState(response.payload);
    } else {
      openSnackbar({ message: response?.payload?.error, severity: 'error' });
    }
  }

  const setInitialState = (requestDetail) => {
    const detail = {
      body: requestDetail.body,
      bodyType: requestDetail.body_type,
      params: requestDetail?.params,
      headers: requestDetail?.headers || [...defaultHeaders],
      url: requestDetail?.url || requestDetail?.entity?.url || "",
      method: requestDetail?.request_type || requestDetail?.entity?.request_type,
      title: requestDetail?.title || "",
      entityId: requestDetail?.id
    };
    savedDetail.current = {...detail};
    setDataSetDetail(prevState => ({...detail}));
    setIsModified(false);
  }

  const handleUpdate = async () => {
    if (coreUtils.isStringInvalidOrBlank(dataSetDetail.title)) {
      setErrors({title: "Required"});
    }
    const newDetails = {
      body: dataSetDetail.body,
      bodyType: dataSetDetail.bodyType,
      params: dataSetDetail.params,
      headers: dataSetDetail.headers,
      title: dataSetDetail.title,
      id: dataSetId
    }
    const response = await dispatch(updateDataSet({...newDetails}));
    if (response?.payload) {
      openSnackbar({ message: "Data set updated successfully!", severity: 'success' });
      setInitialState(response.payload);
      // history.push(`/data-sets/${response.payload.id}`);
    } else {
      openSnackbar({ message: "Data set updation failed!", severity: 'error' });
    }
  }

  const handleClickCreate = async () => {
    if (coreUtils.isStringInvalidOrBlank(dataSetDetail.title)) {
      setErrors({title: "Required"});
      return;
    }
    const newDetails = {
      body: dataSetDetail.body,
      body_type: dataSetDetail.bodyType,
      params: dataSetDetail.params,
      headers: dataSetDetail.headers,
      title: dataSetDetail.title,
      entityId: entity?.id
    }
    const response = await dispatch(createDataSet({...newDetails}));
    if (response?.payload) {
      openSnackbar({ message: "Data set created successfully!", severity: 'success' });
      initialMount.current = true;
      history.push(`/data-sets/${response.payload.id}`);
    } else {
      openSnackbar({ message: "Data set creation failed!", severity: 'error' });
    }
  }

  const handleReset = (isResetBlank) => {
    if (isResetBlank) {
      setErrors({});
      const blankDetail = {
        body: null,
        bodyType: null,
        params: [],
        headers: [...defaultHeaders],
        url: "",
        method: "",
        title: ""
      }
      savedDetail.current = blankDetail;
      setDataSetDetail({...blankDetail});
      setIsModified(false);
    } else {
      setErrors({});
      setDataSetDetail({...savedDetail?.current});
      setIsModified(false);
    }
  }

  const checkIfModified = (oldDetail, newDetail) => {
    let isParamsModified = coreUtils.isDifferentArray(
      oldDetail?.params,
      newDetail?.params,
      true
    );
    let isHeadersModified = coreUtils.isDifferentArray(
      oldDetail?.headers,
      newDetail?.headers
    );
    let isBodyModified = coreUtils.isDifferentJson(
      oldDetail?.body || {},
      newDetail?.body || {}
    );
    let isBodyTypeModified = (oldDetail?.body_type || "") !== (newDetail?.bodyType || "");
    const oldTitle = oldDetail?.title;
    const newTitle = newDetail?.title;
    let isTitleModified = (oldTitle?.trim() || "") !== (newTitle?.trim() || "");

    const isDataSetModified = isParamsModified || isHeadersModified || isBodyModified || isBodyTypeModified || isTitleModified;

    setIsModified(isDataSetModified);
  };

  const handleParamsChange = (newParams) => {
    setErrors({});
    setDataSetDetail(prevState => ({
      ...prevState,
      params: [...newParams]
    }));
    checkIfModified(savedDetail?.current, {
      ...dataSetDetail,
      params: [...newParams]
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleHeadersChange = async (validHeaders) => {
    setErrors({});
    setDataSetDetail(prevState => ({
      ...prevState,
      headers: [...validHeaders]
    }));
    checkIfModified(savedDetail?.current, {
      ...dataSetDetail,
      headers: [...validHeaders]
    });
  };

  const handleTitleChange = (e) => {
    setErrors({});
    setDataSetDetail(prevState => ({
      ...prevState,
      title: e.target.value
    }))
    checkIfModified(savedDetail?.current, {
      ...dataSetDetail,
      title: e.target.value
    });
  }

  const handleBodyChange = async (body, bodyType) => {
    setErrors({});
    setDataSetDetail(prevState => ({
      ...prevState,
      body: body,
      bodyType: bodyType
    }));
    checkIfModified(savedDetail?.current, {
      ...dataSetDetail,
      body: body,
      bodyType: bodyType
    });
  };

  const getTabStyle = (index) => ({
    textTransform: "none",
    color: activeTab === index ? "#fff" : "#575757",
    backgroundColor: activeTab === index ? "#D27FFF" : "#fff",
  });

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <QueryParams
            initialParams={dataSetDetail?.params}
            onParamsChange={handleParamsChange}
            skipOutsideClickCheck={true}
            hideExtraFields={true}
          />
        );
      case 1:
        return (
          <HeadersComponent
            headers={dataSetDetail?.headers}
            onHeadersChange={handleHeadersChange}
            skipOutsideClickCheck={true}
            hideExtraFields={true}
          />
        );
      case 2:
        return (
          <BodyComponent
            body={dataSetDetail?.body}
            bodyType={dataSetDetail?.bodyType}
            onBodyChange={handleBodyChange}
            skipOutsideClickCheck={true}
            hideExtraFields={true}
          />
        );
      default:
        return null;
    }
  };

  const tabStyle = {
    height: "32px",
    minHeight: "32px",
    textTransform: "none",
    color: "#575757",
    backgroundColor: "transparent",
  };

  let collectionEntities = [];
  if (collection && allCollectionEntities && allCollectionEntities[collection.id]) {
    collectionEntities = allCollectionEntities[collection.id];
  }

  return (
    <MainLayout
      isLoading={isLoading}
      headerText={dataSetDetail?.title?.trim() || "Data set"}
      subtitleText=""
    >
      <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "auto"}}>
          {isNew ? (
            <Box sx={{p: 2}}>
              <h5 style={{ color: "black" }}>Select entity</h5>
              <EntitySelection
                collections={collections}
                entities={collectionEntities}
                selectedEntity={entity}
                selectedCollection={collection}
                onCollectionChange={onCollectionChange}
                onEntityChange={onEntityChange}
                isDisabled={isCollectionsLoading}
              />
            </Box>
          ) : null}
        {
          (isNew ? entity : true) && (
            <>
              <h5 style={{ color: "black", padding: "0px 16px 8px 16px"}}>Data Set</h5>
              <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                <Button variant="outlined" color="inherit" endIcon={<ArrowDropDown />} sx={{mr: 2}}>
                  {dataSetDetail?.method || ""}
                </Button>
                <TextField
                  value={dataSetDetail?.url}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={true}
                />
              </Box>
              <Box className="testCaseDataSet" sx={{px: 2}}>
                <Typography>Title</Typography>
                <TextField
                  value={dataSetDetail?.title}
                  onChange={handleTitleChange}
                  variant="outlined"
                  size="small"
                  sx={{width: '50%'}}
                  error={errors.title}
                  helperText={errors.title}
                />
                <div className="actions">
                  {!isNew && (
                    <LabelButton className="labelButton primary" onClick={handleUpdate} disabled={!isModified}>
                      Save
                    </LabelButton>
                  )}
                  <LabelButton className="labelButton primary" onClick={handleClickCreate} disabled={!isModified}>
                    Save As
                  </LabelButton>
                  <LabelButton className="labelButton primary" onClick={handleReset} disabled={!isModified}>
                    Reset
                  </LabelButton>
                </div>
              </Box>
              <Box sx={{px: 2}}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  sx={{
                    marginTop: "10px",
                    height: "32px",
                    minHeight: "32px",
                    border: "1px solid #e9e9e9",
                    borderRadius: "5px",
                  }}
                >
                  {tabLabels.map((label) => (
                    <Tab
                      key={label}
                      label={label}
                      sx={getTabStyle(label)}
                      style={tabStyle}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  "&::-webkit-scrollbar": { display: "none" },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  p: 2
                }}
              >
                {renderTabContent()}
              </Box>
            </>
          )
        }
      </Box>
    </MainLayout>
  )
}

export default DataSetDetailPage;
