import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';
import { setOrganisations } from 'reducers/commonSlice';
import { updateCurrentUser } from './currentUserSlice';


export const isLoggedIn = createAsyncThunk(
  'app/isLoggedIn',
  async (_, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");
    if(!token) return false;
    if(!storedUser) return false;

    const parsedUser = JSON.parse(storedUser);
    if(!parsedUser?.id) return false;

    try {
      const response = await apiAgent({
        method: 'get',
        url: '/dashboard',
      });
      dispatch(setOrganisations(response.data));
      dispatch(updateCurrentUser(parsedUser));
      return true;
    } catch (err) {
      if (err.response.data.error == 'User not found'){
        return false;
      } else {
      }
    }
  }
);

export const fetchOrganisations = createAsyncThunk(
  'dashboard/fetchOrganisations',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'get',
        url: '/dashboard'
      });
      dispatch(setOrganisations(response.data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
