import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Alert,
  RadioGroup,
  Radio,
  FormControlLabel,
  Autocomplete,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Inventory2Outlined,
  ArticleOutlined,
  CreateOutlined,
  FiberManualRecord,
  DeleteOutline,
  SaveOutlined,
  OpenInNew,
  Close,
  Person,
} from "@mui/icons-material";
import LoadingOverlay from "../Common/LoadingOverlay";
import CustomButton from "../Common/CustomButton";
import DeleteDialog from "../Common/DeleteDialog";
import CustomChip from "../Common/CustomChip";
import CustomCheckbox from "../Common/CustomCheckbox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  updateTestPlan,
  deleteTestPlan,
} from "../../redux-store/testPlanReducers/testPlanActions";
import { getOwners } from "../../redux-store/currentUserActions";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import coreUtils from "../../utils/coreUtils";

import "../../styles/components/Common/EditDetail.scss";

const notificationActions = [
  { id: "pass", label: "Passed", disabled: false },
  { id: "fail", label: "Failed", disabled: false },
  { id: "queued", label: "Queued", disabled: false },
  { id: "not_executed", label: "Not Executed", disabled: true },
  { id: "stopped", label: "Stopped", disabled: true },
  { id: "running", label: "Running", disabled: true },
];

const notificationTypes = [
  { id: "email", label: "Email", disabled: false },
  { id: "slack", label: "Slack", disabled: false }
];


export function BasicDateTimePicker({ value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DateTimePicker
          sx={{ width: "100%", ".MuiOutlinedInput-root" : {height: "36px"} }}
          value={value}
          onChange={onChange}
          size="small"
          variant="outlined"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

const EditDetail = ({ projectId, testPlanId, closeDrawer }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const [detail, setDetail] = useState({
    frequency: "",
    startTime: null,
    timeGap: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const isLoading = useSelector((state) => state.testPlan.isLoading);
  const isSaving = useSelector((state) => state.testPlan.isSaving);
  const allUsers = useSelector((state) => state.user.owners) || [];
  const testPlanDetail = useSelector(
    (state) => state.testPlan.selectedTestPlanDetail
  );
  const frequencyItems = [
    { value: "hourly", display: "Hourly" },
    { value: "daily", display: "Daily" },
    { value: "weekly", display: "Weekly" },
    { value: "custom", display: "Custom" },
  ];
  const allStatuses = [
    {
      value: "draft",
      display: "Draft",
      icon: <CreateOutlined sx={{ color: "orange" }} />,
    },
    {
      value: "active",
      display: "Active",
      icon: <FiberManualRecord sx={{ color: "green" }} />,
    },
    { value: "archived", display: "Archived", icon: <DeleteOutline /> },
  ];

  useEffect(() => {
    console.log("testPlanDetail", testPlanDetail);
    getUsers();
  }, []);

  useEffect(() => {
    setInitialState();
  }, [testPlanDetail]);

  const setInitialState = () => {
    const detailObj = {
      title: testPlanDetail?.title || "",
      status: testPlanDetail?.status || "draft",
      notifiableActions: testPlanDetail?.notifiable_actions || [],
      notificationEnabled: testPlanDetail?.notifications_enabled,
      notifiableUsers: [...testPlanDetail?.notifiable_users],
      notificationTypes: testPlanDetail?.notification_types || [],
      frequency: testPlanDetail?.frequency || undefined,
      startTime: testPlanDetail?.start_time
        ? dayjs(
            testPlanDetail.start_time
              ? testPlanDetail.start_time * 1000
              : Date()
          )
        : dayjs(Date()),
      timeGap: testPlanDetail?.time_gap
        ? coreUtils.parseMinutes(testPlanDetail.time_gap)
        : { days: 0, hours: 0, minutes: 0 },
    };
    setDetail(detailObj);
  };

  const getUsers = async () => {
    await dispatch(getOwners({ projectId: projectId }));
  };

  const updateTestPlanDetail = async (data) => {
    const response = await dispatch(updateTestPlan({ testPlanId, data }));
    if (response?.payload) {
      openSnackbar({
        message: "Test plan detail updated successfully.",
        severity: "success",
      });
      setIsEdit(false);
    } else {
      openSnackbar({
        message: "Test plan detail update failed!",
        severity: "error",
      });
    }
  };

  const onChangeTimeGap = (e, name) => {
    resetErrors(["timeGap"]);
    setDetail((prevState) => ({
      ...prevState,
      timeGap: {
        ...prevState.timeGap,
        [name]: e.target.value,
      },
    }));
  };

  const onChangeStartTime = (e) => {
    resetErrors(["startTime"]);
    setDetail((prevState) => ({
      ...prevState,
      startTime: e,
    }));
  };

  const onChange = (name, value) => {
    resetErrors([[name]]);
    setDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = {};
    if (!detail?.title || !detail.title.trim()) errors.title = "Required";
    if (!detail?.frequency) errors.frequency = "Required";
    if (!detail?.startTime) errors.startTime = "Required";
    if (
      detail?.notificationEnabled &&
      detail?.notifiableActions?.length === 0
    ) {
      errors.notifiableActions = "Required";
    }

    if ( detail?.notificationEnabled && detail?.notificationTypes?.length === 0) {
      errors.notificationTypes = "Required";
    }

    if (detail?.notificationEnabled && detail?.notifiableUsers?.length === 0) {
      errors.notifiableUsers = "Required";
    }
    if (detail?.frequency === "custom") {
      const timeGap = detail?.timeGap;
      let isValidTimeGap = false;
      for (const k of Object.keys(timeGap)) {
        if (timeGap[k] === undefined) {
          isValidTimeGap = true;
        } else if (
          !isNaN(timeGap[k]) &&
          Number.isInteger(timeGap[k] * 1) &&
          Math.sign(timeGap[k] * 1) >= 0
        ) {
          isValidTimeGap = true;
        } else {
          isValidTimeGap = false;
        }
      }
      if (!isValidTimeGap) {
        errors.timeGap = "Invalid";
      }
    }
    return errors;
  };

  const formatDetail = () => {
    const data = {};
    data.title = detail?.title.trim();
    data.start_time = Math.floor(new Date(detail?.startTime).getTime() / 1000);
    data.frequency = detail?.frequency;
    if (detail?.frequency === "custom") {
      const timeGap = detail?.timeGap;
      data.time_gap =
        (timeGap.days ? timeGap.days * 24 * 60 : 0) * 1 +
        (timeGap.hours ? timeGap.hours * 60 : 0) * 1 +
        (timeGap.minutes ? timeGap.minutes : 0) * 1;
    }
    data.notifications_enabled = detail?.notificationEnabled;
    data.notifiable_actions = [];
    data.notifiable_user_ids = [];
    if (data.notifications_enabled) {
      data.notifiable_actions = detail?.notifiableActions;
      data.notifiable_user_ids = detail?.notifiableUsers?.map((user) => user?.id);
      data.notification_types = detail?.notificationTypes;
    }
    data.status = detail?.status;
    return data;
  };

  const resetErrors = (keys) => {
    const validationErrors = { ...errors };
    for (const k of keys) {
      delete validationErrors[k];
    }
    setErrors(validationErrors);
  };

  const onSave = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const data = formatDetail();
      updateTestPlanDetail(data);
    } else {
      setErrors(validationErrors);
    }
  };

  const onEdit = () => {
    setInitialState();
    setIsEdit(true);
  };

  const toggleArchiveModal = () => {
    setShowArchiveModal((prevState) => !prevState);
  };

  const onArchiveTestPlan = async () => {
    const response = await dispatch(
      updateTestPlan({
        testPlanId,
        data: { status: "archived" },
      })
    );

    if (response?.payload) {
      openSnackbar({
        message: "Test plan archived successfully.",
        severity: "success",
      });
      toggleArchiveModal();
    } else {
      openSnackbar({ message: "Test plan archive failed!", severity: "error" });
    }
  };

  const onDeleteTestPlan = async () => {
    await dispatch(deleteTestPlan({ testPlanId }));
    setAlertMessage("Test plan deleted successfully.");
    closeDrawer();
    history.push("/test-plans");
  };

  const onCancelEdit = () => {
    setIsEdit(false);
    setInitialState();
  };

  const handleSwitchChange = () => {
    setDetail((prevState) => ({
      ...prevState,
      notificationEnabled: !prevState.notificationEnabled,
    }));
  };

  const handleNotificationSelect = (id) => {
    setDetail((prevState) => {
      let notifiableActions;
      if (prevState.notifiableActions.indexOf(id) === -1) {
        notifiableActions = [...prevState.notifiableActions, id];
      } else {
        notifiableActions = prevState.notifiableActions.filter(
          (nId) => nId !== id
        );
      }
      return {
        ...prevState,
        notifiableActions: notifiableActions,
      };
    });
  };

  const handleNotificationTypeSelect = (id) => {
    setDetail((prevState) => {
      let notificationTypes;
      if (prevState.notificationTypes.indexOf(id) === -1) {
        notificationTypes = [...prevState.notificationTypes, id];
      } else {
        notificationTypes = prevState.notificationTypes.filter(
          (nId) => nId !== id
        );
      }
      return {
        ...prevState,
        notificationTypes: notificationTypes,
      };
    });
  };

  const handleUserChecked = (event, checkUsers) => {
    setDetail((prevState) => {
      return {
        ...prevState,
        notifiableUsers: checkUsers,
      };
    });
  };

  const onStatusChange = (event, newValue) => {
    setDetail((prevState) => ({
      ...prevState,
      status: newValue,
    }));
  };

  const renderStartTime = () => {
    if (!isEdit) {
      return (
        <>
          <Typography sx={{ mt: "14px", fontWeight: 450 }}>Start Time</Typography>
          <Typography sx={{ mb: "8px", mt: "4px", textTransform: "capitalize" }}>{testPlanDetail?.start_time ? (new Date(testPlanDetail?.start_time * 1000)).toUTCString() : ""}</Typography>
        </>
      )
    }
    return (
      <Grid item xs={12}>
        <Typography sx={{ mt: "16px", fontWeight: 450 }}>Start Time</Typography>
        <BasicDateTimePicker
          value={detail?.startTime}
          onChange={onChangeStartTime}
          className="dataPicker"
        />
      </Grid>
    )
  }

  const renderFrequency = () => {
    if (!isEdit) {
      return (
        <>
          <Typography sx={{ mt: "14px", fontWeight: 450 }}>Frequency</Typography>
          <Typography sx={{ mb: "8px", mt: "4px", textTransform: "capitalize" }}>{testPlanDetail?.frequency}</Typography>
        </>
      )
    }
    return (
      <>
        <Typography sx={{ mb: "8px", mt: "14px", fontWeight: 450 }}>Frequency</Typography>
        <FormControl sx={{ width: "100%" }} className="side-drawer-popover">
          <Select
            labelId="frequency-label"
            id="frequency-label"
            value={detail?.frequency}
            onChange={(e) => {
              onChange("frequency", e.target.value);
            }}
            className="side-drawer-popover"
            variant="outlined"
            sx={{ height: "36px" }}
          >
            {frequencyItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!errors.frequency && (
          <Typography
            color="error"
            sx={{
              fontSize: "0.75rem",
              marginLeft: "14px",
              lineHeight: 1.66,
              marginTop: "4px",
            }}
          >
            {errors.frequency}
          </Typography>
        )}
      </>
    )
  };

  const renderTimeGap = () => {
    if (!isEdit && !testPlanDetail?.time_gap) {
      return null;
    }
    const divStyle = {
      width: "30%",
      display: "inline-block",
      marginRight: "8px",
    };
    const TextFieldStyle = { mr: 2, width: "100%" };

    if (!isEdit) {
      const timeObj = coreUtils.parseMinutes(testPlanDetail?.time_gap) || {};
      let arr = [];
      Object.keys(timeObj).map(k => {
        arr.push(timeObj[k] < 10 ? ("0" + timeObj[k]) : timeObj[k]);
      })
      return (
        <>
          <Typography sx={{ mt: "14px", fontWeight: 480 }}>Time Gap</Typography>
          <Typography sx={{ mb: "8px", mt: "4px"}}>{arr.join(" : ")}</Typography>
        </>
      )
    }

    return (
      <Grid item xs={12} sx={{ maxWidth: "250px", mt: "12px" }}>
        <Typography sx={{ mb: "8px", mt: "14px", fontWeight: 450 }}>Time Gap</Typography>
        <div style={divStyle}>
          <InputLabel id="days-label">Days</InputLabel>
          <TextField
            size="small"
            sx={TextFieldStyle}
            id="days-label"
            placeholder="Days"
            variant="outlined"
            name="Days"
            value={detail?.timeGap?.days}
            onChange={(e) => {
              onChangeTimeGap(e, "days");
            }}
            error={!!errors.timeGap}
            helperText={errors.timeGap || ""}
          />
        </div>
        <div style={divStyle}>
          <InputLabel id="hours-label">Hours</InputLabel>
          <TextField
            size="small"
            sx={TextFieldStyle}
            id="hours-label"
            placeholder="Hours"
            variant="outlined"
            name="Hours"
            value={detail?.timeGap?.hours}
            onChange={(e) => {
              onChangeTimeGap(e, "hours");
            }}
            error={!!errors.timeGap}
            helperText={errors.timeGap || ""}
          />
        </div>
        <div style={divStyle}>
          <InputLabel id="minutes-label">Mins</InputLabel>
          <TextField
            size="small"
            sx={TextFieldStyle}
            id="minutes-label"
            placeholder="Minutes"
            variant="outlined"
            name="Minutes"
            value={detail?.timeGap?.minutes}
            onChange={(e) => {
              onChangeTimeGap(e, "minutes");
            }}
            error={!!errors.timeGap}
            helperText={errors.timeGap || ""}
          />
        </div>
      </Grid>
    );
  };

  if (isLoading) {
    return <LoadingOverlay isLoading={isLoading} />;
  }

  let content = null;
  content = (
    <>
      <div className="section">
        <h6>Title</h6>
        {isEdit ? (
          <TextField
            sx={{ mb: 2, width: "100%" }}
            size="small"
            variant="outlined"
            value={detail?.title}
            onChange={(e) => {
              onChange("title", e.target.value);
            }}
          />
        ) : (
          <Typography>{testPlanDetail?.title}</Typography>
        )}
        {errors?.title && (
          <Typography sx={{ color: "red" }}>{errors.title}</Typography>
        )}
      </div>
      <div className="section">
        <h6>Status</h6>
        <RadioGroup
          aria-labelledby="status-radio-buttons-group-label"
          onChange={onStatusChange}
          value={isEdit ? detail.status : testPlanDetail.status}
        >
          {!isEdit && (
            allStatuses.filter(
              (status) => status.value === testPlanDetail.status
            )
            ).map((status) => (
              <div>
                {status.icon}
                {status.display}
              </div>
            )
          )}
          {isEdit && allStatuses.map((status) => (
            <FormControlLabel
              key={status.value}
              sx={{ height: "30px" }}
              value={status.value}
              control={<Radio />}
              label={
                <>
                  {status.icon}
                  {status.display}
                </>
              }
            />
          ))}
        </RadioGroup>
      </div>
      <div className="section">
        <h6>Schedule Plan Build</h6>
        <>
          {renderFrequency()}
          {renderStartTime()}
          {detail?.frequency === "custom" && renderTimeGap()}
        </>
      </div>
      <div className="section notifications">
        <h6>
          Send Notifications{" "}
          <Switch
            checked={
              isEdit
                ? detail.notificationEnabled
                : testPlanDetail?.notifications_enabled
            }
            onChange={handleSwitchChange}
            disabled={!isEdit}
          />
        </h6>
        {detail.notificationEnabled && isEdit && (
          <>
            <p style={{ fontWeight: "450" }}>
              {isEdit
                ? "Select notification actions"
                : "Selected notification actions"}
            </p>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              sx={{ mb: 2 }}
            >
              {(isEdit
                ? notificationActions
                : notificationActions.filter(
                    (type) =>
                      testPlanDetail?.notifiable_actions.indexOf(type.id) !== -1
                  )
              ).map((type) => {
                const selected =
                  detail.notifiableActions.indexOf(type.id) !== -1;
                const props = { selected: selected };
                if (isEdit) {
                  props.onClick = () => {
                    handleNotificationSelect(type.id);
                  };
                }
                return (
                  <CustomChip
                    key={type.id}
                    label={type.label}
                    className={
                      selected
                        ? "notificationType selected"
                        : "notificationType"
                    }
                    {...props}
                  />
                );
              })}
              {errors?.notifiableActions && (
                <div style={{ color: "red", display: "block", width: "100%" }}>{errors.notifiableActions}</div>
              )}

              
            </Grid>

            <p style={{ fontWeight: "450" }}>
              {isEdit
                ? "Select notification types"
                : "Selected notification types"}
            </p>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              sx={{ mb: 2 }}
            >
              {(isEdit
                ? notificationTypes
                : notificationTypes.filter(
                    (type) =>
                      testPlanDetail?.notification_types.indexOf(type.id) !== -1
                  )
              )?.map((type) => {
                const selected =
                  detail?.notificationTypes.indexOf(type.id) !== -1;
                const props = { selected: selected };
                if (isEdit) {
                  props.onClick = () => {
                    handleNotificationTypeSelect(type.id);
                  };
                }
                return (
                  <CustomChip
                    key={type.id}
                    label={type.label}
                    className={
                      selected
                        ? "notificationType selected"
                        : "notificationType"
                    }
                    {...props}
                  />
                );
              })}
              {errors?.notificationTypes && (
                <div style={{ color: "red", display: "block", width: "100%" }}>{errors.notificationTypes}</div>
              )}
            </Grid>
            {isEdit && (
              <>
                <p style={{ fontWeight: "450" }}>Select users</p>
                <Autocomplete
                  sx={{ mb: 2 }}
                  multiple
                  filterSelectedOptions
                  id="checkboxes-emails"
                  options={allUsers}
                  disableCloseOnSelect
                  value={detail.notifiableUsers}
                  onChange={handleUserChecked}
                  getOptionLabel={(option) => option?.name || option?.email}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <CustomCheckbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.name || option?.email}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="select users"
                      size="small"
                      variant="outlined"
                      disabled={!isEdit}
                    />
                  )}
                />
                {errors?.notifiableUsers && (
                  <div style={{ color: "red", display: "block", width: "100%" }}>{errors.notifiableUsers}</div>
                )}
              </>
            )}
          </>
        )}
        {!isEdit && testPlanDetail?.notifications_enabled && (
          <>
            <p style={{ fontWeight: "450" }}>Selected notification actions</p>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              sx={{ mb: 2 }}
            >
              {(
                testPlanDetail?.notifiable_actions &&
                notificationActions.filter(
                  (type) =>
                    testPlanDetail?.notifiable_actions?.indexOf(type.id) !== -1
                )
              ).map((type) => {
                const selected =
                  testPlanDetail.notifiable_actions.indexOf(type.id) !== -1;
                const props = { selected: selected };
                if (isEdit) {
                  props.onClick = () => {
                    handleNotificationSelect(type.id);
                  };
                }
                return (
                  <CustomChip
                    key={type.id}
                    label={type.label}
                    className={
                      selected
                        ? "notificationType selected"
                        : "notificationType"
                    }
                    {...props}
                  />
                );
              })}
            </Grid>

            <p style={{ fontWeight: "450" }}>Selected notification types</p>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              sx={{ mb: 2 }}
            >
              {(
                testPlanDetail?.notification_types &&
                notificationTypes.filter(
                  (type) =>
                    testPlanDetail?.notification_types?.indexOf(type.id) !== -1
                )
              ).map((type) => {
                const selected =
                  testPlanDetail?.notification_types?.indexOf(type.id) !== -1;
                const props = { selected: selected };
                if (isEdit) {
                  props.onClick = () => {
                    handleNotificationTypeSelect(type.id);
                  };
                }
                return (
                  <CustomChip
                    key={type.id}
                    label={type.label}
                    className={
                      selected
                        ? "notificationType selected"
                        : "notificationType"
                    }
                    {...props}
                  />
                );
              })}
            </Grid>
            <p style={{ fontWeight: "450" }}>Selected users</p>
            <Grid container>
              {testPlanDetail?.notifiable_users?.map((user) => {
                return (
                  <Grid item key={user.id}>
                    <CustomChip
                      label={user?.name || user?.email}
                      sx={{ padding: "14px 4px", margin: "0 8px 8px 0" }}
                      icon={<Person />}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </div>
      {!isEdit && (
        <>
          <div className="section">
            <h6 style={{ marginBottom: "4px" }}>Associated Test Suites</h6>
            {testPlanDetail?.test_suite_plans?.length === 0 &&
              "No associated test suites"}
            {testPlanDetail?.test_suite_plans?.map((testSuitePlan) => (
              <div key={testSuitePlan.id} style={{ marginTop: "4px" }}>
                {testSuitePlan.test_suite?.title}
                <OpenInNew
                  sx={{ cursor: "pointer", ml: 1 }}
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/test-suites/${testSuitePlan.test_suite?.id}`,
                      "_blank"
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );

  const nonEditModeActions = (
    <Grid container spacing={2} sx={{ flexDirection: "row-reverse" }}>
      <CustomButton
        variant="outlined"
        color="primary"
        disabled={false}
        onClick={onEdit}
      >
        <CreateOutlined sx={{ mr: 1 }} />
        Edit
      </CustomButton>
    </Grid>
  );

  const editModeActions = (
    <Grid container spacing={2}>
      <CustomButton
        variant="outlined"
        sx={{ mr: 2 }}
        color="primary"
        disabled={isSaving}
        onClick={onSave}
      >
        <SaveOutlined sx={{ mr: 1 }} />
        Save
      </CustomButton>
      <CustomButton
        variant="outlined"
        color="primary"
        disabled={isSaving}
        onClick={onCancelEdit}
      >
        <Close sx={{ mr: 1 }} />
        Cancel
      </CustomButton>
    </Grid>
  );

  return (
    <Box sx={{ p: 2, px: 4, color: "black" }} className="editDetail">
      {isSaving && <LoadingOverlay isLoading={isSaving} />}
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          {isEdit ? (
            <>
              <h5>
                <CreateOutlined sx={{ mr: 1 }} />
                Edit Basic Details
              </h5>
            </>
          ) : (
            <>
              <h5>
                <ArticleOutlined sx={{ mr: 1 }} />
                Basic Details
              </h5>
            </>
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          {!isEdit && nonEditModeActions}
        </Grid>
        <Grid item xs={12} md={12}>
          {content}
        </Grid>
        <Grid item xs={12} md={12} style={{ padding: "32px 40px" }}>
          {isEdit && editModeActions}
        </Grid>
        {!isEdit && (
          <div style={{ borderBottom: "1px solid grey", width: "100%" }}></div>
        )}
        <Grid item xs={12} md={12}>
          {!isEdit && (
            <>
              <h5 style={{ color: "#d32f2f" }}>Danger Zone</h5>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                color="error"
                disabled={false}
                onClick={toggleArchiveModal}
              >
                Delete
              </Button>
            </>
          )}
        </Grid>
      </Grid>

      <DeleteDialog
        open={showArchiveModal}
        onClose={toggleArchiveModal}
        title="Delete Test Plan? Archive instead!"
        deleteBtnLabel="Delete Permanently"
        secondaryBtnLabel="Archive"
        secondaryBtnIcon={<Inventory2Outlined />}
        onDeleteAction={onDeleteTestPlan}
        onSecondaryAction={onArchiveTestPlan}
      >
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          We recommend archiving Test Plans instead of deleting.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          Are you sure you want to permanently delete this Test Plan?
        </Typography>
      </DeleteDialog>

      {alertMessage && (
        <Alert sx={{ position: "fixed", top: 20, right: 20 }}>
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default EditDetail;
