import React, { useState, useEffect } from 'react';
import logoSmall from "../../assets/logos/logo_small.png";
import MixedContent from '../Common/MixedContent';
import { useDispatch } from 'react-redux';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import useSearchParams from "../../hooks/useSearchParams";
import { Help } from '@mui/icons-material';
import { Box, TextField, Dialog, DialogTitle, Button, DialogContent, DialogActions } from "@mui/material";
import {
  getQuestionsList,
  publishQuestionAnswer
} from '../../redux-store/chatsReducers/chatsActions';

import MainLayout from '../../layouts/MainLayout';
import CustomButton from '../Common/CustomButton';
import TextEditor from "../Common/TextEditor/TextEditor";
import LoadingOverlay from '../Common/LoadingOverlay';

const ChatsDetail = function ({ chatId, project}) {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [messages, setMessages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishContent, setPublishContent] = useState({question: "", answer: ""});

  useEffect(() => {
    fetchQuestionList();
    // keep page query-param in sync with state
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  const fetchQuestionList = async () => {
    let response;
    setIsLoading(true);
    response = await dispatch(
      getQuestionsList({
        chatId: chatId,
        pageNumber: pageNumber,
        pageSize: 10
      })
    );
    if (response?.payload) {
      setMessages(response.payload?.chat_messages || []);
    }
    setIsLoading(false);
    if (response?.payload) {
      setTotalPages(response.payload?.total_pages);
      setTotalMessages(response.payload?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openSnackbar({
        message: "Something went wrong. Please try again!",
        severity: "error",
      });
    }
  };


  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const onClose = () => {
    setIsPublishModalOpen(false);
    setPublishContent({});
  }

  const onPublishQuestion = (questionText="", answerText="") => {
    setIsPublishModalOpen(true);
    setPublishContent({question: questionText, answer: answerText});
  };

  const onPublishConfirm = async () => {
    const response = await dispatch(
      publishQuestionAnswer({ projectId: project.id, questionText: publishContent.question?.trim(), answerText: publishContent.answer?.trim() })
    );
    if (!response?.error && response?.payload) {
      onClose();
      fetchQuestionList();
      openSnackbar({
        message: "Question published successfully!",
        severity: "success",
      });
    } else if (response?.error) {
      openSnackbar({
        message: "Question publishing failed! Please try again!",
        severity: "error",
      });
    }
  }

  const onQuestionChange = (e) => {
    setPublishContent(state => ({...state, question: e.target.value}))
  }

  const onAnswerChange = (value) => {
    setPublishContent(state => ({...state, answer: value}))
  }


  const renderList = () => {
    if (messages.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }
    const msgs = [];
    for (let i = 0; i < messages.length; ) {
      let userMessage = messages[i];
      let parsedMessage = {user: "", system: ""};
      if (userMessage.message_type === "user_message") {
        parsedMessage.user = userMessage.content;
        i += 1;
        let systemMessage = messages?.[i];
        if (systemMessage?.message_type === "system_message") {
          parsedMessage.system = systemMessage.content;
          i += 1;
        }
        msgs.push(parsedMessage);
      }
    }
    return (
      <Box>
        {msgs.map((m, i) => {
          return (
            <Box key={i} sx={{mb: 2, textTransform: "capitalize", p: 2, border: "1px solid #e9e9e9", borderRadius: "8px", background: "#f7f7f7" }}>
              {m.user ? (
                  <Box>
                    <Help sx={{width: "28px", height: "28px", mr: 1}} />
                    <Box sx={{width: "calc(100% - 100px)", display: "inline-block"}}>
                      <h5>{m.user}</h5>
                    </Box>
                    <CustomButton sx={{display: "inline-block"}} onClick={() => {onPublishQuestion(m.user, m.system)}}>
                      Publish
                    </CustomButton>
                  </Box>
                ) : null}
              {
                m.system ? (
                  <Box sx={{mt: 1}}>
                    <img src={logoSmall} width="30px" style={{border: "1px solid #f8f8f8", borderRadius: "50%", marginRight: "8px"}} />
                    <Box sx={{px: 1/2, mt: 1/2, width: "calc(100% - 50px)", display: "inline-block", verticalAlign: "top"}}>
                      <MixedContent content={m.system} />
                    </Box>
                  </Box>
                ) : null
              }
            </Box>
          )
        })}
      </Box>
    );
  }

  return (
    <MainLayout
      isLoading={isLoading}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      renderHeader={() => (
        <Box sx={{color: "rgb(47, 47, 47)", textTransform: "capitalize", fontFamily: "Inter, Sans-serif"}}>
          <span style={{fontSize: "12px"}}>Search chat</span>
          <h3 style={{fontWeight: "500", marginTop: "4px", fontSize: "24px"}}>{isLoading ? <LoadingOverlay /> : messages?.[0]?.content}</h3>
        </Box>
      )}
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto", px: 1}}>
        {!isLoading && (
          renderList()
        )}
      </Box>
      <Dialog fullWidth maxWidth="md" onClose={onClose} open={isPublishModalOpen}>
        <DialogTitle sx={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "18px"}}>Publish a question answer.</DialogTitle>
        <DialogContent>
          <Box sx={{mb: 2}}>
            <h5>Question</h5>
            <TextField
              fullWidth
              multiline
              rows={5}
              defaultValue={publishContent.question}
              onChange={onQuestionChange}
            />
          </Box>
          <Box sx={{mb: 2}}>
            <h5>Answer</h5>
            <TextEditor initialContent={publishContent.answer} onChange={onAnswerChange} />
          </Box>
        </DialogContent>
        <DialogActions sx={{p: "20px 24px"}}>
          <Button variant="outlined" onClick={onClose} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onPublishConfirm} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}} disabled={!publishContent?.question?.trim() || !publishContent?.answer?.trim()}>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  )
};

export default ChatsDetail;
