import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, IconButton, Stack, Typography, MenuItem, MenuList } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import CustomTable from '../Common/CustomTable';
import CustomMenu from '../Common/CustomMenu';

const VariableSetList = function ({ variableSetList, pageNumber, onDelete }) {
  const history = useHistory();

  const isLoading = useSelector((state) => state.variableSet.isLoading);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteVariableSetId, setDeleteVariableSetId] = useState('');
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const onViewVariableSet = async (variableSetId) => {
    history.push(`/variable-set/${variableSetId}/?page=${pageNumber}`);
  };

  const onClickDelete = (variableSetId) => {
    setDeleteVariableSetId(variableSetId);
    onToggleDeleteDialog();
  };

  const onDeleteConfirm = () => {
    onDelete(deleteVariableSetId);
    onToggleDeleteDialog();
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deleteVariableSetId) setDeleteVariableSetId('');
  };

  const renderTable = () => {
    if (variableSetList.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }
    const sxTh = {height: "54px"};

    return (
      <>
        <CustomTable
          headers={[
            {
              id: 'title',
              type: 'text',
              text: 'Title',
              sx: sxTh
            },
            {
              id: 'env-set',
              type: 'text',
              text: 'Environment Variable Set',
              sx: sxTh
            },
            {
              id: 'actions',
              type: 'text',
              text: 'Actions',
              align: 'center',
              sx: sxTh
            },
          ]}
          rows={variableSetList.map(({ id, title, is_environment }) => ({
            id,
            cells: [
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Typography variant="body1" className="truncate-1-lines" title={title}>{title}</Typography>
                  </Stack>
                ),
                sx: { cursor: 'pointer', width: "500px" },
                onClick: () => {
                  onViewVariableSet(id);
                },
              },
              {
                id: 'env-set-' + id,
                type: 'text',
                text: is_environment ? String(is_environment) : '',
                sx: { width: "100" },
              },
              {
                id: 'actions-' + id,
                type: 'component',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem
                          onClick={() => {
                            onClickDelete(id);
                            handleClose(id);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                align: 'center',
                sx: { width: "50" }
              },
            ],
          }))}
          sx={{}}
        />
      </>
    );
  };

  const renderConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        onConfirm={onDeleteConfirm}
        title="Confirm Delete"
        content="Are you sure you want to delete?"
      />
    );
  };

  return (
    <>
      <Box>{!isLoading && renderTable()}</Box>
      {deleteDialogOpen && renderConfirmDialog()}
    </>
  );
};

export default VariableSetList;
