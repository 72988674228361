import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import TestSuiteBuildLogs from './TestSuiteBuildLogs';
import TestSuiteBuildsListPage from './TestSuiteBuildsListPage';

const TestSuiteBuildsPage = function({project, buildId, testSuiteId}) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (buildId) {
      setCurrentView("testSuiteBuildLogs");
    } else {
      setCurrentView("testSuiteBuildsList");
    }
  }, [buildId]);

  let content = null;
  if (currentView === 'testSuiteBuildsList') {
    content = (<TestSuiteBuildsListPage project={project} testSuiteId={testSuiteId} />);
  } else if (currentView === 'testSuiteBuildLogs') {
    content = (
      <TestSuiteBuildLogs project={project} buildId={buildId} testSuiteId={testSuiteId} />
    );
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
}

export default TestSuiteBuildsPage;
