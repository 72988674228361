import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";

import CustomButton from "../Common/CustomButton";

import { createTestPlan } from "../../redux-store/testPlanReducers/testPlanActions";
import { attachPlansWithSuites } from "../../redux-store/testSuitePlanReducers/testSuitePlanActions";
import coreUtils from "../../utils/coreUtils";

const CreateTestPlanModal = ({ projectId, open, onClose, onCancel }) => {
  const dispatch = useDispatch();
  const selectedTestSuites = useSelector(
    (state) => state.testSuite.selectedTestSuites
  );
  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState("");
  const [environmentSet, setEnvironmentSet] = useState(environmentSets[0]?.id);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    validate(["suite"]);
  }, []);

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleRadioChange = (event) => setEnvironmentSet(event.target.value);

  const handleCancel = () => {
    setTitle("");
    setEnvironmentSet("");
    setErrors({});
    onCancel?.();
  };

  const validate = (tests = ["suite", "title", "environment"]) => {
    let isValid = true;
    if (
      (!selectedTestSuites || selectedTestSuites?.length === 0) &&
      tests.indexOf("scenario") !== -1
    ) {
      setErrors({ scenario: "Please select atleast one test scenario." });
      isValid = false;
    }
    if (
      coreUtils.isStringInvalidOrBlank(title) &&
      tests.indexOf("title") !== -1
    ) {
      setErrors({ title: "Please enter a valid Title" });
      isValid = false;
    }
    if (
      coreUtils.isStringInvalidOrBlank(environmentSet) &&
      tests.indexOf("environment") !== -1
    ) {
      setErrors({ title: "Please select a valid Environment" });
      isValid = false;
    }
    return isValid;
  };

  const onCreateConfirm = async () => {
    setIsSaving(true);
    if (!validate()) {
      setIsSaving(false);
      return;
    }
    const response = await dispatch(
      createTestPlan({
        projectId: projectId,
        title: title,
        environmentSetId: environmentSet,
      })
    );
    if (response?.payload?.id) {
      await dispatch(
        attachPlansWithSuites({
          testPlanIds: [response.payload.id],
          testSuiteIds: [...selectedTestSuites.map((test) => test.id)],
        })
      );
    } else {
      //toast with error message
    }
    setIsSaving(false);
    handleCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attach-test-plan-dialog-title"
    >
      <DialogTitle id="attach-test-plan-dialog-title" sx={{ fontSize: 20 }}>
        Create new test plan
      </DialogTitle>
      <DialogContent
        sx={{
          width: "500px",
          height: "auto",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <span style={{ fontSize: "14px" }} className="text_caption_error">
          {errors.title || errors.scenario || errors.environmentSet}
        </span>
        <h5 style={{ marginTop: 16 }}>Title</h5>
        <TextField
          sx={{ mt: 1, width: 1 }}
          size="small"
          variant="outlined"
          value={title}
          onChange={handleChange}
        />
        <div style={{ minWidth: "300px", marginTop: 24 }}>
          <h5 style={{ marginTop: 16 }}>Select an environment</h5>
          <RadioGroup
            name="radio-buttons-group"
            value={environmentSet}
            onChange={handleRadioChange}
          >
            {environmentSets.map((option) => (
              <div key={option.id}>
                <FormControlLabel
                  value={option.id}
                  sx={{ textTransform: "capitalize", m: 0 }}
                  control={<Radio />}
                  label={option.name || option.title}
                />
              </div>
            ))}
          </RadioGroup>
        </div>
        <h5 style={{ marginTop: 16, marginBottom: 8 }}>Selected Test Suites</h5>
        {selectedTestSuites.map((suite) => (
          <Chip
            key={suite.id}
            id={suite.id}
            label={suite.title}
            size="small"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {!selectedTestSuites?.length && <h6>None selected</h6>}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <CustomButton
          onClick={onCreateConfirm}
          color="primary"
          disabled={
            coreUtils.isStringInvalidOrBlank(title) ||
            coreUtils.isStringInvalidOrBlank(environmentSet) ||
            !!errors.suite
          }
        >
          Create new test plan
        </CustomButton>
        <CustomButton
          onClick={handleCancel}
          color="secondary"
          disabled={isSaving}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTestPlanModal;
