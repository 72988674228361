import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box} from "@mui/material";

import MetadataHeader from '../Common/MetadataHeader';

import { updateTestScenario } from '../../redux-store/testScenarioReducers/testScenarioActions';

const TestScenarioHeader = ({testScenarioId}) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(state => state.testSuite.isSaving);

  const handleTestScenarioUpdate = async (data) => {
    return dispatch(updateTestScenario({testScenarioId: testScenarioId, data: data}));
  }

  return (
    <Box className="testScenarioHeader">
      <MetadataHeader id={testScenarioId} onUpdate={handleTestScenarioUpdate} type="testScenario" isLoading={isSaving} />
    </Box>
  )
}

export default TestScenarioHeader;
