import Search from '@mui/icons-material/Search';

const SearchWithIcon = (props) => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <input {...props} type="text" className="search-input-w-icon" />
      <Search
        fontSize="small"
        sx={{
          position: 'absolute',
          right: '32px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
    </div>
  );
};

export default SearchWithIcon;
