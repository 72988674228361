import React from 'react';
import { connect } from 'react-redux';

import { Box, Stack, Typography } from '@mui/material';

import CustomTable from '../Common/CustomTable';

const CollectionForksList = function ({collectionForksList, isLoading}) {
  const renderTable = () => {
    if (isLoading) return null;

    if (collectionForksList && collectionForksList.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }
    const sxTh = {height: "54px"};

    return (
      <>
        <CustomTable
          headers={[
            {
              id: 'title',
              type: 'text',
              text: 'Collection Name',
              sx: sxTh
            },
            {
              id: 'user-name',
              type: 'text',
              text: 'Name(Forked by)',
              sx: sxTh
            },
            {
              id: 'user-email',
              type: 'text',
              text: 'Email(Forked by)',
              sx: sxTh
            },
            {
              id: 'actions',
              type: 'text',
              text: 'Date/Time',
              align: 'center',
              sx: sxTh
            },
          ]}
          rows={collectionForksList.map(({ id, created_at, collection, user }) => ({
            id,
            cells: [
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Typography variant="body1" className="truncate-1-lines" title={collection.name}>{collection.name}</Typography>
                  </Stack>
                ),
                sx: { width: "500px", padding: "16px" }
              },
              {
                id: 'name-' + id,
                type: 'text',
                text: user?.name || "",
                sx: { width: "100", padding: "16px" },
              },
              {
                id: 'email-' + id,
                type: 'text',
                text: user?.email || "",
                sx: { width: "100", padding: "16px" },
              },
              {
                id: 'time-' + id,
                type: 'text',
                text: created_at ? new Date(created_at * 1000).toUTCString() : "",
                sx: { width: "150", padding: "16px" },
              },
            ],
          }))}
          sx={{}}
        />
      </>
    );
  };

  return (
    <Box>{!isLoading && renderTable()}</Box>
  );
};

function mapStateToProps(state, ownProps) {
  const collectionForks = state.collectionForks;
  console.log(state);
  return {
    collectionForksList: collectionForks.collectionForksList,
    isLoading: collectionForks.isLoading,
    ...ownProps
  }
}
export default connect(mapStateToProps)(CollectionForksList)
