import {createSlice} from '@reduxjs/toolkit';

const testPlanSlice = createSlice({
    name: "testPlanSlice",
    initialState: {
        isLoading: false,
        errors: {},
        total: 0,
        totalPages: 0,
        testPlansList: [],
        selectedTestPlanDetail: {}
    },
    reducers: {
        updateLoadError: (state, action) => {
            return {
                ...state,
                errors: action.payload.error_message
            };
        },
        setLoadingState: (state, action) => {
            return {
                ...state,
                isLoading: action.payload
            }
        },
        setSavingState: (state, action) => {
            return {
                ...state,
                isSaving: action.payload
            }
        },
        updateTestPlansList: (state, action) => {
            return {
                ...state,
                testPlansList: [...action.payload.testPlansList],
                total: action.payload.total,
                totalPages: action.payload.totalPages
            }
        },
        updateSelectedTestPlanDetail: (state, action) => {
            return {
              ...state,
              selectedTestPlanDetail: { ...action.payload.testPlanDetail },
            };
        }
    }
})

export const {
    updateLoadError,
    setLoadingState,
    setSavingState,
    updateTestPlansList,
    updateSelectedTestPlanDetail
} = testPlanSlice.actions;

export default testPlanSlice.reducer;