import { Dialog, DialogTitle, DialogContent, Stack } from '@mui/material';
import { ReportProblemOutlined, DeleteOutlined } from '@mui/icons-material';

import { DeleteButton, SecondaryButton } from './CustomButton';

const DeleteDialog = ({
  open,
  onClose,
  title,
  children,
  deleteBtnLabel,
  secondaryBtnLabel,
  onDeleteAction,
  onSecondaryAction,
  secondaryBtnIcon,
  hideSecondary=false
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '12px',
          boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <ReportProblemOutlined color="warning" sx={{ marginRight: '10px' }} />{' '}
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
        <Stack spacing={1.5} direction="row">
          <DeleteButton
            size="small"
            startIcon={<DeleteOutlined />}
            onClick={onDeleteAction}
          >
            {deleteBtnLabel}
          </DeleteButton>
          {!hideSecondary && (
            <SecondaryButton
              size="small"
              startIcon={secondaryBtnIcon}
              onClick={onSecondaryAction}
            >
              {secondaryBtnLabel}
            </SecondaryButton>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;
