import {
  Box,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

import "../../styles/components/Common/CustomTable.scss";

const CustomTable = ({ headers = [], rows = [], sx={}, toolbarSx }) => {
  return (
    <Box className="toolbar-container customTable">
      <Toolbar variant="dense" className="tool-bar-style" sx={toolbarSx}>
        <TableContainer className="toolbarContainer">
          <Table
            sx={{ minWidth: 650, ...sx }}
            aria-label="simple table"
            style={{ border: 'none' }}
          >
            <TableHead sx={{height: "40px"}}>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell
                    key={header.id || `header-${i}`}
                    align={header.align || 'left'}
                    sx={{
                      border: 'none',
                      padding: "10px 12px",
                      ...(header.sx ? { ...header.sx } : {}),
                    }}
                  >
                    {header.type === 'text' ? (
                      <p>
                        {header.text}
                      </p>
                    ) : (
                      header.content
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={row.id || `row-${i}`} onClick={row?.onClick ? row?.onClick : () => {}}>
                  {(row.cells || []).map((cell, i) => (
                    <TableCell
                      key={cell.id || `cell-${i}`}
                      align={cell.align || 'left'}
                      sx={{
                        border: 'none',
                        padding: "10px 12px",
                        ...(cell.sx ? { ...cell.sx } : {}),
                      }}
                      onClick={cell.onClick ? cell.onClick : () => {}}
                    >
                      {cell.type === 'text' ? (
                        <Typography variant="body1">{cell.text}</Typography>
                      ) : (
                        cell.content
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Toolbar>
    </Box>
  );
};

export default CustomTable;
