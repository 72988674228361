import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import BuildHistory from "../Common/BuildHistory";

const TestScenarioBuildList = function({ testScenarioBuildList, testScenarioId, pageNumber }) {
    const isLoading = useSelector(state => state.testScenario.isLoading);
    const history = useHistory();

    useEffect(() => {
        history.push(`/test-scenarios/${testScenarioId}/build/?page=${pageNumber}`);
    }, [pageNumber])

    const onViewBuild = async (id) => {
        history.push(`/test-scenarios/${testScenarioId}/build/${id}/?page=${pageNumber}`);
    }

    const onViewSource = () => {
        history.push(`/test-scenarios/${testScenarioId}/`);
    }

    return (
        <Box>
            <BuildHistory buildList={testScenarioBuildList} onViewBuild={onViewBuild} onViewSource={onViewSource} isLoading={isLoading} />
        </Box>
    )
}

export default TestScenarioBuildList;
