import { ArrowOutward } from "@mui/icons-material";
import { Typography, Link, Box } from "@mui/material";

const AuthTypeText = () => {
  return (
    <Box mt={2}>
      <Typography variant="body1" align="left">
        The authorization header will be automatically generated when you send the request.
        Learn more about
        <Link href="#" color="inherit" underline="always" style={{ marginLeft: '5px', marginRight: '5px' }}>
          authorization
        </Link>
        <ArrowOutward fontSize="small" />
      </Typography>
    </Box>
  );
}

export default AuthTypeText;
