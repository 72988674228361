const initialState = {
    message: null, 
    severity: 'info',  
    open: false,  
    verticalPos: 'top', 
    horizontalPos: 'right' 
  };
  
  const globalErrorSnackbarReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_GLOBAL_ERROR_SNACKBAR_MESSAGE':
        return {
          ...state,
          ...action.payload  
        };
      default:
        return state;
    }
  };
  
  export default globalErrorSnackbarReducer;
  