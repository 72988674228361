import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

const variableSlice = createSlice({
    name: "variableSlice",
    initialState: {
        isLoading: false,
        errors: {},
        total: 0,
        totalPages: 0,
        
    },
    reducers: {
        updateLoadError: (state, action) => {
            return {
                ...state,
                errors: action.payload.error_message
            };
        },
        setLoadingState: (state, action) => {
            return {
                ...state,
                isLoading: action.payload
            }
        },
        setSavingState: (state, action) => {
            return {
                ...state,
                isSaving: action.payload
            }
        }
    }
})

export const {
    updateLoadError,
    setLoadingState,
    setSavingState
} = variableSlice.actions;

export default variableSlice.reducer;