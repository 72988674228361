import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingOverlay from "../Common/LoadingOverlay";
import KeyValueTable from "../Common/KeyValueTable";

import {
  attachVariableSet,
  detachVariableSet,
  getVariableSet,
  getAllVariableSets,
} from "../../redux-store/variableSetReducers/variableSetActions";
import {
  deleteVariable,
  createVariable,
  updateVariable,
} from "../../redux-store/variableReducers/variableActions";

const TestScenarioVariables = function (props) {
  const { testScenarioId, projectId } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.variableSet.isLoading);
  const testScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );
  const [variablesMap, setVariablesMap] = useState({});
  const [variables, setVariables] = useState([]);
  const [attachedVariableSetIds, setAttachedVariableSetIds] = useState([]);
  const [attachedVariableSetMap, setAttachedVariableSetMap] = useState([]);
  const [allVariableSets, setAllVariableSets] = useState([]);
  const [variableSetErrorMsg, setVariableSetErrorMsg] = useState("");
  const [variableErrorMsg, setVariableErrorMsg] = useState("");
  const [selectedVariableSet, setSelectedVariableSet] = useState(null);
  const [expandedVariableSet, setExpandedVariableSet] = useState("");
  const [variableSetMap, setVariableSetMap] = useState({});

  useEffect(() => {
    fetchAllVariableSets();
    setInitialVariables(testScenarioDetail);
  }, []);

  const fetchAllVariableSets = async () => {
    const allVariableSetsResponse = await dispatch(
      getAllVariableSets({ projectId, pageNumber: 1, isEnvironment: false })
    );
    const variableSets = allVariableSetsResponse?.payload?.variable_sets;
    setAllVariableSets(
      variableSets?.map((vSet) => ({ ...vSet, isSelected: false }))
    );
  };

  const setInitialVariables = (testScenarioDetail) => {
    const { variable_set_resources, variables } = testScenarioDetail;
    const variablesMap = {};
    const formattedVariables = variables.map((v) => formatVariableResponse(v));
    setVariables(formattedVariables);
    formattedVariables.forEach((v) => {
      variablesMap[v.id] = { ...v };
    });
    setVariablesMap(variablesMap);
    const attachedVariableSetMap = {};
    const variableSetIds = variable_set_resources.map((vSet) => {
      attachedVariableSetMap[vSet.variable_set.id] = vSet;
      return vSet.variable_set.id;
    });
    setAttachedVariableSetIds(variableSetIds);
    setAttachedVariableSetMap(attachedVariableSetMap);
  };

  const onVariableSetAttach = async (event, variableSet) => {
    setVariableSetErrorMsg("");
    let variableSetDetail;
    if (variableSetMap[variableSet.id]) {
      variableSetDetail = variableSetMap[variableSet.id];
    } else {
      const variableSetDetailResponse = await dispatch(
        getVariableSet({ variableSetId: variableSet.id })
      );
      variableSetDetail = variableSetDetailResponse.payload;
      setVariableSetMap((prevState) => ({
        ...prevState,
        [variableSet.id]: { ...variableSetDetail },
      }));
    }

    const isValid = validateVariableSetCollission(variableSetDetail);
    if (isValid.success) {
      const attachedVariableSetResponse = await dispatch(
        attachVariableSet({
          resourceId: testScenarioId,
          resourceType: "TestScenario",
          variableSetId: variableSet.id,
        })
      );
      if (attachedVariableSetResponse?.payload) {
        setAttachedVariableSetIds((prevState) => [
          ...prevState,
          attachedVariableSetResponse?.payload.variable_set.id,
        ]);
        setAttachedVariableSetMap((prevState) => ({
          ...prevState,
          [variableSet.id]: attachedVariableSetResponse?.payload,
        }));
      }
    } else {
      setVariableSetErrorMsg(isValid.msg);
    }
  };

  const onVariableSetDetach = async (variableResourceSet) => {
    await dispatch(
      detachVariableSet({ variableSetResourceId: variableResourceSet.id })
    );
    setAttachedVariableSetIds((prevState) =>
      prevState.filter((id) => id !== variableResourceSet.variable_set.id)
    );
    setAttachedVariableSetMap((prevState) => {
      const newState = { ...prevState };
      delete newState[variableResourceSet.variable_set.id];
      return newState;
    });
  };

  const updateVariableList = (id, variableDetail) => {
    const variableIndex = variables.findIndex(
      (variableObj) => variableObj.id === id
    );
    setVariables((prevState) => {
      if (variableIndex === -1) {
        return [...prevState, variableDetail];
      }
      const newState = [...prevState];
      newState[variableIndex] = {
        ...prevState[variableIndex],
        ...variableDetail,
      };
      return newState;
    });
  };

  const updateVariableMap = (id, variableDetail) => {
    setVariablesMap((prevState) => {
      delete variables[id];
      return {
        ...prevState,
        [variableDetail.id]: { ...variableDetail },
      };
    });
  };

  const formatVariableResponse = (variable) => {
    return {
      id: variable.id,
      key: variable.var_name,
      value: variable.var_value,
      isNew: variable.isNew,
      isEdit: variable.isEdit,
    };
  };

  const validateVariableSetCollission = (variableSet) => {
    // TODO: move this calculation to the backend
    const newVariablesKey = variableSet.variables.map((v) => v.key);
    let isDuplicate = false;
    let duplicateWith;
    for (const vSetId of attachedVariableSetIds) {
      const variablesKey = attachedVariableSetMap[
        vSetId
      ].variable_set.variables.map((v) => v.key);
      for (const k of newVariablesKey) {
        if (variablesKey.indexOf(k) !== -1) {
          isDuplicate = true;
          break;
        }
      }
      if (isDuplicate) {
        duplicateWith = vSetId;
        break;
      }
    }
    return isDuplicate
      ? {
          msg: `This variable set has duplicate key/s with already added variable set present at Serial number ${
            attachedVariableSetIds.indexOf(duplicateWith) + 1
          } titled '${
            attachedVariableSetMap[duplicateWith].variable_set.title
          }'`,
        }
      : { success: true };
  };

  const validateVariableKey = (variableKey) => {
    const trimmedKey = variableKey ? variableKey.trim() : "";
    if (!trimmedKey) {
      setVariableErrorMsg("Please enter a valid key");
      return false;
    }
    const matchedKeys = Object.keys(variablesMap).filter(
      (k) => variablesMap[k].var_name === variableKey
    );
    if (matchedKeys.length > 0) {
      setVariableErrorMsg("Key name collides with other variable key");
      return false;
    }
    return true;
  };

  const onCreateVariable = async ({ var_name, var_value }) => {
    if (!validateVariableKey(var_name)) {
      return false;
    }
    const data = {
      resourceId: testScenarioId,
      resourceType: "TestScenario",
      var_name: var_name,
      var_value: var_value,
    };
    const newVariableResponse = await dispatch(createVariable(data));
    const variable = newVariableResponse?.payload?.variable || {};
    updateVariableDetail(variable.id, variable);
  };

  const updateVariableDetail = (id, variable) => {
    const formattedVariable = formatVariableResponse(variable);
    updateVariableList(id, formattedVariable);
    updateVariableMap(id, formattedVariable);
  };

  const onSaveVariable = async ({ id, value }) => {
    const data = {
      variableValue: value,
      variableId: id,
    };
    const variableResponse = await dispatch(updateVariable(data));
    updateVariableDetail(id, variableResponse?.payload?.variable);
  };

  const onDeleteVariable = async (id) => {
    await dispatch(deleteVariable({ variableId: id }));
    const variableIndex = variables.findIndex((variable) => variable.id === id);
    setVariables((prevState) => {
      return [
        ...prevState.slice(0, variableIndex),
        ...prevState.slice(variableIndex + 1, prevState.length),
      ];
    });
    setVariablesMap((prevState) => {
      const newState = { ...prevState };
      delete newState[id];
      return newState;
    });
  };

  const getVariableSetOptions = () => {
    if (allVariableSets && allVariableSets.length > 0) {
      const options = allVariableSets.filter(
        (vSet) => attachedVariableSetIds.findIndex((v) => v === vSet.id) === -1
      );
      return options;
    }
    return [];
  };

  const onExpandVariableSet = (id) => {
    if (id === expandedVariableSet) {
      setExpandedVariableSet("");
    } else {
      setExpandedVariableSet(id);
    }
  };

  const renderAttachedVariableSet = () => {
    if (attachedVariableSetIds.length === 0) return "No attached variable set";
    return attachedVariableSetIds.map((id) => {
      const variableResourceSet = attachedVariableSetMap[id];
      return (
        <Accordion
          key={id}
          expanded={expandedVariableSet === id}
          onChange={() => {
            onExpandVariableSet(id);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>{variableResourceSet?.variable_set.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KeyValueTable
              allItems={variableResourceSet?.variable_set?.variables}
              isReadOnly
            />
          </AccordionDetails>
          <AccordionActions>
            <Button
              onClick={() => {
                onVariableSetDetach(variableResourceSet);
              }}
            >
              Detach
            </Button>
          </AccordionActions>
        </Accordion>
      );
    });
  };

  return (
    <Box sx={{ p: 2, color: "black" }} className="editDetail">
      <Card variant="outlined" sx={{ border: "none" }}>
        <CardContent>
          <h5> Select variables sets to attach </h5>
          <Autocomplete
            options={getVariableSetOptions()}
            getOptionLabel={(option) => option.title}
            id="variableSet"
            value={selectedVariableSet}
            onChange={onVariableSetAttach}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            sx={{ margin: "0 0 8px", width: "auto" }}
          />
          <h5 style={{ marginBottom: "16px", marginTop: "32px" }}>
            Attached variables sets
          </h5>
          {renderAttachedVariableSet()}
          {variableSetErrorMsg && (
            <p style={{ color: "red" }}>{variableSetErrorMsg}</p>
          )}
          <h5 style={{ marginTop: "32px" }}>Create custom variables</h5>
          <KeyValueTable
            allItems={variables}
            onCreateItem={onCreateVariable}
            onUpdateItem={onSaveVariable}
            onDeleteItem={onDeleteVariable}
            itemName="variable"
            onValidate={() => {}}
          />
          {variableErrorMsg && (
            <p style={{ color: "red" }}>{variableErrorMsg}</p>
          )}
        </CardContent>
      </Card>
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

export default TestScenarioVariables;
