import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    selectedProject: null,
    selectedOrganisation: null,
    organisations: []
  },
  reducers: {
    setSelectedProject: (state, action) => {
      return {
        ...state,
        selectedProject: action.payload,
      };
    },
    setSelectedOrganisation: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        selectedOrganisation: action.payload,
      };
    },
    setOrganisations: (state, action) => {
      return {
        ...state,
        organisations: action.payload,
      };
    },
    setUpdatedOrganisation: (state, action) => {
      console.log(action.payload);
      const organisations = state.organisations.map((org, index) => {
        if(org.id === action.payload.id) {
          return {...state.organisations[index], ...action.payload};
        }
        return org;
      });
      const newState = {
        ...state,
        organisations: organisations
      };
      return newState;
    }
  },
});

export const {
  setSelectedProject,
  setSelectedOrganisation,
  setOrganisations,
  setUpdatedOrganisation
} = commonSlice.actions;

export default commonSlice.reducer;
