import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import TestSuite from './TestSuite';
import TestSuiteListPage from './TestSuitesListPage';

const TestSuitesPage = function({project, testSuiteId}) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (testSuiteId) {
      setCurrentView("testSuite");
    } else {
      setCurrentView("testSuiteList");
    }
  }, [testSuiteId]);

  let content = null;
  if (currentView === 'testSuiteList') {
    content = (<TestSuiteListPage project={project} />);
  } else if (currentView === 'testSuite') {
    content = (<TestSuite project={project} testSuiteId={testSuiteId} />);
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
}

export default TestSuitesPage;
