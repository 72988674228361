import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import CustomButton from '../Common/CustomButton';
import LoadingOverlay from '../Common/LoadingOverlay';

import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { createBuild } from '../../redux-store/testScenarioBuildReducers/testScenarioBuildActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const BuildScenario = ({ projectId, testScenarioId, closeDrawer }) => {
  const dispatch = useDispatch();

  const allEnvironments = useSelector(
    (state) => state.variableSet.allVariableSets
  );
  const isSaving = useSelector((state) => state.testScenario.isSaving);
  const [selectedEnv, setSelectedEnv] = useState('');
  const { openSnackbar } = useSnackbar();

  const onCancelBuild = () => {
    closeDrawer();
  };

  const onRunBuild = async () => {
    if (selectedEnv) {
      await dispatch(
        createBuild({
          testScenarioId: testScenarioId,
          environmentSetId: selectedEnv,
        })
      );
      openSnackbar({
        message: "Build was triggered successfully.",
        severity: "success",
      });
      closeDrawer();
    }
  };

  const handleRadioChange = (event, newValue) => {
    setSelectedEnv(newValue);
  };

  return (
    <Box sx={{ p: 2, color: 'black' }} className="editDetail">
      {isSaving && <LoadingOverlay isLoading={isSaving} />}
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item xs={6} md={6} sx={{ pt: 0 }}>
          <h4>
            <PlayCircleFilledWhiteOutlinedIcon sx={{ mr: 1 }} />
            Run
          </h4>
        </Grid>
        <Grid item xs={12} md={12}>
          <h5>Environment</h5>
          <RadioGroup
            aria-labelledby="status-radio-buttons-group-label"
            onChange={handleRadioChange}
            value={selectedEnv}
            defaultValue="draft"
            name="radio-buttons-group"
          >
            {allEnvironments.map((option) => {
              return (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.title[0].toUpperCase() + option.title.slice(1)}
                />
              );
            })}
          </RadioGroup>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 4, ml: 2 }}>
          <CustomButton
            variant="outlined"
            sx={{ mr: 2 }}
            color="primary"
            disabled={isSaving || !selectedEnv}
            onClick={onRunBuild}
          >
            <PlayCircleFilledWhiteOutlinedIcon sx={{ mr: 1 }} />
            Run
          </CustomButton>
          <CustomButton
            variant="outlined"
            color="primary"
            disabled={isSaving}
            onClick={onCancelBuild}
          >
            <CloseIcon sx={{ mr: 1 }} />
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BuildScenario;
