import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Box} from '@mui/material';
import TestSuiteBuildsList from './TestSuiteBuildsList';
import LoadingOverlay from "../Common/LoadingOverlay";
import MainLayout from "../../layouts/MainLayout";

import { getAllBuilds } from '../../redux-store/testSuiteBuildReducers/testSuiteBuildActions';
import { getTestSuite } from "../../redux-store/testSuiteReducers/testSuiteActions";


const TestSuiteBuildsListPage = ({ project, testSuiteId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pageSize = 10;
    const [pageNumber, setPageNumber] = useState(history.location.search.split("page=")[1] || 1);
    const [testSuiteBuildList, setTestSuiteBuildList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalBuilds, setTotalBuilds] = useState(0);
    const isLoading = useSelector(state => state.testSuiteBuild.isLoading);
    const testSuiteDetail = useSelector(
        (state) => state.testSuite.selectedTestSuiteDetail
      );

    useEffect(() => {
        fetchTestSuiteDetail();
        getTestSuiteBuildList(pageNumber, pageSize);
    }, []);

    const fetchTestSuiteDetail = async () => {
        await dispatch(getTestSuite({ testSuiteId: testSuiteId }));
    };

    const getTestSuiteBuildList = async (pageNumber, pageSize) => {
        const response = await dispatch(getAllBuilds({testSuiteId: testSuiteId, pageNumber: pageNumber, pageSize: pageSize}))
        if (response?.payload) {
            setTestSuiteBuildList(response.payload?.test_suite_builds || []);
            setTotalPages(response.payload?.total_pages);
            setTotalBuilds(response.payload?.total);
        }
    }

    const onPageChange = (e, value) => {
        setPageNumber(value);
        getTestSuiteBuildList(value, pageSize);
    }

    return (
        <MainLayout
            sx={{ width: 'calc(100% - 100px)' }}
            headerText={testSuiteDetail?.title}
            subtitleText={testSuiteDetail?.description}
            isLoading={isLoading}
            pageNumber={pageNumber}
            onPageChange={onPageChange}
            totalPages={totalPages}
        >
            <h4 style={{color: "rgba(0, 0, 0, 0.87)", padding: "0px 24px"}}>All builds ({totalBuilds})</h4>
            <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "auto"}}>
                <TestSuiteBuildsList
                    testSuiteId={testSuiteId}
                    testSuiteBuildList={testSuiteBuildList}
                    isLoading={isLoading}
                    pageNumber={pageNumber}/>
            </Box>
        </MainLayout>
    )
}


export default TestSuiteBuildsListPage;
