import React from "react";
import {Box} from "@mui/material";

const Monitoring = () => {
  return (
    <Box>
      {/* <div className="title"><div className="menuIcon monitoring" /><h3>Monitoring</h3></div> */}
      <p style={{marginTop: "0px"}}>
        QodexAI keeps your system health check in control and follows a hierarchical approach to notify stakeholders (as configured including phone calls when necessary) regarding change in test suites results.
      </p>
      <p>
      It also keeps a detailed check on tests grouped by different departments, micro-services, or functionalities, and notifies the stakeholders accordingly.
      </p>
    </Box>
  )
}

export default Monitoring;
