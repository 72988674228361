import React, { useReducer } from "react";
import { useDispatch } from "react-redux";
import { createProject } from "../../redux-store/currentUserActions";
import { Box, TextField, Button, IconButton, Typography, Grid, Card, CardContent } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const ProjectScreen = ({ org, onChild, updateSelectedProject }) => {
  const dispatch = useDispatch();
  const {openSnackbar} = useSnackbar();
  const [state, setState] = useReducer(reducer, {
    projectName: "",
    projectUrl: "",
    isLoading: false,
    organisationId: org.id,
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'projectName' && value.length > 50) {
      e.preventDefault();
      return;
    }
    setState({ type: "SET_STATE", payload: { [name]: value } });
  };

  const closeModal = () => {
    onChild();
  };

  const onFormSubmit = async () => {
    let errors = {};
    let error = false;

    if (!state.projectName) {
      errors["projectName"] = "Please enter project name.";
      error = true;
    }
    if (!state.projectUrl) {
      errors["projectUrl"] = "Please enter project URL.";
      error = true;
    }

    setState({ type: "SET_STATE", payload: { errors: errors } });

    if (error) return;

    setState({ type: "SET_STATE", payload: { isLoading: true } });

    const response = await dispatch(createProject(state));

    if (response?.payload && !response?.error) {
      if (response?.payload?.id) {
        openSnackbar({ message: "Project updated successfully!", severity: 'success' });
        updateSelectedProject(response.payload);
        onChild();
      } else {
        openSnackbar({ message: "Something went wrong, please try again!", severity: 'error' });
      }
      setState({ type: "SET_STATE", payload: { isLoading: false } });
    } else {
      openSnackbar({ message: response?.payload?.error || "Project updation failed!", severity: 'error' });
      setState({ type: "SET_STATE", payload: { isLoading: false } });
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Create Project</Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography>Project Name<span style={{color: "grey"}}> (max chars: 50)</span></Typography>
              <TextField
                size="small"
                fullWidth
                name="projectName"
                value={state.projectName}
                onChange={handleInputChange}
                error={!!state.errors.projectName}
                helperText={state.errors.projectName}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Project URL</Typography>
              <TextField
                size="small"
                fullWidth
                name="projectUrl"
                value={state.projectUrl}
                onChange={handleInputChange}
                error={!!state.errors.projectUrl}
                helperText={state.errors.projectUrl}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={onFormSubmit}
                disabled={state.isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProjectScreen;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
