import { useState } from "react";
import dropDownArrow from "../../assets/Dropdown-white.png";
import ConditionalRender from "../conditionalRender";


var SIZES = {
    LessPadding: "small_less_padding_btn",
    Small: "small_btn",
    Medium: "medium_btn",
    Big: "big_btn",
    Bigger: "very_big_btn",
    Large: "large_btn",
};
SIZES["Very Big"] = "very_big_btn";

var TYPES = {
    Primary: "an_primary_button",
    Secondary: "an_secondary_button",
};

const PrimaryButton = ({
    children,
    type = "Primary",
    onClick,
    buttonStyle,
    innerButtonStye = { width: "100%" },
    buttonSize = "Medium",
    dropOptions = [],
    dropContainerStyles={},
    dropDownTextClass = "",
    disabled = false,
    isLoading = false,
    error = "",
}) => {
    const [dropDownStatus, setDropDownStatus] = useState(false);
    let isDropDown = dropOptions.length > 0;
    let computed_classes = [];
    computed_classes += " " + TYPES[type];
    computed_classes += " " + SIZES[buttonSize];
    computed_classes += " " + (disabled ? TYPES[type] + "_disabled" : "");

    const handleDropDownStatus = (e) => {
        setDropDownStatus(!dropDownStatus);
    };

    const clickEventHandler = isDropDown ? handleDropDownStatus : onClick;

    // Renders dropdown list when true
    //To Do: Should move this to generic dropdown component which maintains its drop open/close state from parent via callbacks
    const renderDropDownList = () => {
        let container_style = {
            background: "#FFFFFF",
            position: "absolute",
            top: "100%",
            right: 0,
            boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
            listStyle: "none",
            padding: "18px 18px 0px",
            zIndex: 99,
            width: "max-content",
            ...dropContainerStyles
        }
        return (
            <>
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    onClick={handleDropDownStatus}
                ></div>
                <ul
                    style={container_style}
                >
                    {dropOptions.map((item, index) => {
                        return (
                            <li
                                className={
                                    dropDownTextClass ||
                                    "text_subtitle_semi_bold"
                                }
                                key={index}
                            >
                                <ConditionalRender if={item.checkUserAccess}>
                                <div
                                    style={{
                                        display: "block",
                                        paddingBottom: "20px",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                    }}
                                    onClick={item.onClick}
                                >
                                    {item.label}
                                </div>
                                </ConditionalRender>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    // Renders children to button comp and maintains loading status
    const renderButtonContent = () => {
        if (isLoading) {
            let loading_color_class =
                type == "Primary" ? "loader-white4" : "loader-green4";
            return (
                <div style={{ position: "relative" }}>
                    <div className="hidden_text">{children}</div>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            left: 0,
                            top: 0,
                            height: "100%",
                            textAlign: "center",
                        }}
                    >
                        <div className="vertical_middle"></div>
                        <div
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                            }}
                            className={loading_color_class}
                        >
                            <div className="loader-box loader_box1"></div>
                            <div className="loader-box loader_box2"></div>
                            <div className="loader-box loader_box3"></div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <span>{children}</span>
                {isDropDown ? (
                    <img
                        src={dropDownArrow}
                        style={{
                            display: "inline",
                            paddingLeft: 8,
                            width: 16,
                            height: 16,
                            verticalAlign: "middle",
                        }}
                    />
                ) : null}
            </>
        );
    };
    const computedStyle = buttonStyle || { width: "max-content" };
    return (
        <div
            onClick={isLoading || disabled ? () => {} : clickEventHandler}
            style={{ position: "relative", ...computedStyle }}
        >
            <button className={computed_classes} style={innerButtonStye}>
                {renderButtonContent()}
            </button>
            {dropDownStatus ? renderDropDownList() : null}
            <ConditionalRender if={error}>
                <div className="text_caption_error" style={{ marginTop: 8 }}>
                    {error}
                </div>
            </ConditionalRender>
        </div>
    );
};

export default PrimaryButton