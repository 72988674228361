import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";

import ChatsList from './ChatsList';
import ChatsDetail from './ChatsDetail';

const ChatsPage = ({ project, chatId }) => {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (chatId) {
      setCurrentView("chatsDetail");
    } else {
      setCurrentView("chatsList");
    }
  }, [chatId]);

  let content = null;
  if (currentView === 'chatsList') {
    content = (
      <ChatsList project={project} />
    );
  } else if (currentView === 'chatsDetail') {
    content = (
      <ChatsDetail project={project} chatId={chatId} />
    );
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
};

export default ChatsPage;
