import { createSlice } from '@reduxjs/toolkit';

const testScenarioSlice = createSlice({
  name: "testScenario",
  initialState: {
    isLoading: false,
    testScenariosList: [],
    pageNumber: 1,
    pageSize: 10,
    selectedTestScenarioDetail: {},
    selectedTestScenario: "",
    errors: {},
    total: 0,
    totalPages: 0,
    testScenarioTags: [],
    selectedTestScenarios: [],
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    updateTestScenariosList: (state, action) => {
      return {
        ...state,
        testScenariosList: [...action.payload.testScenariosList],
        total: action.payload.total,
        totalPages: action.payload.totalPages,
      };
    },
    setSelectedTestScenario: (state, action) => {
      return {
        ...state,
        selectedTestScenario: action.payload.testScenarioId,
      };
    },
    updateSelectedTestScenarioDetail: (state, action) => {
      return {
        ...state,
        selectedTestScenarioDetail: { ...action.payload.testScenarioDetail }
      };
    },
    setTestScenarioTags: (state, action) => {
      return {
        ...state,
        testScenarioTags: action.payload || [],
      };
    },
    setSelectedTestScenarios: (state, action) => {
      return {
        ...state,
        selectedTestScenarios: action.payload || [],
      };
    },
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  updateTestScenariosList,
  setSelectedTestScenario,
  updateSelectedTestScenarioDetail,
  setTestScenarioTags,
  setSelectedTestScenarios
} = testScenarioSlice.actions;

export default testScenarioSlice.reducer;
