import { createSlice } from '@reduxjs/toolkit';

const collectionForksSlice = createSlice({
  name: 'collectionForksSlice',
  initialState: {
    isLoading: false,
    errors: {},
    total: 0,
    totalPages: 0,
    collectionForksList: [],
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setCollectionForks: (state, action) => {
      return {
        ...state,
        collectionForksList: action.payload?.collectionForks || [],
        totalPages: action.payload?.totalPages || 0,
        total: action.payload?.total || 0
      };
    }
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setCollectionForks
} = collectionForksSlice.actions;

export default collectionForksSlice.reducer;
