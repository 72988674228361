import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const RouteRenderer = (route) => {
  const user = useSelector(state => state.user.current_user) || {};
  const token = localStorage.getItem('token');

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <route.layout isPostAuth={route.isPostAuth}>
          <route.component {...props} {...route.props} routes={route.routes} />
        </route.layout>
      )}
    />
  );
};

export default RouteRenderer;
