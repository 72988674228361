import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import {Box} from "@mui/material";

import {
  getCollectionForks
} from '../../redux-store/collectionForksReducers/collectionForksActions';

import useSearchParams from '../../hooks/useSearchParams';
import MainLayout from '../../layouts/MainLayout';
import CollectionForksList from './CollectionForksList';

const CollectionForksPage = ({ project, totalPages, total, isLoading }) => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');
  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);

  useEffect(() => {
    getCollectionForksList();
    // keep page query-param in sync with state
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  const getCollectionForksList = async () => {
    await dispatch(
      getCollectionForks({
        projectId: project.id,
        pageNumber: pageNumber,
        pageSize: 10,
      })
    );
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Collection Forks"
      subtitleText={`Showing ${total || 0} collection fork(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto"}}>
        <CollectionForksList />
      </Box>
    </MainLayout>
  );
};

function mapStateToProps(state, ownProps) {
  const collectionForks = state.collectionForks;
  return {
    isLoading: collectionForks.isLoading,
    totalPages: collectionForks.totalPages,
    total: collectionForks.total,
    ...ownProps
  }
}
export default connect(mapStateToProps)(CollectionForksPage)
