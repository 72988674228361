import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import BuildHistory from "../Common/BuildHistory";

const TestPlanBuildList = function({ testPlanBuildList, testPlanId, pageNumber }) {
    const isLoading = useSelector(state => state.testPlan.isLoading);
    const history = useHistory();

    useEffect(() => {
        history.push(`/test-plans/${testPlanId}/build/?page=${pageNumber}`);
    }, [pageNumber])

    const onViewBuild = async (id) => {
        history.push(`/test-plans/${testPlanId}/build/${id}/?page=${pageNumber}`);
    }

    const onViewSource = () => {
        history.push(`/test-plans/${testPlanId}/`);
    }

    return (
        <Box>
            <BuildHistory buildList={testPlanBuildList} onViewBuild={onViewBuild} onViewSource={onViewSource} isLoading={isLoading} />
        </Box>
    )
}

export default TestPlanBuildList;
