import React, {useState} from 'react'
import {Typography, Box, Checkbox, Chip, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel} from '@mui/material';
import { Close, Add, ExpandLess } from '@mui/icons-material'
import CustomButton from '../Common/CustomButton';
import MonacoEditor from "react-monaco-editor";

import jsFunctionUtils from '../../utils/jsFunctionUtils';

import "../../styles/components/TestScenarios/TestRulesSuggestions.scss";

const TestRulesSuggestions = ({testRules, saveTestRules, setAlert, onCancel}) => {
  const [checkedIds, setCheckedIds] = useState([]);
  const [expandedId, setExpandedId] = useState("");
  
  const handleChange = (id, value) => {
    setCheckedIds(prevState => {
      const index = prevState.indexOf(id);
      if (value) return [...checkedIds, id];
      return [...prevState.slice(0, index), ...prevState.slice(index+1, prevState.length)];
    })
  }

  const clearSelection = () => {
    setCheckedIds([]);
    if (!checkedIds.length)
      onCancel();
  }

  const onSave = () => {
    saveTestRules(checkedIds);
    setCheckedIds([]);
  }

  const onExpand = (ruleId) => {
    let newValue = ruleId;
    if (ruleId === expandedId) newValue = "";
    setExpandedId(newValue);
  }

  const renderSingleRule = (rule) => {
    return (
      <Accordion expanded={rule.id === expandedId}>
        <AccordionSummary
          expandIcon={<ExpandLess />}
          aria-controls="suggested-testrule-content"
          id={rule.id}
          onClick={() => {onExpand(rule.id)}}
        >
          <div style={{width: "100%"}} className="title">
            <Checkbox onClick={(event) => {event.stopPropagation();}} onChange={(event, value) => { handleChange(rule.id, value)}} checked={checkedIds.indexOf(rule.id) !== -1} />
            <div className="truncate-1-lines" style={{ width: "calc(100% - 180px)", display: "inline-block", whiteSpace: "pre", verticalAlign: "middle"}}>{rule?.title}</div>
            <Chip label="Suggested by AI" className="suggestedTag" />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{border: "1px solid #e4e4e4", borderRadius: "4px", p: 1}}>
            <Typography sx={{mb: "4px", fontWeight: 550, position: "relative"}}>
              function () {"{"}
            </Typography>
            <MonacoEditor
              className="jsEditor"
              language="javascript"
              theme="vs-dark"
              value={rule.expression}
              height="150"
              style={{borderBottom: "1px solid #e4e4e4"}}
              options={{
                automaticLayout: true,
                wordWrap: "on",
                minimap: { enabled: false },
                lineNumbers: "off",
                tabSize: 4,
                overviewRulerLanes: 0,
                readOnly: true
              }}
            />
            <Typography sx={{fontWeight: 550}}>{"}"}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderRules = () => {
    return (
      testRules.map((rule) => {
        return (
          <div key={rule.id} style={{margin: "8px 0"}}>
            {renderSingleRule(rule)}
          </div>
        )
      })
    )
  }
  return (
    <Box sx={{my: 1}} className="testRulesSuggestions">
      {renderRules()}
      <CustomButton startIcon={<Add />} disabled={checkedIds.length === 0} sx={{mr: 1}} onClick={onSave}>Save</CustomButton>
      <CustomButton startIcon={<Close />} onClick={clearSelection}>{checkedIds.length ? "Clear Selection" : "Cancel"}</CustomButton>
    </Box>
  )
}

export default TestRulesSuggestions
