import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TestScenarioBuildList from "./TestScenarioBuildList";
import MainLayout from "../../layouts/MainLayout";

import { getAllBuilds } from "../../redux-store/testScenarioBuildReducers/testScenarioBuildActions";
import { fetchTestScenarioDetail } from "../../redux-store/testScenarioReducers/testScenarioActions";

const TestScenarioBuildListPage = ({ testScenarioId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pageSize = 10;
  const page = history.location.search.split("page=")[1];
  const [pageNumber, setPageNumber] = useState(page ? page * 1 : 1);
  const [testScenarioBuildList, setTestScenarioBuildList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBuilds, setTotalBuilds] = useState(0);
  const isLoading = useSelector((state) => state.testScenarioBuild.isLoading);
  const testScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );

  useEffect(() => {
    getTestScenarioDetail();
    getTestScenarioBuildList(pageNumber, pageSize);
  }, []);

  const getTestScenarioDetail = async () => {
    await dispatch(fetchTestScenarioDetail({ testScenarioId: testScenarioId }));
  };

  const getTestScenarioBuildList = async (pageNumber, pageSize) => {
    const testScenarioBuildResponse = await dispatch(
      getAllBuilds({
        testScenarioId: testScenarioId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      })
    );
    if (testScenarioBuildResponse?.payload) {
      setTestScenarioBuildList(
        testScenarioBuildResponse.payload?.test_scenario_builds || []
      );
      setTotalPages(testScenarioBuildResponse.payload?.total_pages);
      setTotalBuilds(testScenarioBuildResponse.payload?.total)
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
    getTestScenarioBuildList(value, pageSize);
  };

  return (
    <MainLayout
      headerText={testScenarioDetail?.title}
      subtitleText={testScenarioDetail?.description}
      isLoading={isLoading}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      totalPages={totalPages}
    >
        <h4 style={{color: "rgba(0, 0, 0, 0.87)", padding: "0px 24px"}}>All builds ({totalBuilds})</h4>
        <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "auto"}}>
            <TestScenarioBuildList
                testScenarioBuildList={testScenarioBuildList}
                testScenarioId={testScenarioId}
                isLoading={isLoading}
                pageNumber={pageNumber}
            />
        </Box>
    </MainLayout>
  );
};

export default TestScenarioBuildListPage;
