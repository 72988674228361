import React, {useState} from 'react';
import {Typography, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Button} from "@mui/material";
import { useEffect } from 'react';

const ForkModal = ({isOpen, onClose, onConfirm, collection, organisations=[]}) => {
  const [projectId, setProjectId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (organisations.length === 1) {
      setOrgId(organisations?.id);
      setProjects(organisations[0]?.projects);
    }
  }, []);

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(collection?.id, projectId);
  }

  const handleOrgChange = (event) => {
    const org = event.target.value;
    if (org) {
      setOrgId(org);
      const projects = organisations?.filter(o => o?.id === org)?.[0]?.projects || [];
      setProjects(projects);
    }
  }

  const handleProjectChange = (event) => {
    setProjectId(event.target.value);
  }

  const labelStyle = {fontFamily: "Inter, Sans-serif", fontWeight: "550"};
  const textStyle = {fontFamily: "Inter, Sans-serif", fontWeight: "500"}

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose} >
      <DialogTitle sx={{labelStyle, fontWeight: "600", fontSize: "20px"}}>Fork this collection</DialogTitle>
      <DialogContent>
        <Typography sx={labelStyle}>Collection Name</Typography>
        <Typography sx={{...textStyle, mb: 2}}>{collection?.name}({collection?.slug})</Typography>
        <FormControl fullWidth sx={{mb: 2}}>
          <Typography sx={labelStyle}>Select Organisation</Typography>
          <Select
            size="small"
            labelId="org-select-label"
            id="org-simple-select"
            value={orgId}
            label=""
            onChange={handleOrgChange}
            sx={textStyle}
          >
            {organisations?.map((org, index) => {
              return (
                <MenuItem sx={textStyle} value={org?.id} key={org?.id}>{org?.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{mb: 2}}>
          <Typography sx={labelStyle}>Select Project</Typography>
          <Select
            size="small"
            labelId="project-select-label"
            id="project-simple-select"
            value={projectId}
            label=""
            onChange={handleProjectChange}
            sx={textStyle}
          >
            {projects?.map((project, index) => {
              return (
                <MenuItem sx={textStyle} value={project?.id} key={project?.id}>{project?.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{padding: "20px 24px"}}>
        <Button onClick={onClose} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>Cancel</Button>
        <Button variant="contained" onClick={handleConfirm} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}} disabled={!projectId}>
          Confirm Fork
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ForkModal;
