import React from "react";
import {Box, Link} from "@mui/material";

const ApiCollection = ({icon}) => {
  return (
    <Box>
      {/* <div className="title">
        {icon ? icon : <div className="menuIcon apiCollection" />}
        <h3>API Collection</h3>
      </div> */}
      <p style={{marginTop: "0px"}}>
        You can add your API collection automatically via our SDK or manually via postman or swagger exports.
      </p>
      <p>
        You can add your API collection via:
      </p>
      <ul>
        <li>
          Automatically by QodexAI SDK: Use our Ruby and NodeJS SDK to automatically add API collections to your project. Our SDK analyzes logs to build and keep the API collection up-to-date.
          <Link className="link" href="https://qodex.ai" target="_blank">Read more</Link>
        </li>
        <li>
          Postman/Swagger exports: You can import your Postman or Swagger exports directly into your project.
        </li>
        <li>
          Manual Creation: You can always add APIs manually, just like you do on Postman.<Link className="link" href="https://qodex.ai" target="_blank">Qodex.ai</Link> acts as an open-source Postman alternative, free forever.
        </li>
      </ul>
    </Box>
  )
}

export default ApiCollection;
