import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Avatar
} from "@mui/material";
import {
  ExpandMore,
  GradingOutlined,
  FormatListBulletedOutlined,
  CheckCircleOutline,
  ErrorOutline,
  HourglassTopOutlined,
  CallMissedOutgoingOutlined,
  RefreshOutlined
} from "@mui/icons-material";
import BuildLogsContainer from "./BuildLogsContainer";
import LoadingOverlay from "../LoadingOverlay";
import MainLayout from "../../../layouts/MainLayout";

const BuildLevel = memo(
  ({
    buildType,
    buildId,
    getBuild,
    // getLoadingState,
    getLevelInfo,
    expanded = false,
    root = false,
  }) => {
    const expandedBuildsRef = useRef({ [buildType]: [] });
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [buildDetail, setBuildDetail] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const testPlanBuilds = useSelector((state) => state.testPlanBuild.builds);
    const testSuiteBuilds = useSelector((state) => state.testSuiteBuild.builds);
    const testScenarioBuilds = useSelector(
      (state) => state.testScenarioBuild.builds
    );
    const testPlanBuildsLoading = useSelector(
      (state) => state.testPlanBuild.isLoading
    );
    const testSuiteBuildsLoading = useSelector(
      (state) => state.testSuiteBuild.isLoading
    );
    const testScenarioBuildsLoading = useSelector(
      (state) => state.testScenarioBuild.isLoading
    );

    useEffect(() => {
      if (expanded) {
        setIsExpanded(expanded);
        getBuildDetail(true);
      }
    }, [buildId, buildType]);

    useEffect(() => {
      const savedBuildDetail = isBuildPresent();
      if (savedBuildDetail) setBuildDetail({ ...savedBuildDetail });
    }, [testPlanBuilds, testSuiteBuilds, testScenarioBuilds]);

    useEffect(() => {
      if (buildType === "testPlan")
        setIsLoading(testPlanBuildsLoading);
      if (buildType === "testSuite")
        setIsLoading(testSuiteBuildsLoading);
      if (buildType === "testScenario")
        setIsLoading(testScenarioBuildsLoading);
    }, [testPlanBuildsLoading, testSuiteBuildsLoading, testScenarioBuildsLoading]);

    const getBuildDetail = (force) => {
      const savedBuildDetail = isBuildPresent();
      const levelInfo = getLevelInfo(buildType);
      if (
        force ||
        !savedBuildDetail ||
        !savedBuildDetail?.[levelInfo?.childBuildKey] ||
        savedBuildDetail?.status === "running" ||
        savedBuildDetail?.status === "queued"
      ) {
        getBuild(buildType, buildId);
      }
    };

    const refreshLogs = (force=true) => {
      getBuildDetail(force);
    }

    const isBuildPresent = () => {
      switch (buildType) {
        case "testPlan":
          return testPlanBuilds?.[buildId];
        case "testSuite":
          return testSuiteBuilds?.[buildId];
        case "testScenario":
          return testScenarioBuilds?.[buildId];
      }
      return null;
    };

    const shouldRefetch = (build, levelInfo) => {
      if (!build?.[levelInfo.childBuildKey]) {
        return true;
      }
    };

    const onExpand = () => {
      let expandedBuilds = expandedBuildsRef.current[buildType] || [];
      if (expandedBuilds.indexOf(buildId) === -1) {
        expandedBuilds = [...expandedBuilds, buildId];
        setIsExpanded(true);
      } else {
        const buildIndex = expandedBuilds.findIndex((id) => id === buildId);
        expandedBuilds = [
          ...expandedBuilds.slice(0, buildIndex),
          ...expandedBuilds.slice(buildIndex + 1),
        ];
        setIsExpanded(false);
      }
      expandedBuildsRef.current = {
        ...expandedBuildsRef.current,
        [buildType]: expandedBuilds,
      };
      getBuildDetail(true);
    };

    const getStatus = (statusType, size="small") => {
      let status;
      let statusIcon;
      if (statusType === "fail") {
        status = <span style={{ color: "#CE4141" }}>Failed</span>;
        statusIcon = (<ErrorOutline sx={{ color: "#CE4141", mr: 1 }} size={size} />);
      }
      if (statusType === "pass") {
        status = <span style={{ color: "#2CAC0C" }}>Passed</span>;
        statusIcon = (<CheckCircleOutline sx={{ color: "#2CAC0C", mr: 1 }} size={size} />);
      }
      if (statusType === "running" || statusType === "queued" || statusType === "skipped") {
        status = <span style={{ textTransform: "capitalize" }}>{statusType}</span>;
        statusIcon = (statusType === "running" || statusType === "queued") ? (<HourglassTopOutlined sx={{ mr: 1 }} size={size} />) : (<CallMissedOutgoingOutlined sx={{ mr: 1 }} size={size} />);
      }
      return {status, statusIcon}
    }

    const renderContentHeading = () => {
      return (
        <Stack direction="row" spacing={1} sx={{p: 2, pt: 0}}>
          <h5 style={{color: "black"}}>
            <FormatListBulletedOutlined sx={{ mr: 1 }} />
            Execution Logs
          </h5>
          <RefreshOutlined onClick={refreshLogs} sx={{cursor: "pointer", width: "24px", height: "24px", color: "black"}} />
          {/* {buildDetail?.status === "running" && (<RefreshOutlined onClick={refreshLogs} />)} */}
        </Stack>
      );
    }

    const renderMessage = (build) => {
      let msg = [];
      if (build.total_count >= 0) {
        msg.push(
          <span style={{ marginRight: "4px" }} key="total_count">
            {build.total_count} {buildType === "testScenario" ? " total cases" : " total scenarios"}
          </span>
        );
      }
      if (build.passed_count > 0) {
        msg.push(
          <span style={{ color: "#2CAC0C", marginRight: "0px" }} key="passed_count">
            , {build.passed_count} passed
          </span>
        );
      }
      if (build.failed_count > 0) {
        msg.push(
          <span style={{ color: "#CE4141", marginRight: "0px" }} key="failed_count">
            , {build.failed_count} failed
          </span>
        );
      }
      if (build.skipped_count > 0) {
        msg.push(
          <span style={{ color: "grey", marginRight: "0px" }} key="skipped_count">
            , {build.skipped_count} skipped
          </span>
        );
      }
      return msg;
    };

    const renderHeader = () => {
      const levelInfo = getLevelInfo(buildType);
      const {status, statusIcon} = getStatus(buildDetail?.status, "large");
      const onViewSource = () => {
        let url;
        if (buildDetail?.test_scenario?.id) {
          url = `${window.location.origin}/test-scenarios/${buildDetail.test_scenario.id}/`;
        } else if (buildDetail?.test_suite?.id) {
          url = `${window.location.origin}/test-suites/${buildDetail.test_suite.id}/`;
        } else if (buildDetail?.test_plan?.id) {
          url = `${window.location.origin}/test-plans/${buildDetail.test_plan.id}/`;
        }
        window.open(
          url,
          "_blank"
        );
      }
      return (
        <>
          <h4 style={{color: "black", fontSize: '24px',}}>
            <GradingOutlined sx={{ mr: 1 }} />
            {levelInfo.name} Report:{" "}
            {buildDetail?.build_number >= 0 && (`TR-000${buildDetail?.build_number}`)}
          </h4>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ color: "black" }} >
            {statusIcon}
            {status}
            <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0 }} />
            <span style={{ textTransform: "capitalize" }}>
              Environment - {buildDetail?.environment_set?.title}
            </span>
            <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
            {buildDetail?.execution_time > 0 && (
              <>
                <span>{buildDetail?.execution_time?.toFixed(2)} seconds</span>
                <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
              </>
            )}
            {
              buildDetail?.source_code && 
              (
                <>
                  <span>Source : </span>
                  <span onClick={onViewSource} style={{cursor: "pointer", textDecoration: "underline"}}>
                    {buildDetail?.source_code}
                  </span>
                </>
              )
            }
            <span style={{ textTransform: "capitalize" }}>
              {renderMessage(buildDetail)}
            </span>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ color: "black" }} >
          </Stack>
        </>
      );
    };

    const renderSummary = () => {
      const levelInfo = getLevelInfo(buildType);
      const {status, statusIcon} = getStatus(buildDetail?.status);
      return (
        <>
          <div style={{ width: "calc(100% - 200px)", display: "inline-block" }}>
            <h6>{buildDetail?.source_code}: {buildDetail?.[levelInfo?.id]?.title}</h6>
          </div>
          <div style={{ width: "200px", display: "inline-block"}}>
            <Stack direction="row-reverse" spacing={1} sx={{ color: "black", mb: 1}} >
              {buildDetail?.execution_time > 0 && 
                <>
                  <span>{buildDetail?.execution_time?.toFixed(2)} seconds</span>
                  <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
                </>
              }
              {status}
              {statusIcon}
            </Stack>
            {/* <Stack direction="row-reverse" spacing={1}>
              <span>{"Mohan Rajoria"}</span>
              <Avatar
                alt={"Mohan Rajoria"}
                src={"Mohan Rajoria"}
                sx={{ width: 24, height: 24 }}
              />
            </Stack> */}
          </div>
        </>
      );
    };

    const renderDetails = () => {
      const levelInfo = getLevelInfo(buildType);
      const childBuildKey = levelInfo.childBuildKey;
      const childBuildType = levelInfo.childBuildType;
      const builds = buildDetail[childBuildKey];
      if (levelInfo.childType === "log") {
        return <BuildLogsContainer
          key={buildDetail?.id}
          buildId={buildDetail?.id}
          buildType={levelInfo.childType}
          getLevelInfo={getLevelInfo}
          getBuild={getBuild}
          expanded={false}
          getStatus={getStatus}
        />
      }
      if (builds) {
        return builds.map((build) => {
          const nextLevelInfo = getLevelInfo(childBuildType);
          if (nextLevelInfo.type === "level") {
            return (
              <BuildLevel
                key={build?.id}
                buildType={childBuildType}
                buildId={build?.id}
                expanded={false}
                getLevelInfo={getLevelInfo}
                getBuild={getBuild}
              />
            );
          }
        });
      } else {
        return <div>Loading...</div>;
      }
    };

    let content = null;
    if (!buildDetail) {
      content = <div>Loading...</div>;
    } else if (root) {
      return (
        <MainLayout renderHeader={renderHeader} sxLeftSide={{maxWidth: "80%"}}>
          {renderContentHeading()}
          <Box sx={{p: 2, maxHeight: "calc(100vh - 200px)", overflow: "auto", color: "black", mb: 1}}>
            {renderDetails()}
            {/* {isLoading ? (<LoadingOverlay isLoading={isLoading} />) : null} */}
          </Box>
        </MainLayout>
      )
    } else {
      return (
        <Box sx={{ color: "black", mb: 1 }}>
          <Accordion expanded={isExpanded} onChange={onExpand} sx={{boxShadow: "none", border: "1px solid #e4e4e4" }}>
            <AccordionSummary
              aria-controls="build-content"
              id="build-header"
            >
              {renderSummary()}
            </AccordionSummary>
            <AccordionDetails>{isExpanded && renderDetails()}</AccordionDetails>
          </Accordion>
          {/* {isLoading ? (<LoadingOverlay isLoading={isLoading} />) : null} */}
        </Box>
      )
    }
  }
);

export default BuildLevel;
