import React, {useState, useRef, useEffect} from "react";
import { useSelector } from "react-redux";
import {Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import MonacoEditor from "react-monaco-editor";

import coreUtils from "../../utils/coreUtils.js";

import "../../styles/components/Functions/FunctionModal.scss";

const FunctionModal = ({isOpen, onClose, actionType, onConfirm, detail={}}) => {
  const [name, setName] = useState(detail?.function_name || "");
  const [args, setArgs] = useState(detail?.fuction_args || "");
  const [code, setCode] = useState(detail?.js_code || "");
  const [errors, setErrors] = useState({});
  const isLoading = useSelector(state => state.function.isLoading);
  const nameRef = useRef(detail?.name);

  useEffect(() => {
    setName(detail?.function_name);
    setArgs(detail?.function_args);
    setCode(detail?.js_code);
    nameRef.current = detail?.function_name;
  }, [detail]);
  
  const handleConfirmClick = (e) => {
    const errorsObj = validate();
    if (Object.keys(errorsObj).length > 0) {
      return;
    }
    onConfirm(actionType, {
      name: name?.trim(),
      args: args?.trim(),
      code: code
    });
    // handleClose();
  }

  const handleClose = () => {
    setName("");
    setArgs("");
    setCode("");
    setErrors({});
    onClose();
  }

  const handleChange = (name, value) => {
    if (name === "name") setName(value);
    else setArgs(value);
    if (errors.name || errors.args) validate();
  }

  const handleCodeChange = (code) => {
    setCode(code);
    if (code?.trim()) setErrors(prevState => ({...prevState, code: ""}));
  }

  const validate = () => {
    const errors = {};
    const nameReg = RegExp(/^[a-zA-Z0-9]+$/);
    let nameError = coreUtils.isStringInvalidOrBlank(name) ? "Required" : "";
    if (!nameError) {
      nameError = nameReg.test(name.trim()) ? "" : "Invalid function name, should only consist of [a-z,A-Z,0-9]";
    }
    if (!nameError && actionType === "duplicate") {
      nameError = name?.trim() === nameRef.current ? "Function name already exist" : "";
    }
    if (nameError) errors.name = nameError;
    // errors.args = coreUtils.isStringInvalidOrBlank(args) ? "" : ""
    if (coreUtils.isStringInvalidOrBlank(code))
      errors.code = "Function code should be a valid expression.";
    setErrors(errors);
    return errors;
  }

  if (!isOpen) {
    return null;
  }

  let title = "";
  let btnText = "";

  if (actionType === "create" || actionType === "duplicate") {
    title = actionType === "create" ? "Create JS function" : "Duplicate JS function";
    btnText = "Create";
  } else {
    title = "Edit JS function";
    btnText = "Update";
  }

  return (
    <Dialog maxWidth="sm" fullWidth onClose={(e) => {e.preventDefault();}} open={isOpen} className="functionModal">
      <DialogTitle><h4>{title}</h4></DialogTitle>
      <DialogContent>
        <h6>Name <span style={{fontSize: "12px"}}>[a-z,A-Z,0-9]</span></h6>
        <TextField value={name} size="small" fullWidth onChange={(e) => {handleChange("name", e.target.value)}} placeholder="name" error={!!errors.name} />
        {errors.name && <span className="error">{errors.name}</span>}
        <h6>Arguments</h6>
        <TextField value={args} size="small" fullWidth onChange={(e) => {handleChange("args", e.target.value)}} placeholder="arguments" error={!!errors.args} />
        {/* {errors.args && <span className="error">{errors.args}</span>} */}
        <h6>Code</h6>
        <Box sx={{border: "1px solid #e4e4e4", borderRadius: "4px", p: 1}}>
          <Typography sx={{mb: "4px", color: "#045804", fontWeight: 550}}>function {name}({args}) {"{"}</Typography>
          <MonacoEditor
            className="jsEditor"
            language="javascript"
            theme="vs-light"
            value={code}
            height="120"
            onChange={(value) => {handleCodeChange(value)}}
            style={{borderBottom: "1px solid #e4e4e4"}}
            options={{
              automaticLayout: true,
              wordWrap: "on",
              minimap: { enabled: false },
              lineNumbers: "off",
              tabSize: 4,
            }}
          />
          <Typography sx={{color: "#045804", fontWeight: 550}}>{"}"}</Typography>
        </Box>
        {errors.code && <span className="error">{errors.code}</span>}
      </DialogContent>
      <DialogActions sx={{my: 2, p: "0px 24px"}}>
        <Button disabled={isLoading} onClick={handleClose} variant="outlined" color="error">Discard</Button>
        <Button disabled={isLoading} onClick={handleConfirmClick} variant="outlined" color="primary">
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FunctionModal;
