import React, { useState } from "react";
import { Box } from "@mui/material";
import TabsComponent from "./TabsComponent";
import JsonBody from "../Common/JsonBody";
import EntityStatus from "./EntityStatus";
import KeyValueTable from "../Common/KeyValueTable";

import coreUtils from "../../utils/coreUtils";

const EntityResponse = ({ response }) => {
  const tabLabels = ["Body", "Headers", "Status"];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex);
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        let headerItems = [];
        const headers = response?.headers;
        if (headers) {
          headerItems = coreUtils.parseObjectToKeyValueArray(headers);
        }
        return <KeyValueTable allItems={headerItems} json={true} sxCell={{textAlign: "left"}} isReadOnly />;
      case 2:
        return <EntityStatus status={response?.status || ""} />;
      default:
        return (
          <JsonBody
            jsonData={response?.body}
          />
        );
    }
  };
  return (
    <Box sx={{ py: 2 }}>
      <TabsComponent
        tabLabels={tabLabels}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        customTabStyle={{ width: "auto", fontSize: "12px", fontWeight: 600 }}
        tabsStyle={{ border: "none" }}
        activeTabStyle={{ backgroundColor: "transparent" }}
      />
      <Box sx={{ height: "500px", overflow: "auto" }}>{renderTabContent()}</Box>
    </Box>
  );
};

export default EntityResponse;
