import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const QueryParamsView = ({ initialParams }) => {
  return (
    <Box id='boundary'>
      <Typography variant="body1">
        Query Params
      </Typography>
      <TableContainer className='table-layout' sx={{ borderRadius: '5px' }}>
        <Table size='small' aria-label="simple" sx={{ border: 'none', padding: '0' }}>
          <TableHead>
            <TableRow sx={{ alignItems: 'center' }}>
              <TableCell sx={{ textAlign: 'center' }}>Key</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {initialParams && initialParams.map((param, index) => (
              <TableRow key={index} sx={{ border: 'none' }}>
                <TableCell sx={{ textAlign: 'center' }}>
                  {param.name}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {param.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default QueryParamsView;
