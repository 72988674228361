import { createSlice } from '@reduxjs/toolkit';

const testSuiteSlice = createSlice({
  name: "testSuiteSlice",
  initialState: {
    isLoading: false,
    errors: {},
    total: 0,
    totalPages: 0,
    testSuitesList: [],
    testSuiteTags: [],
    selectedTestSuites: [],
    selectedTestSuiteDetail: {},
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    updateTestSuitesList: (state, action) => {
      return {
        ...state,
        testSuitesList: [...action.payload.testSuitesList],
        total: action.payload.total,
        totalPages: action.payload.totalPages,
      };
    },
    setTestSuiteTags: (state, action) => {
      return {
        ...state,
        testSuiteTags: action.payload || [],
      };
    },
    setSelectedTestSuites: (state, action) => {
      return {
        ...state,
        selectedTestSuites: action.payload || [],
      };
    },
    updateSelectedTestSuiteDetail: (state, action) => {
      return {
        ...state,
        selectedTestSuiteDetail: { ...action.payload.testSuiteDetail },
      };
    },
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  updateTestSuitesList,
  setTestSuiteTags,
  setSelectedTestSuites,
  updateSelectedTestSuiteDetail
} = testSuiteSlice.actions;

export default testSuiteSlice.reducer;
