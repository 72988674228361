import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box} from "@mui/material";

import MetadataHeader from '../Common/MetadataHeader';

import { updateTestPlan } from '../../redux-store/testPlanReducers/testPlanActions';

const TestPlanHeader = ({testPlanId}) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(state => state.testPlan.isSaving);

  const handleTestPlanUpdate = async (data) => {
    return dispatch(updateTestPlan({testPlanId: testPlanId, data: data}));
  }

  return (
    <Box className="testPlanHeader">
      <MetadataHeader id={testPlanId} onUpdate={handleTestPlanUpdate} type="testPlan" isLoading={isSaving} />
    </Box>
  )
}

export default TestPlanHeader;
