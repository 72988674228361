import { createSlice } from "@reduxjs/toolkit";

const chatsSlice = createSlice({
  name: "chats",
  initialState: {
    chatList: [],
    chatDetail: {}
  },
  reducers: {
    setChatList: (state, action) => {
      return {
        ...state,
        chatList: [...action.payload.dataSetList]
      };
    },
    setChatDetail: (state, action) => {
      return {
        ...state,
        chatDetail: {...state, [action.payload.id]: action.payload.detail}
      }
    }
  },
});

export const {
  setChatList,
  setChatDetail
} = chatsSlice.actions;

export default chatsSlice.reducer;
