import React, { useState, useEffect } from 'react';
import { Box, Radio, Typography, FormControlLabel, Button } from '@mui/material';
import BodyRaw from './BodyRaw';
import FormDataComponent from './FormDataComponent';
import Binary from './Binary';

const BodyComponent = ({ body, bodyType, onBodyChange, hideExtraFields=false }) => {
  const [bodyRaw, setBodyRaw] = useState();
  const [bodyForm, setBodyForm] = useState();
  const [bodyFormEncoded, setBodyFormEncoded] = useState();
  const [bodyGraphQl, setBodyGraphQl] = useState();
  const [bodyNone, setBodyNone] = useState();

  useEffect(() => {
    if (bodyType === 'raw') setBodyRaw(body);
    if (bodyType === 'form-data') setBodyForm(body);
    if (bodyType === 'x-www-form-urlencoded') setBodyFormEncoded(body);
    if (bodyType === 'graphql') setBodyGraphQl(body);
    if (bodyType === 'none_type') setBodyNone();
  }, [body, bodyType]);

  const handleRadioChange = (event) => {
    if (event.target.value === 'raw') {
      onBodyChange(bodyRaw, event.target.value);
    } else if (event.target.value === 'form-data') {
      onBodyChange(bodyForm, event.target.value);
    } else if (event.target.value === 'x-www-form-urlencoded') {
      onBodyChange(bodyFormEncoded, event.target.value);
    } else if (event.target.value === 'graphql') {
      onBodyChange(bodyGraphQl, event.target.value);
    } else if (event.target.value === 'none_type') {
      onBodyChange(bodyNone, event.target.value);
    }
  };

  const handleBodyChange = (newBody) => {
    if (bodyType === 'raw') {
      setBodyRaw(newBody);
    } else if (bodyType === 'form-data') {
      setBodyForm(newBody);
    } else if (bodyType === 'x-www-form-urlencoded') {
      setBodyFormEncoded(newBody);
    } else if (bodyType === 'graphql') {
      setBodyGraphQl(newBody);
    } else if (bodyType === 'none_type') {
      setBodyNone();
    }
    onBodyChange(newBody, bodyType);
  };

  const beautifyBody = () => {
    // Placeholder for beautification logic (e.g., formatting JSON)
    if (bodyType === 'raw' && bodyRaw) {
      try {
        const beautified = JSON.stringify(JSON.parse(bodyRaw), null, 2);
        setBodyRaw(beautified);
        onBodyChange(beautified, 'raw');
      } catch (error) {
        console.error("Invalid JSON format");
      }
    }
  };

  const renderBodyContent = () => {
    switch (bodyType) {
      case 'none_type':
        return (
          <Typography sx={{ color: '#999', margin: '0px 10px', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "11px" }}>
            This request does not have a body
          </Typography>
        );
      case 'raw':
        return <BodyRaw body={bodyRaw} onBodyChange={handleBodyChange} />;
      case 'form-data':
        return (
          <FormDataComponent body={bodyForm} onBodyChange={handleBodyChange} hideExtraFields={hideExtraFields} />
        );
      case 'x-www-form-urlencoded':
        return (
          <FormDataComponent
            body={bodyFormEncoded}
            onBodyChange={handleBodyChange}
            hideExtraFields={hideExtraFields}
          />
        );
      case 'binary':
        return <Binary />;
      case 'graphql':
        return <BodyRaw body={bodyGraphQl} onBodyChange={handleBodyChange} />;
      default:
        return (
          <Typography sx={{ color: '#999', margin: '0px 10px' }}>
            This request does not have a body
          </Typography>
        );
    }
  };

  const bodyOptions = [
    {body_type: 'none_type', display_text: 'none'},
    {body_type: 'form-data', display_text: 'form-data'},
    {body_type: 'x-www-form-urlencoded', display_text: 'x-www-form-urlencoded'},
    {body_type: 'raw', display_text: 'raw'},
    {body_type: 'graphql', display_text: 'graphql'}
  ]

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem', marginLeft: '0.5rem' }}>
        {bodyOptions.map(
          (bodyOption) => (
            <FormControlLabel
              key={bodyOption.body_type}
              control={
                <Radio
                  checked={bodyType === bodyOption.body_type}
                  onChange={handleRadioChange}
                  value={bodyOption.body_type}
                  name="body-option"
                  color="primary"
                  sx={{ transform: 'scale(0.9)' }}
                />
              }
              label={bodyOption.display_text}
              labelPlacement="end"
              sx={{
                span : {
                  fontFamily: "Inter, Sans-serif",
                  fontWeight: "500",
                  fontSize: "11px",
                  ml: 0,
                }
              }}
            />
          )
        )}
        {bodyType === 'raw' && (
          <Button
            variant="outlined"
            size="small"
            onClick={beautifyBody}
            sx={{ marginLeft: 'auto', marginRight: '0.5rem', fontSize: '10px', textTransform: 'none', fontWeight:'600', outline: 'none', boxShadow: 'none', '&:focus': { outline: 'none' }, '&:active': { boxShadow: 'none' } }}
          >
            Beautify
          </Button>
        )}
      </Box>
      {renderBodyContent()}
    </>
  );
};

export default BodyComponent;
