import {createSlice} from '@reduxjs/toolkit';

const testPlanBuildSlice = createSlice({
    name: "testPlanBuildSlice",
    initialState: {
        isLoading: false,
        errors: {},
        total: 0,
        totalPages: 0,
        builds: {},
        testPlanBuildList: []
    },
    reducers: {
        updateLoadError: (state, action) => {
            return {
                ...state,
                errors: action.payload.error_message
            };
        },
        setLoadingState: (state, action) => {
            return {
                ...state,
                isLoading: action.payload
            }
        },
        setSavingState: (state, action) => {
            return {
                ...state,
                isSaving: action.payload
            }
        },
        setBuilds: (state, action) => {
            return {
              ...state,
              builds: {
                ...state.builds,
                ...{ ...action.payload },
              },
            };
        },
        setTestPlanBuildList: (state, action) => {
            return {
              ...state,
              total: action?.payload?.total,
              totalPages: action?.payload?.total_pages,
              testPlanBuildList: [...action?.payload?.test_plan_builds]
            }
        }
    }
})

export const {
    updateLoadError,
    setLoadingState,
    setSavingState,
    setBuilds,
    setTestPlanBuildList
} = testPlanBuildSlice.actions;

export default testPlanBuildSlice.reducer;