import Switch from "@mui/material/Switch";
import React, { useState } from 'react';

const ToggleSwitchComponent = ({labelHead, label, showsettings}) => {

  const initialSettings = [
    { id: 1, name: 'Enable SSL certificate verification', value: false },
    { id: 2, name: 'Automatically follow redirects', value: false },
    // Add more settings as needed
  ];
  const [settings, setSettings] = useState(initialSettings);


  const [switchValue, setSwitchValue] = useState(false);

  const handleSwitchToggle = (labelHead) => {
  };

  const setting = settings.find((setting) => setting.name === labelHead);

  return (
      <>
        <div style={{marginTop: "30px"}}>
            <div style={{ color: "grey", marginBottom: "20px", fontSize: "12px", fontWeight: "800"}}>
              {labelHead}
            </div>

            <div style={{ marginLeft: "95%", marginTop: "-50px" }}>
              {/* <Switch checked={switchValue} onChange={handleSwitchToggle} /> */}
              <Switch checked={setting?.value} onChange={() => handleSwitchToggle(labelHead)} />
            </div>

            <div style={{ color: "grey", fontWeight: "500" , display: "flex", fontSize: "11px"}}>
              {label}
            </div>

            { showsettings && <div style={{color: "grey", display: 'flex',justifyContent: 'flex-end', marginTop: "-25px", fontWeight: "600", fontSize: "12px"}}>
              Default: Settings
            </div>}
        </div>
      </>
  )

}
export default ToggleSwitchComponent;

