import { Box, Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';
import OverviewComponent from '../Collections/OverviewComponent';
import FolderAuthorizationTabComponent from "./FolderAuthorizationTabComponent";
import VariablesComponent from '../Common/VariablesComponent';

const FolderScreen = ({state, folder}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Overview",
      component: <OverviewComponent resource={folder || state.selectedFolder} />
    },
    {
      label: "Authorization",
      component: <FolderAuthorizationTabComponent folder={folder || state.selectedFolder}/>
    },
    {
      label: "Variables",
      component: <VariablesComponent resource={folder} resourceType={'Folder'} />
    }
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setActiveTab(0);
  }, [folder]);

  return (
    <Box sx={{ backgroundColor: '#fff', flexGrow: 1, height: 'calc(100vh - 100px)' }}>
      <Box sx={{ marginTop: '10px', marginLeft: '-5px' }}>
        <Tabs
          className='tab-style'
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { display: "none" }}}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab?.label}
              label={tab?.label}
              style={{
                minHeight: '32px',
                height: '32px',
                textTransform: 'none',
                color: activeTab === index ? '#fff' : '#575757',
                backgroundColor: activeTab === index ? '#6241D4' : '#e9e9e9'
                , fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"
              }}
            />
          ))}
        </Tabs>
        {/* FIXME :: Something still wrong. Temporarily fixing with maxHeight */}
        <Box sx={{ height: 'calc(100% - 50px)', maxHeight: '900px', padding: '15px', paddingBottom: 10, overflowY: 'auto' }}>
          {tabs[activeTab].component}
        </Box>
      </Box>
    </Box>
  );
}

export default FolderScreen;
