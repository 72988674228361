import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTestScenarioProcessStatus } from 'redux-store/testScenarioReducers/testScenarioActions';

const useTestScenarioPolling = (testScenarioProcessId, pollingInterval = 5000) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    console.log('Starting polling...', testScenarioProcessId);

    if (!testScenarioProcessId) {
      return; 
    }

    try {
      const response = await dispatch(fetchTestScenarioProcessStatus({ testScenarioProcessId })).unwrap();
      setData(response);
    } catch (err) {
      setError(err.message);
    }
  }, [dispatch, testScenarioProcessId]); 

  const startPolling = useCallback(() => {
    console.log('Starting polling...', testScenarioProcessId);
    setIsPolling(true); 
  }, []);

  const stopPolling = useCallback(() => {
    setIsPolling(false); 
  }, []);

  useEffect(() => {
    console.log('Starting polling...', testScenarioProcessId);

    if (!testScenarioProcessId || !isPolling) {
      return; 
    }

    const intervalId = setInterval(fetchData, pollingInterval);
    fetchData(); 

    return () => {
      clearInterval(intervalId); 
    };
  }, [testScenarioProcessId, isPolling, fetchData, pollingInterval]); 

  return { data, error, startPolling, stopPolling }; 
};

export default useTestScenarioPolling;
