import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import TestPlanBuildList from "./TestPlanBuildList";
import MainLayout from "../../layouts/MainLayout";

import { getAllBuilds } from "../../redux-store/testPlanBuildReducers/testPlanBuildActions";
import { getTestPlan } from "../../redux-store/testPlanReducers/testPlanActions";

const TestPlanBuildListPage = ({ testPlanId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pageSize = 10;
  const page = history.location.search.split("page=")[1];
  const [pageNumber, setPageNumber] = useState(page ? page * 1 : 1);
  const [testPlanBuildList, setTestPlanBuildList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBuilds, setTotalBuilds] = useState(0);
  const isLoading = useSelector((state) => state.testPlanBuild.isLoading);
  const testPlanDetail = useSelector(state => state.testPlan.selectedTestPlanDetail);

  useEffect(() => {
    getTestPlanDetail();
    getTestPlanBuildList(pageNumber, pageSize);
  }, []);

  const getTestPlanDetail = async () => {
    await dispatch(getTestPlan({testPlanId: testPlanId}));
  }

  const getTestPlanBuildList = async (pageNumber, pageSize) => {
    const testPlanBuildResponse = await dispatch(
      getAllBuilds({
        testPlanId: testPlanId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      })
    );
    if (testPlanBuildResponse?.payload) {
      setTestPlanBuildList(
        testPlanBuildResponse.payload?.test_plan_builds || []
      );
      setTotalPages(testPlanBuildResponse.payload?.total_pages);
      setTotalBuilds(testPlanBuildResponse.payload?.total);
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
    getTestPlanBuildList(value, pageSize);
  };

  return (
    <MainLayout
        sx={{ width: 'calc(100% - 100px)' }}
        headerText={testPlanDetail?.title}
        subtitleText={testPlanDetail?.description}
        isLoading={isLoading}
        pageNumber={pageNumber}
        onPageChange={onPageChange}
        totalPages={totalPages}
    >
        <h4 style={{color: "rgba(0, 0, 0, 0.87)", padding: "0px 24px"}}>All builds ({totalBuilds})</h4>
        <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "auto"}}>
          <TestPlanBuildList
            testPlanBuildList={testPlanBuildList}
            testPlanId={testPlanId}
            isLoading={isLoading}
            pageNumber={pageNumber}
          />
        </Box>
    </MainLayout>
  )
};

export default TestPlanBuildListPage;
