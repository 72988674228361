import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import { Menu, MenuItem } from '@mui/material';
import { truncate } from 'lodash';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import {
  createEntity,
  updateFolder,
  destroyFolder,
  duplicateResource,
} from '../../redux-store/currentUserActions';
import {
  pushFoldersIfExist,
  pushFolderEntitiesIfExist,
} from '../../redux-store/currentUserSlice';
import { removeTab } from '../../redux-store/tabSlice';
import { useGuestUser } from '../../contexts/GuestUserContext';
import { Box, IconButton, Typography } from '@mui/material';

// Styles and assets

const FolderComponent = ({
  folder,
  collection,
  refreshCollections,
  setSelectedFolderForAddDoc,
  handleDelete,
  onFolderClick,
  onEntityClick,
}) => {
  const { isGuestUser } = useGuestUser();
  const [dottedFolder, setDottedFolder] = useState();
  const [showEditOn, setShowEditOn] = useState();
  const [nameToUpdate, setNameToUpdate] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenNodeId, setMenuOpenNodeId] = useState(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleRenameClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleRenameClickOutside);
    };
  }, [nameToUpdate]);

  useEffect(() => {
    if (showEditOn) {
      setNameToUpdate(showEditOn.name);
    }
  }, [showEditOn]);

  const onFolderMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuOpenNodeId(menuOpenNodeId === folder.id ? null : folder.id);

    if (menuOpen && dottedFolder?.id === folder.id) {
      setMenuOpen(false);
      setAnchorEl(null);
      setDottedFolder(null);
    } else {
      setAnchorEl(event.currentTarget);
      setDottedFolder(folder);
      setMenuOpen(true);
    }
  };

  const addRequestToFolder = async () => {
    const response = await dispatch(
      createEntity({
        entityableType: 'Folder',
        entityable: dottedFolder,
      })
    );

    if (response?.payload?.entity) {
      const addedEntity = response?.payload?.entity;

      dispatch(
        pushFolderEntitiesIfExist({
          entities: [addedEntity],
          folderId: folder.id,
        })
      );
      onFolderClick({ folder, collection, updateRoute: false });
      onEntityClick({ entity: addedEntity, folder, collection });
    }
  };

  const renameCall = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowEditOn(folder);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      rename();
    } else if (event.key === 'Escape') {
      setShowEditOn();
    }
  };

  const renameFolder = (value) => {
    setNameToUpdate(value);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    await dispatch(destroyFolder({ id: dottedFolder.id }));
    dispatch(removeTab(dottedFolder.id));
    handleDelete(dottedFolder.slug, collection.id);
    handleDeleteDialogClose();
  };

  const duplicate = async () => {
    const response = await dispatch(
      duplicateResource({
        resource_id: dottedFolder?.id,
        resource: 'folder',
      })
    );

    if (response?.payload) {
      const duplicatedFolder = response.payload;

      dispatch(
        pushFoldersIfExist({
          folders: [duplicatedFolder],
          collectionId: collection.id,
        })
      );
      onFolderClick({ folder: duplicatedFolder, collection });
    }

    // FIXME :: Keep this commented for now
    // await dispatch(fetchCollectionFolders(dottedFolder?.collection_id));
  };

  const handleRenameClickOutside = async (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      rename();
    }
  };

  const rename = async () => {
    let body = {
      name: nameToUpdate,
      id: dottedFolder?.id,
      collection: collection,
      // collectionApi: isCollectionApi,
      // folderApi: isFolderApi,
      // folder: apiFolder
    };

    await dispatch(updateFolder(body));
    refreshCollections();
    setShowEditOn();
  };

  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
    setMenuOpenNodeId(null);
  };

  const replaceFolderName = (folderName) => {
    const methodRegex = /^(GET|POST|PATCH|PUT|DELETE)\s/;
    return truncate(folderName?.replace(methodRegex, ''), { length: 22 });
  };

  const getMethodColor = (method) => {
    if (method === 'GET') {
      return 'green';
    } else if (['POST', 'PATCH', 'PUT'].includes(method)) {
      return 'orange';
    } else if (method === 'DELETE') {
      return 'red';
    }

    return 'inherit';
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        '&:hover .more-icon': {
          visibility: 'visible',
        },
        '.more-icon': {
          visibility: menuOpenNodeId === folder.id ? 'visible' : 'hidden',
        },
        padding: "4px 0px"
      }}
    >
      {showEditOn === folder ? (
        <input
          type="text"
          ref={inputRef}
          onChange={(event) => renameFolder(event.target.value)}
          onClick={(event) => event.stopPropagation()}
          onKeyUp={handleKeyDown}
          placeholder={folder.name}
          value={nameToUpdate}
          style={{
            width: '100%',
            border: '1px solid #e9e9e9',
            borderRadius: '5px',
            padding: '5px',
            height: '25px',
            fontSize: '12px',
            fontFamily: "Inter, Sans-serif"
          }}
        />
      ) : (
        <>
          <FolderOutlined
            fontSize="small"
            sx={{ marginRight: '4px', marginLeft: '8px' }}
          />
          <Typography
            variant="body2"
            className="truncate-1-lines"
            sx={{
              flexGrow: 1,
              fontSize: '13px',
              ...(isGuestUser && { marginTop: '3px', marginBottom: '3px' }),
              fontWeight: "550",
              fontFamily: "Inter, Sans-serif",
              wordBreak: "break-all"
            }}
          >
            <span
              style={{
                color: getMethodColor(folder?.request?.request_type),
                fontSize: '10px',
              }}
            >
              {folder?.request?.request_type}
            </span>
            &nbsp;
            {replaceFolderName(folder.name)}
          </Typography>
          {!isGuestUser && (
            <IconButton
              size="small"
              onClick={(event) => onFolderMenuClick(event)}
              className="more-icon"
            >
              <MoreHorizIcon fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-icon-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {[
          { label: 'Add Request', action: addRequestToFolder },
          'divider',
          { label: 'Duplicate', action: duplicate },
          { label: 'Rename', action: renameCall, condition: folder.can_update },
          {
            label: 'Delete',
            action: handleDeleteDialogOpen,
            color: 'red',
            condition: folder.can_delete,
          },
        ]
          .filter((item) => item.condition !== false)
          .map((item, index) => (
            <MenuItem
              key={index}
              onClick={(event) => {
                if (item.label === 'Delete') event.stopPropagation();
                if (item.label === 'Add doc and re-run test cases')
                  setSelectedFolderForAddDoc(folder);
                item.action(event);
                setMenuOpen(false);
              }}
              style={{
                fontSize: '12px',
                fontWeight: "550",
                fontFamily: "Inter, Sans-serif",
                color: item.color || 'inherit',
              }}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>

      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
        title="Confirm Delete"
        content="Are you sure you want to delete?"
      />
    </Box>
  );
};

export default FolderComponent;
