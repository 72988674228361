import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
const getChatList = createAsyncThunk(
  "getChatList",
  async ({projectId, pageNumber, pageSize}, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "GET",
        url: '/api/v1/chats',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          project_id: projectId,
          page: pageNumber,
          page_size: pageSize
        },
      });
      if (response.status === 200 && response?.data) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getQuestionsList = createAsyncThunk(
  "getQuestionsList",
  async ({chatId, pageNumber, pageSize}, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "GET",
        url: '/api/v1/chat_messages',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          chat_id: chatId,
          page: pageNumber,
          page_size: pageSize
        },
      });
      if (response.status === 200 && response?.data) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getPublishedQuestionsDetail = createAsyncThunk(
  "getPublishedQuestionsDetail",
  async ({faqSlug}, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "GET",
        url: `/api/v1/question_answers/${faqSlug}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200 && response?.data) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getPublishedQuestionList = createAsyncThunk(
  "getPublishedQuestionList",
  async ({projectId, pageNumber, pageSize}, { rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: "GET",
        url: '/api/v1/question_answers',
        params: {
          project_id: projectId,
          page: pageNumber,
          page_size: pageSize
        },
      });
      if (response.status === 200 && response?.data) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const publishQuestionAnswer = createAsyncThunk(
  "publishQuestionAnswer",
  async ({projectId, questionText, answerText}, {dispatch, rejectWithValue}) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "POST",
        url: '/api/v1/question_answers',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          project_id: projectId,
          question_content: questionText,
          answer_content: answerText
        }
      });
      if (response.status === 200) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
)

const updatePublishedQuestionAnswer = createAsyncThunk(
  "updatePublishedQuestionAnswer",
  async ({faqId, questionText, answerText}, {rejectWithValue}) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "PUT",
        url: `/api/v1/question_answers/${faqId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          question_content: questionText,
          answer_content: answerText
        }
      });
      if (response.status === 200) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
)

const deletePublishedQuestionAnswer = createAsyncThunk(
  "deletePublishedQuestionAnswer",
  async ({faqId}, {rejectWithValue}) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "DELETE",
        url: `/api/v1/question_answers/${faqId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      if (response.status === 200) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
)

export {
  getChatList,
  getQuestionsList,
  publishQuestionAnswer,
  getPublishedQuestionsDetail,
  getPublishedQuestionList,
  updatePublishedQuestionAnswer,
  deletePublishedQuestionAnswer,
};
