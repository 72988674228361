import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Material UI icon

// Custom code block component
const CodeBlock = ({ language, value }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true); // Change the text to "Copied"
    setTimeout(() => setIsCopied(false), 2000); // Revert after 2 seconds
  };

  const renderCopyCode = () => {
    let content = null;
    if(isCopied){
      return (
        <Box>
          Copied
        </Box>
      )
    }else{
      return (
        <Box>
          <ContentCopyIcon style={{fontSize: '16px', marginRight: '8px'}} />
          <span>
            Copy
          </span>
        </Box>
      )
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Box style={{background: '#111', padding: '8px 16px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
        <div style={{float: 'left', color: '#fff'}}>
          {language}
        </div>
        <Box
          onClick={copyToClipboard}
          style={{
            padding: '5px 10px',
            cursor: 'pointer',
            float: 'right',
            color: '#fff',
            fontSize: '14px'
          }}
        >
          {renderCopyCode()}
        </Box>
        <div style={{clear: 'both'}}></div>
      </Box>
      <SyntaxHighlighter style={materialDark}
        customStyle={{
          margin: '0px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          padding: '8px 16px'
        }}
        language={language}
        children={value}
      />
    </div>
  );
};

// Main component
const MixedContent = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MixedContent;