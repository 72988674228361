import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux-store/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import reportWebVitals from './reportWebVitals';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import App from './App';

dayjs.extend(relativeTime);

let appContent;
if (process.env.NODE_ENV === "development" || process.env.REACT_APP_ENVIRONMENT === "development") {
  appContent = (<App />)
} else {
  Bugsnag.start({
    apiKey: "ae2134cf2a6d42bf0226a21536b445a6",
    plugins: [new BugsnagPluginReact()]
  })
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  appContent = (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {appContent}
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
