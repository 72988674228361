import React, { useState, useRef, useEffect } from "react";
import MonacoEditor from "react-monaco-editor";
import { Box, Snackbar, Alert } from "@mui/material";
import { monaco } from "react-monaco-editor";

// Define a custom Monaco theme
monaco.editor.defineTheme("custom-monaco-theme", {
  base: "vs",
  inherit: true,
  rules: [
    { token: "key", foreground: "A31515" }, // Red for keys
    { token: "value", foreground: "0451A5" }, // Blue for values
    { token: "number", foreground: "098658" }, // Green for numbers
    { token: "boolean", foreground: "FF8C00" }, // Orange for booleans
  ],
  colors: {
    "editor.foreground": "#000000",
    "editor.background": "#FFFFFF",
    "editor.lineHighlightBackground": "#ffffff00",
    "editor.lineHighlightBorder": "#ffffff00",
  },
});

// Define a new language for JSON
monaco.languages.register({ id: "jsonWithCustomColors" });

monaco.languages.setMonarchTokensProvider("jsonWithCustomColors", {
  tokenizer: {
    root: [
      // Tokenize keys and values in JSON
      [/\"([a-zA-Z0-9_]+)\"\s*:/, { token: "key" }],
      [/\"([^\"\\]*(?:\\.[^\"\\]*)*)\"/, { token: "value" }],
      [/(\d+)/, { token: "number" }],
      [/\b(true|false|null)\b/, { token: "boolean" }],
      [/[{}[\]]/, { token: "delimiter" }],
      [/,/, { token: "delimiter" }],
    ],
  },
});

// Create the BodyRaw component
const BodyRaw = ({ body, onBodyChange }) => {
  const [bodyRaw, setBodyRaw] = useState(JSON.stringify(body, undefined, 4));
  const [error, setError] = useState(null);
  const editorRef = useRef(null);

  const handleChange = (newBodyRaw) => {
    setBodyRaw(newBodyRaw);
    setError(null);
  };

  const handleOutsideClick = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      try {
        const jsonObject = JSON.parse(bodyRaw);
        onBodyChange(jsonObject);
      } catch (err) {
        setError("Invalid JSON format. Please correct it before saving.");
      }
    }
  };

  useEffect(() => {
    setBodyRaw(JSON.stringify(body, undefined, 4));
  }, [body]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [bodyRaw]);

  return (
    <Box
      ref={editorRef}
      sx={{
        height: "200px",
        overflow: "auto", 
        border: "1px solid #e9e9e9",
        margin: "10px 0",
        borderRadius: "5px",
      }}
    >
     <MonacoEditor
  language="jsonWithCustomColors" // Use custom language
  theme="custom-monaco-theme"
  value={bodyRaw}
  onChange={handleChange}
  options={{
    automaticLayout: true,
    wordWrap: "on",
    minimap: { enabled: false },
    lineNumbers: "on",
    tabSize: 8,
    fontSize: 11, 
    fontFamily: "Inter, Sans-serif",
    scrollBeyondLastLine: false,
  }}
/>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BodyRaw;
