import { Divider, TextField, Box, Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResourceSummary } from "../../redux-store/currentUserActions";
import { setResourceSummary } from '../../redux-store/currentUserSlice';
import AuthTypeText from "./AuthTypeText";

const BasicAuthorization = ({ selectedType }) => {
  const selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (selectedSummary?.authorization_value) {
      setPassword(selectedSummary?.authorization_value?.password);
      setUserName(selectedSummary?.authorization_value?.userName);
    }
  }, [selectedSummary]);

  const handleInputChange = (field, value) => {
    if (field === 'password') setPassword(value);
    else setUserName(value);
  };

  const handleApiCall = async () => {
    const state = {
      id: selectedSummary?.id,
      summary: {
        authorization_type: selectedType,
        authorization_value: {
          userName: userName,
          password: password
        }
      }
    };
    const res = await dispatch(updateResourceSummary(state));
    if (res.payload.summary) {
      setPassword(res.payload.summary.authorization_value.password);
      setUserName(res.payload.summary.authorization_value.userName);
    }
  };

  return (
    <Box p={2}>
      <AuthTypeText />
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body">Username:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={userName}
            onChange={(e) => handleInputChange('username', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Password:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            type={isChecked ? "text" : "password"}
            value={password}
            onChange={(e) => handleInputChange('password', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                color="primary"
              />
            }
            label="Show Password"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicAuthorization;
