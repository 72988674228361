import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import TabsComponent from "../TestScenarios/TabsComponent.jsx";
import MainLayout from '../../layouts/MainLayout';
import ProfileSettings from "../Settings/ProfileSettings.jsx";
import OrganisationSettings from "../Settings/OrganisationSettings.jsx";
import ProjectSettings from "../Settings/ProjectSettings.jsx";

const SettingsPage = function({project, organisation, onOrganisationUpdate, onProjectUpdate}) {
  const [activeTab, setActiveTab] = useState(0);
  const tabLabels = ["Profile", "Organisation", "Project"];

  useEffect(() => {
    setActiveTab(0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (<OrganisationSettings projectId={project?.Id} organisation={organisation} onUpdate={onOrganisationUpdate} />);
      case 2:
        return <ProjectSettings project={project} onUpdate={onProjectUpdate} />;
      default:
        return (<ProfileSettings projectId={project?.id} />);
    }
  }

  return (
    <MainLayout
      // isLoading={isLoading}
      headerText="Settings"
    >
      <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "scroll"}}>
        <TabsComponent sx={{px: 4}} activeTab={activeTab} customTabStyle={{width: "auto"}} handleTabChange={handleTabChange} tabLabels={tabLabels} tabsStyle={{width: "fit-content", minHeight: "36px"}} />
        <Box sx={{padding: "32px 32px", width: "800px"}}>
          {renderTabContent()}
        </Box>
      </Box>
    </MainLayout>
  )
}

function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    project: state.common.selectedProject,
    organisation: state.common.selectedOrganisation
  }
}

export default connect(mapStateToProps)(SettingsPage);
