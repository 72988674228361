import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Add, CreateOutlined } from "@mui/icons-material";
import { Chip, Stack, MenuItem, Menu } from "@mui/material";

import { getAllTestSuites } from "../../redux-store/testSuiteReducers/testSuiteActions";
import AttachTestSuiteModal from "./AttachTestSuiteModal";
import CreateTestSuiteModal from "./CreateTestSuiteModal";

const TestSuiteAttach = ({ projectId }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    fetchAllTestSuites();
  }, []);

  const fetchAllTestSuites = async () => {
    await dispatch(
      getAllTestSuites({
        projectId: projectId,
        pageSize: 100000,
        pageNumber: 1,
      })
    );
  };

  const handleClick = (event) => {
    setAnchorEl(
      event.currentTarget.tagName === "svg"
        ? event.currentTarget.parentNode
        : event.currentTarget
    );
  };

  const onCreate = () => {
    setAnchorEl("");
    setShowCreateModal(true);
  };

  const onAdd = () => {
    setAnchorEl("");
    setShowAddModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowAddModal(false);
    setShowCreateModal(false);
  };

  return (
    <>
      <Chip
        id="attach-test-suite"
        label="Create/Add to Test Suite"
        icon={<Add />}
        onClick={handleClick}
        size="small"
        sx={{border: "1px solid #6241d4", padding: "14px 0", borderRadius: "4px", fontWeight: 500}}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ mt: 0.5 }}
        open={Boolean(anchorEl)}
        className="attach-test-suite-menu"
      >
        <Stack spacing={1}>
          <MenuItem onClick={onCreate}>
            <CreateOutlined />
            Create new test suite
          </MenuItem>
          <MenuItem onClick={onAdd}>
            <Add />
            Add to existing test suite
          </MenuItem>
        </Stack>
      </Menu>
      {showAddModal && (
        <AttachTestSuiteModal
          projectId={projectId}
          open={showAddModal}
          onClose={handleClose}
          onCancel={handleClose}
        />
      )}
      {showCreateModal && (
        <CreateTestSuiteModal
          projectId={projectId}
          open={showCreateModal}
          onClose={handleClose}
          onCancel={handleClose}
        />
      )}
    </>
  );
};

export default TestSuiteAttach;
