import React, { useReducer , useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { updateLoadError } from "../../redux-store/currentUserSlice";
import { useSelector, useDispatch } from "react-redux";
import { Login, GoogleSignup } from "../../redux-store/currentUserActions";
import { GoogleLogin } from "@react-oauth/google";
import "../../styles/App.scss"
import "./index.css";
import { Typography, Box, Button, Container, Grid, Card, InputLabel, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const LoginScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { openSnackbar } = useSnackbar();
  const [state, setState] = useReducer(reducer, {
    email: "",
    password: "",
    isLoading: false,
    errors: {},
  });
  const showEmailSentMessage = localStorage.getItem('showEmailSentMessage') === 'true';

  useEffect(() => {
    if (showEmailSentMessage) {
      const timer = setTimeout(() => {
        localStorage.removeItem('showEmailSentMessage');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showEmailSentMessage]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectPath = urlParams.get('redirect');

    if (redirectPath) {
      setState({
        type: "SET_STATE",
        payload: { redirectPath: decodeURIComponent(redirectPath) },
      });
    }
  }, [location]);

  const responseMessage = async (response) => {
    const res = await dispatch(GoogleSignup({ credential: response.credential }));

    if (res.payload.message === 'Signed up successfully' || res.payload.message === 'Logged in successfully') {
      if (res.payload.has_organisation_or_project) history.push("/collections");
      else history.push("/home");
    }
  };

  const errorMessage = (error) => {
    dispatch(updateLoadError(error))
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!state.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)) {
      errors["email"] = "Please enter a valid email.";
    }
    if (!state.password) {
      errors["password"] = "Please enter Password.";
    }
    return errors;
  };

  const handleSubmit = async () => {
    const res = await dispatch(Login(state));

    if (res.payload && res.payload.message === "Login successful") {
      localStorage.setItem("showEmailSentMessage", 'false');

      if (state.redirectPath) {
        const pathToRedirect = state.redirectPath;

        setState({
          type: "SET_STATE",
          payload: { redirectPath: '' },
        });

        history.push(pathToRedirect);
      } else {
        if (res.payload.has_organisation_or_project) history.push("/collections");
        else history.push("/home");
      }
    } else {
      const errorMessage = res.payload ? res.payload : "Login failed.";
      openSnackbar({ message: errorMessage, severity: 'error' });
    }
  };

  const onFormSubmit = async () => {
    const errors = validateForm();
    setState({
      type: "SET_STATE",
      payload: { errors },
    });

    if (!Object.keys(errors).length) {
      await handleSubmit();
    }
  };

  let full_error = "";
  const errors = useSelector((state) => state.user.errors);

  if (errors) {
    Object.keys(errors).map((key) => (full_error = full_error + errors[key]));
  }

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          {showEmailSentMessage && (
            <Box className="info-message">
              A confirmation email has been sent to your email address. Please confirm it to continue.
            </Box>
          )}
          <Card className="login-grid-card">
            <div className="right-column">
              <Box className="heading-container">
                <Box className="create-account-title">
                  Sign In to Qodex
                </Box>
                <Box className="signin-link-container">
                  or
                  <a href="/" className="signin-link">
                    Create an Account
                  </a>
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="email" className="input-label">Email</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="email"
                  value={state.email}
                  onChange={handleInputChange}
                  error={!!state.errors.email}
                  helperText={state.errors.email || ' '}
                />

                <InputLabel htmlFor="password" className="input-label">Password</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  type="password"
                  name="password"
                  value={state.password}
                  onChange={handleInputChange}
                  error={!!state.errors.password}
                  helperText={state.errors.password || ' '}
                />
              </Box>
              <Box className="mb-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="staySignedIn"
                      color="primary"
                      checked={state.staySignedIn}
                      // onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography className='text-color'>
                      Stay signed in for 30 days.
                    </Typography>
                  }
                />
              </Box>
              <div className="btn_login">
                <Button variant="contained" onClick={onFormSubmit} size="medium" style={{ padding: '10px 0', width: '100%' }}>
                  Sign In
                </Button>
              </div>
              <Box className="mb-4 mt-4 center">
                By signing in, I agree to the{" "}
                <a href="https://www.qodex.ai/termsandconditions" className="terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
              </Box>
              <Box className="line-container primary-color mb-4">
                <span className="cutting-line"></span>
                <Typography className="overlay-text">or</Typography>
                <span className="cutting-line"></span>
              </Box>
              <div style={{ width: "100%" }}>
                <GoogleLogin
                  buttonText="Sign in with Google"
                  onSuccess={responseMessage}
                  onFailure={errorMessage}
                  cookiePolicy={'single_host_origin'}
                />
              </div>
              <div style={{ marginTop: "8px" }}></div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};


export default LoginScreen;
