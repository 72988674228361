import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';

const CustomSnackbar = () => {
  const { snackbar, closeSnackbar } = useSnackbar();

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: snackbar.verticalPos, horizontal: snackbar.horizontalPos }}
    >
      <Alert onClose={closeSnackbar} severity={snackbar.severity} variant="filled">
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
