import React, {useEffect, useReducer} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Button,
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from "@mui/material";

import { updateUserProfile } from '../../redux-store/currentUserActions.js';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const initialState = {
  id: '',
  name: '',
  username: '',
  email: '',
  notificationSetting: 'all',
  isLoading: false,
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const ProfileSettings = () => {
  const user = useSelector((state) => state.user.current_user);
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const {openSnackbar} = useSnackbar();

  useEffect(() => {
    if (user?.id) {
      dispatchLocal({
        type: "SET_STATE",
        payload: {
          id: user.id,
          name: user.name,
          username: user.username,
          email: user.email,
          notificationSetting: user.notificationSetting || 'all',
        },
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatchLocal({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let errors = {};
    let hasError = false;

    if (!state.name) {
      errors.name = "Name is required";
      hasError = true;
    }

    if (hasError) {
      dispatchLocal({
        type: "SET_STATE",
        payload: { errors },
      });
      return;
    }

    const jsonData = {
      id: state.id,
      name: state.name,
      // username: state.username,
      // email: state.email,
      notificationSetting: state.notificationSetting,
    };

    dispatchLocal({ type: "SET_STATE", payload: { isLoading: true } });

    const res = await dispatch(updateUserProfile(jsonData));
    if (res?.payload && res?.payload?.user) {
      openSnackbar({ message: "Profile updated successfully!", severity: 'success' });
      localStorage.setItem('user', JSON.stringify(res.payload.user));
    } else {
      openSnackbar({ message: "Profile updation failed!", severity: 'error' });
    }

    dispatchLocal({ type: "SET_STATE", payload: { isLoading: false } });
  };

  const avatarStyle = {
    width: '75px',
    height: '75px',
    fontSize: '30px',
    bgcolor: '#1e88e5',
    margin: '5px auto'
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Avatar style={avatarStyle}>{state.name ? state.name[0].toUpperCase() : ''}</Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography>Name</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              name="name"
              value={state.name}
              onChange={handleInputChange}
              error={!!state.errors.name}
              helperText={state.errors.name || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Username</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              name="username"
              value={state.username}
              onChange={handleInputChange}
              disabled
              sx={{ bgcolor: '#f5f5f5', borderRadius: '4px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Email Address</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              name="email"
              value={state.email}
              onChange={handleInputChange}
              disabled
              sx={{ bgcolor: '#f5f5f5', borderRadius: '4px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>Notification Settings</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="notification-setting"
                name="notificationSetting"
                value={state.notificationSetting}
                onChange={handleInputChange}
                row
              >
                <FormControlLabel value="all" control={<Radio />} label="All notifications" />
                <FormControlLabel value="failed" control={<Radio />} label="Only failed collections" />
                <FormControlLabel value="none" control={<Radio />} label="No notifications" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={state.isLoading}
            >
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default ProfileSettings
