import React, {useEffect, useState, memo, useRef} from "react";
import { connect } from "react-redux";
import { PopupButton } from 'react-calendly';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Drawer,
  Grid,
  Avatar,
  Link
} from "@mui/material";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  DataArray
} from "@mui/icons-material";

import customUtils from "../../utils/customUtils";

import qodexLogoFull from '../../assets/logos/qodexLogoFull.png';
import avatar from '../../assets/icons/person/avatar.png';
import "../../styles/components/Dashboard/NavigationDrawer.scss";

const drawerWidth = 240;

const NavigationDrawer = memo(function NavigationDrawer({expandedNodeIdsInitial=[], selectedNodeIdInitial="", drawerItems=[], selectedMenuItem, isItemDisabled, getProjectButton, onProfileClick, profileDropdownRef, projectSideBarRef, selectedProject, user}) {
  const [expandedNodeIds, setExpandedNodeIds] = useState(expandedNodeIdsInitial);
  const [selectedNodeId, setSelectedNodeId] = useState(selectedNodeIdInitial);
  const selectedNodeIdRef = useRef("");

  useEffect(() => {
    if (selectedNodeIdRef.current !== selectedMenuItem) {
      let selectedChildItem;
      for (let i = 0; i < drawerItems.length; i++) {
        for (let j = 0; j < drawerItems[i].length; j++) {
          const menuItem = drawerItems[i][j];
          selectedChildItem = menuItem?.items?.filter((childItem) => childItem?.id === selectedMenuItem)?.[0];
          if (selectedChildItem) {
            selectedNodeIdRef.current = menuItem?.id;
            setSelectedNodeId(selectedChildItem?.id);
            setExpandedNodeIds([menuItem?.id]);
            break;
          }
        }
        if (selectedChildItem)
          break;
      }
    }
  }, [selectedMenuItem]);

  const renderTopNav = () => {
    return (
      <div>
        <List className="topNav" ref={projectSideBarRef}>
          <ListItem key="logoNav-1" className="listItem logo">
              <img src={qodexLogoFull} height="50px" />
          </ListItem>
          <ListItem key="logoNav-2" className="listItem">
            {getProjectButton}
          </ListItem>
        </List>
      </div>
    )
  }

  const renderBottomNav = () => {
    const totalCredits = selectedProject?.total_credits;
    const availableCredits = selectedProject?.available_credits;
    let userName = user?.name || user?.username || "Qodex User";
    return (
      <div className="bottomNavContainer">
        <Divider />
        <List key="profileNav-book-demo" className="list">
          <ListItem className="listItem">
            <PopupButton
              url={customUtils.calendlyDemoUrl}
              rootElement={document.getElementById('root')}
              text="Book a Demo"
              className="book-demo-button listItemButton"
            />
          </ListItem>
        </List>
        <Divider />
        <List className="bottomNav">
          <ListItem key="creditInfo" className="listItem creditListItem">
            <div className="creditInfo">
              {availableCredits}/{totalCredits} AI credits remaining •
              <Link href="https://qodex.ai" target="_blank">Upgrade</Link>
            </div>
          </ListItem>
          <ListItem key="profileNav" className="listItem">
            <ListItemButton onClick={onProfileClick} className="listItemButton">
              <ListItemIcon className="listItemIcon" ref={profileDropdownRef}>
                <Grid container flexDirection="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    <Avatar
                      alt="Qodex User"
                      // src={avatar}
                      sx={{ width: 44, height: 44 }}
                    >
                      {userName.split(' ')?.[0]?.[0]?.toUpperCase()}{userName.split(' ')?.[1]?.[0]?.toUpperCase()}
                    </Avatar>
                  </Grid>
                  <Grid item className="welcome">
                    <div className="greeting">Welcome back 👋</div>
                    <div className="name truncate-1-lines">{userName}</div>
                  </Grid>
                  <Grid item className="action">
                    <ListItemIcon className="listItemIcon">
                      <KeyboardArrowRight />
                    </ListItemIcon>
                  </Grid>
                </Grid>
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    )
  }

  const handleDropdownClick = () => {

  }

  const handleListItemClick = (item) => {
    selectedNodeIdRef.current = item?.id;
    setSelectedNodeId(item?.id);
    if (item.onClick) {
      item.onClick();
    }
  }

  const handleTreeViewNodeToggle = (event, nodeIds) => {
    setExpandedNodeIds([...nodeIds]);
  }

  const renderNavDropdown = (menuItem) => {
    return (
      <TreeView
        aria-label={`${menuItem?.label} tree`}
        defaultExpandIcon={menuItem.icon}
        defaultCollapseIcon={menuItem.icon}
        defaultExpanded={expandedNodeIds}
        sx={{ flexGrow: 1, maxWidth: 400, bgcolor: 'background.paper' }}
        expanded={expandedNodeIds}
        selected={selectedNodeId}
        onNodeToggle={handleTreeViewNodeToggle}
        className="dropdownItem"
        key={menuItem.id}
      >
        <TreeItem
          key={menuItem.id}
          nodeId={menuItem.id}
          onClick={() => handleDropdownClick({ menuItem })}
          className="treeItemButton"
          sx={{padding: "0px", fontFamily: "Inter, Sans-serif"}}
          label={(<div>
            {menuItem.label}
            {expandedNodeIds.indexOf(menuItem.id) !== -1 ? <KeyboardArrowDown sx={{position: "absolute", right: "0px"}} /> : <KeyboardArrowRight sx={{position: "absolute", right: "0px"}} />}
          </div>)}
        >
          {menuItem?.items?.map((item, index) => {
            const selected = item.id === selectedMenuItem;
            return (
              <TreeItem
                key={item.id}
                nodeId={item.id}
                onClick={(event) => {event.preventDefault(); event.stopPropagation(); handleListItemClick(item);}}
                className="treeItemButtonLeaf"
                label={
                  <ListItem key={item.id} className="listItem" sx={{padding: "0px"}}>
                    <a href={`${window?.location?.origin}${item.href}`}>
                      <ListItemButton disabled={isItemDisabled(item.id)} className={`listItemButton${selected ? " selected" : ""}`}>
                        <ListItemIcon className="listItemIcon">
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    </a>
                  </ListItem>
                }
              />
            )
          })}
        </TreeItem>
        {/* {renderTree(collections)} */}
      </TreeView>
    )
  }

  const renderDrawer = () => {
    return drawerItems.map((menuItemsList, index) => {
      return (
        <div key={index}>
          <List className="list">
            {
              menuItemsList.map((menuItem, index) => {
                if (menuItem.type === 'dropdown') {
                  return renderNavDropdown(menuItem);
                } else {
                  const selected = menuItem.id === selectedMenuItem;
                  return (
                    <ListItem key={menuItem.id} className="listItem">
                      <a href={`${window?.location?.origin}${menuItem.href}`}>
                        <ListItemButton onClick={(event) => {event.preventDefault(); menuItem.onClick();}} disabled={isItemDisabled(menuItem.id)} className={`listItemButton${selected ? " selected" : ""}`}>
                          <ListItemIcon className="listItemIcon">
                            {menuItem.icon}
                          </ListItemIcon>
                          <ListItemText primary={menuItem.label} />
                        </ListItemButton>
                      </a>
                    </ListItem>
                  )
                }
              })
            }
          </List>
          {index < (drawerItems.length - 1) && <Divider key={`divider-${index}`} />}
        </div>
      )
    })
  };

  return (
    <Box sx={{ display: "flex" }} className="navigationDrawer">
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="navigation menu items"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {renderTopNav()}
          <div style={{maxHeight: "calc(100vh - 370px)", overflow: "auto"}}>
            {renderDrawer()}
          </div>
          {renderBottomNav()}
        </Drawer>
      </Box>
    </Box>
  );
});

function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    selectedProject: state.common.selectedProject,
    user: state.user.current_user
  }
}

export default connect(mapStateToProps)(NavigationDrawer);
