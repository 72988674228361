import React, { useState, useEffect } from 'react';
import { Box, TextField, Select, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import QueryParams from './QueryParamsView.jsx';
import AuthorizationComponentView from './AuthorizationComponentView.jsx';
import HeadersComponentView from './HeadersComponentView.jsx';
import BodyComponentView from './BodyComponentView.jsx';
import LoadingOverlay from '../../Common/LoadingOverlay.js';

const Request = ({ state, api, entity, response }) => {
  const [method, setMethod] = useState('GET');
  const [url, setUrl] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (response) {
      setUrl(response.request_url || '');
      setMethod(response.request_type || 'GET');
    }
  }, [response]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getTabStyle = (index) => ({
    textTransform: 'none',
    color: activeTab === index ? '#fff' : '#575757',
    backgroundColor: activeTab === index ? '#D27FFF' : '#fff',
  });

  const renderTabContent = () => {
    switch (activeTab) {
      case 0: return <QueryParams state={state} api={api} initialParams={response?.request_params || []} entity={entity} />;
      case 1: return <AuthorizationComponentView state={state} api={api} />;
      case 2: return <HeadersComponentView state={state} api={api} headers={response?.request_headers} />;
      case 3: return <BodyComponentView state={state} entity={entity} />;
      default: return null;
    }
  };

  const tabLabels = ["Params", "Authorization", "Headers", "Body"];

  return (
    <Box sx={{ flexGrow: 1, marginLeft: '16px', marginRight: '16px' }}>
      <Typography variant="body1"><strong>Request</strong></Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Select
          size='small'
          value={method}
          sx={{ marginRight: '10px', width: '15%' }}
          disabled
        >
          <MenuItem value="GET" sx={{ color: 'green', fontWeight: 'bold' }}>GET</MenuItem>
          <MenuItem value="POST" sx={{ color: '#ffb900', fontWeight: 'bold' }}>POST</MenuItem>
          <MenuItem value="PUT" sx={{ color: 'blue', fontWeight: 'bold' }}>PUT</MenuItem>
          <MenuItem value="PATCH" sx={{ color: 'purple', fontWeight: 'bold' }}>PATCH</MenuItem>
          <MenuItem value="DELETE" sx={{ color: 'red', fontWeight: 'bold' }}>DELETE</MenuItem>
          <MenuItem value="HEAD" sx={{ color: 'green', fontWeight: 'bold' }}>HEAD</MenuItem>
          <MenuItem value="OPTIONS" sx={{ color: 'pink', fontWeight: 'bold' }}>OPTIONS</MenuItem>
        </Select>
        <TextField
          value={url}
          variant="outlined"
          size='small'
          fullWidth
          disabled
        />
      </Box>
      
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        sx={{ marginTop: '20px', height: '37px', minHeight: '37px', border: '1px solid #e9e9e9', borderRadius: '5px' }}
      >
        {tabLabels.map((label, index) => (
          <Tab 
            key={index}
            label={label}
            sx={getTabStyle(index)}
            style={{
              height: '37px',
              minHeight: '37px',
              textTransform: 'none',
              color: activeTab === index ? '#575757' : '#575757',
              backgroundColor: 'transparent'
            }}
          />
        ))}
      </Tabs>
      <Box sx={{ marginTop: '10px', marginBottom: '20px', minHeight: '175px', maxHeight: '200px', overflow: 'auto' }}>
        {renderTabContent()}
      </Box>
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

export default Request;
