import React from 'react';

import { Typography } from "@mui/material";

import { STATUS_TO_ICON } from '../../config/constants';

import "../../styles/components/Common/Status.scss";

const Status = ({status}) => {
  return (
    <Typography variant="body1" className="status">
      <span>{STATUS_TO_ICON[status] || ''}</span>
      <span>{status || ''}</span>
    </Typography>
  )
}

export default Status;
