import { createSlice } from "@reduxjs/toolkit";

const functionSlice = createSlice({
  name: "functionSlice",
  initialState: {
    isLoading: false,
    isSaving: false,
    functionsList: [], //[{function_name: "sum", function_args: "[a,b]", js_code: "return a*b;", count: 0, id: "1"}, {function_name: "multiply", js_code: "return a+b+c+d;", function_args: "a,b,c,d", count: 0, id: "2"}],
    total: 0,
    totalPages: 0
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    setFunctionsList: (state, action) => {
      return {
        ...state,
        functionsList: [...action.payload.functionsList]
      }
    },
    setTotalPages: (state, action) => {
      return {
        ...state,
        totalPages: action.payload.totalPages
      }
    },
    setTotal: (state, action) => {
      return {
        ...state,
        total: action.payload.total
      }
    }
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setFunctionsList,
  setTotalPages,
  setTotal
} = functionSlice.actions;

export default functionSlice.reducer;
