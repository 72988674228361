import React from "react";
import { Divider, Typography, Box } from '@mui/material';
import { ArrowOutward } from "@mui/icons-material";
import ApiKeyAuthorization from "../../Collections/ApiKeyAuthorization";
import BearerTokenAuthorization from "../../Collections/BearerTokenAuthorization";
import BasicAuthorization from "../../Collections/BasicAuthorization";
import AWSAuthorization from "../../Collections/AWSAuthorization";

const AuthorizationComponentView = ({ selectedType, collection }) => {
  return (
    <Box style={{ marginRight: '5%' }}>
      <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#6b6b6b' }}>
        This authorization method will be used for every request in this collection. You can override this by specifying one in the request.
      </Typography>
      <Box style={{ marginTop: '50px' }}>
        <Typography style={{ marginRight: '10px', display: 'inline' }}>Type:</Typography>
        <Typography style={{ fontWeight: 'bold', display: 'inline' }}>{selectedType}</Typography>
      </Box>
      {selectedType === 'No Auth' && (
        <Box style={{ marginTop: '10px', textAlign: 'center' }}>
          <Divider sx={{ borderBottom: 'outset', marginTop: '2%' }} />
          <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#6b6b6b' }}>
            This collection does not use any authorization. Learn more about
            <a> <u> authorization</u></a>
            <ArrowOutward style={{ fontSize: '17px' }}></ArrowOutward>
          </Typography>
        </Box>
      )}
      {selectedType === 'Api Key' && <ApiKeyAuthorization collection={collection} />}
      {selectedType === 'Bearer Token' && <BearerTokenAuthorization collection={collection} />}
      {selectedType === 'Basic Auth' && <BasicAuthorization collection={collection} />}
      {selectedType === 'Aws Signature' && <AWSAuthorization collection={collection} />}
    </Box>
  );
};

export default AuthorizationComponentView;
