import React from "react";
import {Box, Link} from "@mui/material";

const ProductDocs = () => {
  return (
    <Box>
      {/* <div className="title"><div className="menuIcon productDocs" /><h3>Product Docs</h3></div> */}
      <p style={{marginTop: "0px"}}>
        QodexAI analyses product documentation to enhance the quality of test scenarios. 
        You can add your product docs in PDF format by uploading it in a collection’s settings.
        <Link className="link" href="https://qodex.ai" target="_blank">Read more</Link>
      </p>
      <p>
        You can also share Product documentation URLs (public) directly in your collection settings. This feature is still in beta and mail us if you don’t see it live on your project.
      </p>
    </Box>
  )
}

export default ProductDocs;
