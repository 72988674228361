import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Box, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomCheckbox from "../Common/CustomCheckbox.jsx";

const getNewRow = () => {
  const newRow = { name: '', value: '', description: '', required: false, datatype: '' };
  return {...newRow};
}

const FormDataComponent = ({ body, onBodyChange, hideExtraFields }) => {
  const [rows, setRows] = useState(body || [{...getNewRow()}]);
  const tableRef = useRef(null);

  const handleInputChange = (index, field, value) => {
    let updatedRows = [...rows];
    if (field === "required") {
      updatedRows[index] = { ...updatedRows[index], [field]: value ? true : false };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }

    updatedRows = updatedRows.filter((row) => row.name || row.value);
    updatedRows.push({...getNewRow()});
    setRows(updatedRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    onBodyChange(updatedRows);
  };

  const handleOutsideClick = async (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      const filteredRows = rows.filter((row) => row.name);
      onBodyChange(filteredRows);
    }
  };

  useEffect(() => {
    setRows([...(body || []), {...getNewRow()}]);
  }, [body]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [rows]);

  const rowTableCellSx = {
    padding: '5px 10px', margin: 0, fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "11px"
  };
  const inputTableCellSx = {
    fontSize: '11px',
    fontFamily: "Inter, Sans-serif",
    fontWeight: "400"
  };

  return (
    <Box>
      <TableContainer sx={{ borderRadius: '5px', maxHeight: '190px', overflowY: 'auto' }} ref={tableRef}>
        <Table size="small" aria-label="form-data" sx={{ border: 'none', padding: '0', backgroundColor: '#ffffff' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={rowTableCellSx}>Key</TableCell>
              <TableCell sx={rowTableCellSx}>Value</TableCell>
              {
                !hideExtraFields && (
                  <>
                    <TableCell sx={rowTableCellSx}>Description</TableCell>
                    <TableCell sx={rowTableCellSx}>Required</TableCell>
                    <TableCell sx={rowTableCellSx}>Data Type</TableCell>
                  </>
                )
              }
              <TableCell sx={rowTableCellSx}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableRef}>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ border: 'none' }}>
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`key_${index}`}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size='small'
                    placeholder='Key'
                    value={row.name}
                    onChange={e => handleInputChange(index, 'name', e.target.value)}
                    InputProps={{
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`value_${index}`}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size='small'
                    placeholder='Value'
                    value={row.value}
                    onChange={e => handleInputChange(index, 'value', e.target.value)}
                    InputProps={{
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                {
                  !hideExtraFields && (
                    <>
                      <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`description_${index}`}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          size='small'
                          placeholder='Description'
                          value={row.description}
                          onChange={e => handleInputChange(index, 'description', e.target.value)}
                          InputProps={{
                            classes: { notchedOutline: 'no-border' },
                            className: 'custom-textfield',
                          }}
                          sx={{
                            height: '27px',
                            '& .MuiInputBase-input': inputTableCellSx
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`required_${index}`}>
                        <CustomCheckbox
                          key={`required-${index}`}
                          checked={row.required}
                          label=""
                          onChange={(e) => handleInputChange(index, "required", e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`datatype_${index}`}>
                        <FormControl fullWidth>
                          <Select
                            labelId="datatype-simple-select-label"
                            id="datatype-simple-select"
                            value={row.datatype}
                            label=""
                            size="small"
                            placeholder="datatype"
                            onChange={e => handleInputChange(index, 'datatype', e.target.value)}
                            sx={{...inputTableCellSx, py: 0, textTransform: "capitalize"}}
                          >
                            {["array", "object", "string", "number", "boolean", "null"].map((type) => (
                              <MenuItem value={type} key={type} sx={{fontFamily: "Inter, Sans-serif", textTransform: "capitalize"}}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </>
                  )
                }
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }} key={`action_${index}`}>
                  <IconButton size="small" aria-label="Delete row" onClick={() => handleDeleteRow(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FormDataComponent;
