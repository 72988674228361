import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Tab,
  Tabs
} from "@mui/material";
import QueryParams from "../Apis/QueryParamsComponent.jsx";
import HeadersComponent from "../Apis/HeadersComponent.jsx";
import BodyComponent from "../Apis/BodyComponent.jsx";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../Common/LoadingOverlay.js";
import CTAAccountDialog from "../Common/CTAAccountDialog.js";
import TestCaseDataSet from "./TestCaseDataSet.jsx";

import {setSelectedDataSetModifiedDetail} from "../../redux-store/dataSetReducers/dataSetSlice.js";
import {createPrivateDataSet} from "../../redux-store/dataSetReducers/dataSetActions.js";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import coreUtils from "../../utils/coreUtils.js";

const TestCaseSetRequest = ({
  testCase,
  onSave,
  isDisabledActions,
  disableUrl
}) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [method, setMethod] = useState("");
  const [url, setUrl] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [body, setBody] = useState({});
  const [bodyType, setBodyType] = useState([]);
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const isModified = useRef(false);
  const isSaving = useSelector(
    (state) => state.testCaseSet.isSaving
  );
  const selectedDataSet = useSelector(
    (state) => state.dataSet.selectedDataSet
  );
  const selectedDataSetModifiedDetail = useSelector(
    (state) => state.dataSet.selectedDataSetModifiedDetail
  );
  const isSavingDataSet = useSelector(
    (state) => state.dataSet.isLoading
  )
  const tabLabels = ["Params", "Headers", "Body"];
  const defaultHeaders = [
    { name: "Accept", value: "*/*", readOnly: true },
    { name: "Accept-Encoding", value: "identity", readOnly: true },
    { name: "Connection", value: "keep-alive", readOnly: true },
  ];
  const saveButtonRef = useRef(null);

  useEffect(() => {
    const extractedParams = updateParamsFromUrl(url);
    setQueryParams(extractedParams);
  }, [url]);

  useEffect(() => {
    setInitialState();
  }, [selectedDataSet]);

  const setInitialState = async (initialDataSetList) => {
    setUrl(testCase?.url || "");
    setMethod(testCase?.request_type || "");
    
    const dataSet = {...selectedDataSet};
    await dispatch(setSelectedDataSetModifiedDetail({headers: dataSet?.headers || [], params: dataSet?.params || [], body: dataSet?.body || null, bodyType: dataSet?.body_type || dataSet?.bodyType || null}));
    handleParamsChange([...(selectedDataSet?.params || [])]);
    setBody(dataSet?.body || {});
    setBodyType(dataSet?.body_type || "");
    setHeaders(dataSet?.headers || [...defaultHeaders]);
  }

  const setIsModified = (value=false) => {
    isModified.current = value;
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMethodChange = (event) => {
    const newMethod = event.target.value;
    setMethod(newMethod);
  };

  const handleUrlChange = (event) => {
    setUpdatedQueryParams(event.target.value);
  };

  const updateParamsFromUrl = (requestUrl) => {
    return coreUtils.extractParamsFromUrl(requestUrl);
  };

  const setUpdatedQueryParams = async (url, params) => {
    const extractedParams = params ? params : updateParamsFromUrl(url);
    setUrl(url);
    setQueryParams(extractedParams);
    await dispatch(setSelectedDataSetModifiedDetail({params: extractedParams}));
  }

  const concatQueryParams = (rows) => {
    const tmpUrl = url || testCase?.url || "";
    const validParams = rows.filter((p) => p.name);
    const tParams = buildQueryParams(validParams);
    const indexOfQuery = tmpUrl.indexOf("?");
    const baseUrl =
      indexOfQuery > -1 ? tmpUrl.substring(0, indexOfQuery) : tmpUrl;
    const newUrl = `${baseUrl}${tParams.length ? "?" + tParams : ""}`;
    setUpdatedQueryParams(newUrl, validParams);
  };

  const buildQueryParams = (arr) => {
    return arr
      .map((item) => (item.value ? `${item.name}=${item.value}` : item.name))
      .join("&")
      .replace(/&+$/, "");
  };

  const handleCreatePrivateDataSet = async () => {
    const requestData = {
      body: selectedDataSetModifiedDetail?.body || null,
      bodyType: selectedDataSetModifiedDetail?.bodyType || null,
      params: selectedDataSetModifiedDetail?.params || [],
      headers: selectedDataSetModifiedDetail?.headers || [],
      title: "Private",
      entityId: testCase?.entity?.id,
      testCaseSetId: testCase?.id
    };
    const response = await dispatch(createPrivateDataSet(requestData));
    if (response?.payload) {
      isModified.current = false;
      handleSaveRequest({dataSetId: response.payload?.id});
    } else {
      openSnackbar({ message: "Data set updation failed.", severity: "error" });
    }
  }

  const handleSaveRequest = async (data={}) => {
    if (isModified.current) {
      handleCreatePrivateDataSet();
    } else {
      const dataSetId = data.dataSetId || selectedDataSet?.id;
      onSave({
        ...testCase,
        requestUrl: url,
        requestType: method,
        dataSetId: dataSetId
      });
    }
  };

  const handleUrlTextFieldBlur = (event) => {
    event.preventDefault();
  };

  const handleParamsChange = (newParams) => {
    concatQueryParams(newParams);
  };

  const handleHeadersChange = async (validHeaders) => {
    setHeaders(validHeaders);
    await dispatch(setSelectedDataSetModifiedDetail({headers: validHeaders}));
  };

  const handleBodyChange = async (body, bodyType) => {
    setBody(body);
    setBodyType(bodyType);
    await dispatch(setSelectedDataSetModifiedDetail({body: body, bodyType: bodyType}));
  };

  const getTabStyle = (index) => ({
    textTransform: "none",
    color: activeTab === index ? "#fff" : "#575757",
    backgroundColor: activeTab === index ? "#D27FFF" : "#fff",
  });

  const handleOpenAccountDialog = () => {
    setOpenAccountDialog(true);
  };

  const handleCloseAccountDialog = () => {
    setOpenAccountDialog(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      saveButtonRef?.current?.click();
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <QueryParams
            initialParams={queryParams}
            onParamsChange={handleParamsChange}
            skipOutsideClickCheck={true}
            hideExtraFields={true}
          />
        );
      case 1:
        return (
          <HeadersComponent
            headers={headers}
            onHeadersChange={handleHeadersChange}
            hideExtraFields={true}
          />
        );
      case 2:
        return (
          <BodyComponent
            body={body}
            bodyType={bodyType}
            onBodyChange={handleBodyChange}
            hideExtraFields={true}
          />
        );
      // case 3:
      //   return (<AuthorizationComponent collection={collection} />);
      default:
        return null;
    }
  };

  const tabStyle = {
    height: "32px",
    minHeight: "32px",
    textTransform: "none",
    color: "#575757",
    backgroundColor: "transparent",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        p: 2,
        bgcolor: "white",
        borderRadius: "8px",
        mb: "16px",
      }}
    >
      {(isSaving || isSavingDataSet) && (<LoadingOverlay isLoading={isSaving || isSavingDataSet} />)}
      <h5 style={{ color: "black" }}>Request Data</h5>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Select
          size="small"
          value={method}
          onChange={handleMethodChange}
          sx={{ marginRight: "10px", width: "15%" }}
          disabled={disableUrl}
        >
          <MenuItem value="GET" sx={{ color: "green", fontWeight: "bold" }}>
            GET
          </MenuItem>
          <MenuItem value="POST" sx={{ color: "#ffb900", fontWeight: "bold" }}>
            POST
          </MenuItem>
          <MenuItem value="PUT" sx={{ color: "blue", fontWeight: "bold" }}>
            PUT
          </MenuItem>
          <MenuItem value="PATCH" sx={{ color: "purple", fontWeight: "bold" }}>
            PATCH
          </MenuItem>
          <MenuItem value="DELETE" sx={{ color: "red", fontWeight: "bold" }}>
            DELETE
          </MenuItem>
          <MenuItem value="HEAD" sx={{ color: "green", fontWeight: "bold" }}>
            HEAD
          </MenuItem>
          <MenuItem value="OPTIONS" sx={{ color: "pink", fontWeight: "bold" }}>
            OPTIONS
          </MenuItem>
        </Select>
        <TextField
          value={url}
          onChange={handleUrlChange}
          onKeyDown={handleKeyDown}
          onBlur={handleUrlTextFieldBlur}
          variant="outlined"
          size="small"
          fullWidth
          sx={{ marginRight: "10px" }}
          disabled={disableUrl}
        />
        <Button
          variant="contained"
          ref={saveButtonRef}
          onClick={handleSaveRequest}
          size="medium"
          disabled={isDisabledActions || disableUrl}
        >
          Save
        </Button>
      </Box>
      <TestCaseDataSet testCase={testCase} onTestCaseSave={handleSaveRequest} updateIsModified={setIsModified} />
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        sx={{
          marginTop: "10px",
          height: "32px",
          minHeight: "32px",
          border: "1px solid #e9e9e9",
          borderRadius: "5px",
        }}
      >
        {tabLabels.map((label) => (
          <Tab
            key={label}
            label={label}
            sx={getTabStyle(label)}
            style={tabStyle}
          />
        ))}
      </Tabs>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          marginTop: "10px",
          marginBottom: "10px",
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {renderTabContent()}
      </Box>
      <LoadingOverlay isLoading={isLoading} />
      <CTAAccountDialog
        open={openAccountDialog}
        handleClose={handleCloseAccountDialog}
      />
    </Box>
  );
};

export default TestCaseSetRequest;
