import React from "react";
import {Box} from "@mui/material";

const TestPlans = ({icon}) => {
  return (
    <Box>
      {/* <div className="title">
        {icon ? icon : <div className="menuIcon testPlans" />}
        <h3>Test Plans</h3>
      </div> */}
      <p style={{marginTop: "0px"}}>
        You can schedule your Test Plans (groups of test suites) either periodically or as part of CI/CD (GitHub Actions, CircleCI, etc.). A detailed report is sent to the configured email IDs and Slack or Jira (if integrated). Developers can see all the detailed logs along with the root cause analysis.
      </p>
      <p>
        Developers can trigger test plans directly from their developer console or Visual Studio plugin and view detailed reports there right before committing anything to GitHub.
      </p>
    </Box>
  )
};

export default TestPlans;
