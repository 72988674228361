import axios from 'axios';
import store from 'redux-store/store';

const default_options = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  timeout: 60000,
  headers: {}
};

const axiosClient = (options = {}) => {
  const headers = {
    ...default_options.headers,
    ...options.headers
  };

  const instance = axios.create({ ...default_options, ...options, headers });

  instance.interceptors.response.use(
    response => response,
    error => {
      console.log("comes inside axios interceptor error", error);
      const backendMessage = error.response?.data?.error || 'An unexpected error occurred. Please try again later.';
      store.dispatch({
        type: 'SET_GLOBAL_ERROR_SNACKBAR_MESSAGE',
        payload: { message: backendMessage, severity: 'error' }
      });
      return Promise.reject(error);
    }
  );

  return instance;
};

const apiAgent = (args) => {
  const project = localStorage.getItem('lastSelectedProject');
  const parsedProject = project ? JSON.parse(project) : {};

  if (args.params === null || args.params === undefined) {
    args.params = {};
  }
  const authToken = localStorage.getItem('token');

  return axiosClient({
    signal: args.signal,
    headers: {
      'project_id': parsedProject?.id,
      'Authorization': `Bearer ${authToken}`
    }
  })(args);
};

export { axiosClient, apiAgent };

export const COOKIE_SETTINGS = {
  "AUTH_DURATION": 60*60*24*365
};
