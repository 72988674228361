import dayjs from 'dayjs';
import { Box, Stack } from "@mui/material";
import CustomTable from "../Common/CustomTable";
import CustomChip from '../Common/CustomChip';
import LoadingOverlay from "../Common/LoadingOverlay";

import { BUILD_STATUS_TO_COLOR } from '../../config/constants';

const BuildHistory = function ({ buildList = [], onViewBuild, isLoading, onViewSource }) {
  const renderTable = () => {
    if (buildList?.length === 0) {
      return (
        <Box sx={{ width: "100%", textAlign: "center", padding: "50px 32px" }}>
          <h3>No results found.</h3>
        </Box>
      );
    }
    const sxTh = {height: "54px"};
    return (
      <CustomTable
        headers={[
          {
            id: "buildNumber",
            type: "text",
            text: "Build Number",
            sx: sxTh
          },
          {
            id: "environment",
            type: "text",
            text: "Environment",
            sx: sxTh
          },
          {
            id: "triggeredVia",
            type: "text",
            text: "Triggered Via",
            sx: sxTh
          },
          {
            id: "startTime",
            type: "text",
            text: "Start Time",
            sx: sxTh
          },
          {
            id: "executionTime",
            type: "text",
            text: "Execution Time",
            sx: sxTh
          },
          {
            id: "status",
            type: "text",
            text: "Status",
            sx: sxTh
          },
          {
            id: "sourceCode",
            type: "text",
            text: "Source Code",
            sx: sxTh
          },
        ]}
        rows={buildList.map(
          ({
            id,
            source_code,
            build_number,
            started_at,
            status,
            triggered_via,
            execution_time,
            environment_set,
            completed_at
          }) => ({
            id,
            cells: [
              {
                id: "buildNumber-" + id,
                type: "text",
                text: build_number,
                sx: { cursor: "pointer", width: "80px" },
                onClick: () => onViewBuild(id),
              },
              {
                id: "environment-" + id,
                type: "text",
                text: environment_set?.title || "",
                sx: { width: "100px" },
              },
              {
                id: "triggeredVia-" + id,
                type: "text",
                text: triggered_via,
                sx: { width: "120px" },
              },
              {
                id: "startTime-" + id,
                type: "text",
                text: started_at
                  ? new Date(started_at * 1000).toUTCString()
                  : "",
                sx: { width: "120px" },
              },
              {
                id: "executionTime-" + id,
                type: "text",
                text: execution_time
                  ? `${execution_time.toFixed(2)} seconds`
                  : "",
                sx: { width: "100px" },
              },
              {
                id: "status-" + id,
                type: "component",
                content: (
                  <Stack useFlexGap direction="row" className="chips lastrun" flexWrap="wrap" spacing={1}>
                    {<CustomChip
                      className={status}
                      title={status}
                      label={
                        <span>
                          {environment_set?.title || '-'}:{' '}
                          <span
                            style={{
                              color:
                                BUILD_STATUS_TO_COLOR[status] ||
                                'black',
                            }}
                          >
                            {completed_at
                              ? dayjs(completed_at * 1000).fromNow() // multiply timestamp with 1000 to make it js compatible
                              : status || '-'}
                          </span>
                        </span>
                      }
                    />}
                  </Stack>
                ),
                sx: { width: "120px" },
              },
              {
                id: "sourceCode-" + id,
                type: "text",
                text: source_code,
                sx: { cursor: "pointer", width: "100px" },
                onClick: onViewSource,
              },
            ],
          })
        )}
      />
    );
  };

  return (
    <Box>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && renderTable()}
    </Box>
  );
};

export default BuildHistory;
