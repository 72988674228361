import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import TestScenarioBuildListPage from './TestScenarioBuildListPage';
import TestScenarioBuildLogs from './TestScenarioBuildLogs';

const TestScenarioBuildPage = function({project, testScenarioId, buildId}) {
  const [currentView, setCurrentView] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (testScenarioId && buildId) {
      setCurrentView("testScenarioBuildLogs");
    } else if (testScenarioId) {
      setCurrentView("testScenarioBuildList");
    }
  }, [testScenarioId, buildId]);

  let content = null;
  if (currentView === 'testScenarioBuildList') {
    content = (<TestScenarioBuildListPage project={project} testScenarioId={testScenarioId} />);
  } else if (currentView === 'testScenarioBuildLogs') {
    content = (<TestScenarioBuildLogs project={project} testScenarioId={testScenarioId} buildId={buildId} />);
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
}

export default TestScenarioBuildPage;
