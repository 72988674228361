import { Divider, TextField, Select, MenuItem, Box, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResourceSummary } from "../../redux-store/currentUserActions";
import { setResourceSummary } from '../../redux-store/currentUserSlice';
import AuthTypeText from "./AuthTypeText";

const ApiKeyAuthorization = ({ selectedType }) => {
  const selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [key, setKey] = useState('');
  const [authType, setAuthType] = useState('Headers');

  useEffect(() => {
    if (selectedSummary?.authorization_value) {
      setValue(selectedSummary?.authorization_value?.value);
      setKey(selectedSummary?.authorization_value?.key);
      setAuthType(selectedSummary?.authorization_value?.authType);
    }
  }, [selectedSummary]);

  const handleInputChange = (field, value) => {
    if (field === 'Value') setValue(value);
    else if (field === 'Key') setKey(value);
    else setAuthType(value);
  }

  const handleApiCall = async () => {
    const state = {
      id: selectedSummary?.id,
      summary: {
        authorization_type: selectedType,
        authorization_value: {
          key: key,
          value: value,
          authType: authType
        }
      }
    };
    const res = await dispatch(updateResourceSummary(state));
    if (res.payload.summary) {
      await dispatch(setResourceSummary(res.payload.summary));
      setValue(res.payload.summary.authorization_value.value);
      setKey(res.payload.summary.authorization_value.key);
      setAuthType(res.payload.summary.authorization_value.authType);
    }
  }

  return (
    <Box p={2}>
      <AuthTypeText />
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body">Key:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={key}
            onChange={(e) => handleInputChange('Key', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Value:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={value}
            onChange={(e) => handleInputChange('Value', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Add to:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Select
            size="small"
            variant="outlined"
            value={authType}
            onChange={(e) => handleInputChange('authType', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          >
            <MenuItem value={"header"}>Header</MenuItem>
            <MenuItem value={"QueryParams"}>Query Params</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApiKeyAuthorization;
