import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import TabsComponent from "./TabsComponent";
import KeyValueTable from "../Common/KeyValueTable";

import coreUtils from "../../utils/coreUtils";

const buildResponseVariables = (testCaseSetList, testCaseSetId) => {
  let responseVariables = [];

  let index = 0;
  while (index < testCaseSetList.length){
    if(testCaseSetList[index].id === testCaseSetId){
      break;
    }
    responseVariables.push({
      var_name: `response${index+1}_status`,
      var_value: testCaseSetList[index].test_case_set_response?.status
    })
    responseVariables.push({
      var_name: `response${index+1}_headers`,
      var_value: testCaseSetList[index].test_case_set_response?.headers
    })
    responseVariables.push({
      var_name: `response${index+1}_body`,
      var_value: testCaseSetList[index].test_case_set_response?.body
    })
    index += 1;
  }
  responseVariables.push({
    var_name: 'current_response_status',
    var_value: testCaseSetList[index].test_case_set_response?.status
  })
  responseVariables.push({
    var_name: 'current_request_time',
    var_value: testCaseSetList[index].test_case_set_response?.time_in_ms
  })
  responseVariables.push({
    var_name: 'current_response_headers',
    var_value: testCaseSetList[index].test_case_set_response?.headers
  })
  responseVariables.push({
    var_name: 'current_response_body',
    var_value: testCaseSetList[index].test_case_set_response?.body
  })
  return responseVariables;

}

const EntityVariables = ({ testCaseSetId }) => {
  const selectedTestScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );
  const testCaseSetList = useSelector(state => (state.testCaseSet.testCaseSetList));
  const tabLabels = ["Response", "Global"];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex);
  };


  const renderTabContent = () => {
    let parsedVariables;
    switch (activeTab) {
      case 0:
        let responseVariables = buildResponseVariables(testCaseSetList, testCaseSetId);
        return <KeyValueTable allItems={responseVariables} sxCell={{textAlign: "left"}} json={true} isReadOnly />;
      case 1:
        let variablesJson = selectedTestScenarioDetail?.variables_json || {};
        parsedVariables = coreUtils.parseObjectToKeyValueArray(variablesJson);
        return <KeyValueTable allItems={parsedVariables} sxCell={{textAlign: "left"}} json={true} isReadOnly />;
      default:
        return null;
    }
  };
  return (
    <Box sx={{ py: 2 }}>
      <TabsComponent
        tabLabels={tabLabels}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        customTabStyle={{ width: "auto", fontSize: "12px", fontWeight: 600 }}
        tabsStyle={{ border: "none" }}
        activeTabStyle={{ backgroundColor: "transparent" }}
      />
      <Box sx={{ height: "500px", overflow: "auto" }}>{renderTabContent()}</Box>
    </Box>
  );
};

export default EntityVariables;
