import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Stack, Tab, Tabs, Box } from '@mui/material';
import { CancelOutlined } from "@mui/icons-material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MainLayout from '../../layouts/MainLayout'
import CustomTabPanel from "../Common/CustomTabPanel";
import DeleteDialog from "../Common/DeleteDialog";
import TestPlanSuiteTable from "./TestPlanSuiteTable";
import BuildPlan from "./BuildPlan";
import EditDetail from "./EditDetail";
import TestPlanBuildsDrawer from "./TestPlanBuildsDrawer";
import TestPlanHeader from "./TestPlanHeader";
import TabsComponent from "../TestScenarios/TabsComponent";
import CICDIntegrations from "./CICDIntegrations.jsx";

import { detachPlanFromSuite } from '../../redux-store/testSuitePlanReducers/testSuitePlanActions';
import { getTestPlan } from "../../redux-store/testPlanReducers/testPlanActions";
import coreUtils from "../../utils/coreUtils";

const drawerItems = [
  { icon: 'article', title: 'Details', id: 'detail' },
  { icon: 'play', title: 'Run', id: 'run' },
  // { icon: 'search', title: 'Debug', id: 'debug', disabled: true },
  { icon: 'build', title: 'Builds', id: 'builds' }
];

export function BasicDateTimePicker({value, onChange}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
          <DateTimePicker sx={{ width: "200px" }} label="Start Time" value={value} onChange={onChange} />
      </DemoContainer>
    </LocalizationProvider>
  );
}

const TestPlan = ({ project, testPlanId }) => {
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const [timeGap, setTimeGap] = useState({ days: 0, hours: 0, minutes: 0 });
    const [attachedSuites, setAttachedSuites] = useState([]);
    const [openDetachSuiteId, setOpenDetachSuiteId] = useState('');
    const [selectedItemContent, setSelectedItemContent] = useState(null);
    const testPlanDetail = useSelector(state => state.testPlan.selectedTestPlanDetail);
    const isLoading = useSelector((state) => state.testPlan.isLoading);
    const tabLabels = ["Test Suites", "CI/CD Integrations"];
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
      fetchTestPlan();
    }, []);

    useEffect(() => {
      updateTestPlanState();
    }, [testPlanDetail]);

    const fetchTestPlan = async () => {
      await dispatch(getTestPlan({ testPlanId }));
    }

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const updateTestPlanState = () => {
      setAttachedSuites(testPlanDetail.test_suite_plans || []);
      if (testPlanDetail?.time_gap) setTimeGap(coreUtils.parseMinutes(testPlanDetail.time_gap));
    }

    const onDetachTestSuite = async () => {
        if (openDetachSuiteId) {
            await dispatch(detachPlanFromSuite({ testSuitePlanId: openDetachSuiteId }));
            setAttachedSuites([...attachedSuites.filter(planSuite => planSuite.id !== openDetachSuiteId)]);
            setOpenDetachSuiteId('');
        }
    }

    const onDetachTestSuiteClick = (testSuitePlanId) => setOpenDetachSuiteId(testSuitePlanId);

    const renderDetachSuiteConfirmModal = () => {
        return (
            <DeleteDialog title="Confirm Detach" open={!!openDetachSuiteId} onClose={() => setOpenDetachSuiteId('')} deleteBtnLabel="Detach" secondaryBtnLabel="Cancel" secondaryBtnIcon={<CancelOutlined />} onDeleteAction={onDetachTestSuite} onSecondaryAction={() => setOpenDetachSuiteId('')}>
                <Typography variant="body1" sx={{ marginBottom: '16px' }}>
                    Are you sure you want to detach this Test Suite?
                </Typography>
            </DeleteDialog>
        )
    }

    const renderDrawerItem = ({selectedItem, onDrawerClose}) => {
      const props = { projectId: project.id, testPlanId, testPlanDetail, attachedSuites, timeGap, setTimeGap, updateTestPlanState };
      switch (selectedItem) {
        case 'run':
          setSelectedItemContent(<BuildPlan {...props} closeDrawer={onDrawerClose} />);
          return false;
        // case 'debug':
        //   return <></>;
        case 'builds':
          setSelectedItemContent(<TestPlanBuildsDrawer {...props} closeDrawer={onDrawerClose} />);
          return false;
        default:
          setSelectedItemContent(<EditDetail {...props} closeDrawer={onDrawerClose} />);
          return false;
      }
    };

    const renderTabContent = () => {
      switch (activeTab) {
        case 1:
          return (<CICDIntegrations webhookBuildCurl={testPlanDetail?.webhook_curl} webhookBuildStatusCurl={testPlanDetail?.build_status_curl} />);
        default:
          return (<TestPlanSuiteTable attachedSuites={testPlanDetail?.test_suite_plans} onDetachTestSuiteClick={onDetachTestSuiteClick} isLoading={isLoading} />);
      }
    };

    return (
        <MainLayout
          sx={{ width: 'calc(100% - 100px)' }}
          headerText={testPlanDetail.title}
          isLoading={isLoading}
          drawerItems={drawerItems}
          renderDrawerItem={renderDrawerItem}
          enableSideDrawer={true}
          selectedItemContent={selectedItemContent}
          sxLeftSide={{maxWidth: "70%"}}
          renderHeader={() => (<TestPlanHeader testPlanId={testPlanId} />)}
        >
          <TabsComponent activeTab={activeTab} handleTabChange={handleTabChange} tabsStyle={{padding: "0px 24px"}} tabLabels={tabLabels} customTabStyle={{width: "max-content", padding: "16px 16px", color: "rgba(0, 0, 0, 0.87)", marginRight: "16px" }} activeTabStyle={{color: "#6241d4"}} />
          <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "auto"}}>
            <CustomTabPanel value={tabIndex} index={0}>
            {renderTabContent()}
            </CustomTabPanel>
          </Box>
          {renderDetachSuiteConfirmModal()}
        </MainLayout>
    );
};

export default TestPlan;
