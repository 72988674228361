import React from "react";
import {Box} from "@mui/material";
import {AutoAwesome} from "@mui/icons-material";

import "../../styles/components/QodexBot/ProcessingShimmer.scss";

const ProcessingShimmer = () => {
  return (
    <Box className="proecessingShimmer">
      <AutoAwesome />
      <span>Working on it...</span>
    </Box>
  )
}

export default ProcessingShimmer;
