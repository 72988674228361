import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Avatar,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import {
  ArticleOutlined,
  CreateOutlined,
  ContentCopyOutlined,
  SaveOutlined,
  OpenInNew,
  Close
} from "@mui/icons-material";
import LoadingOverlay from "../Common/LoadingOverlay";
import CustomButton from "../Common/CustomButton";
import DeleteScenarioModal from "./DeleteScenarioModal";
import Status from "../Common/Status";

import {
  updateTestScenario,
  cloneTestScenario,
  deleteTestScenario,
} from "../../redux-store/testScenarioReducers/testScenarioActions";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import "../../styles/components/Common/EditDetail.scss";

const EditDetail = ({ projectId, testScenarioId, closeDrawer }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [detail, setDetail] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [tagText, setTagText] = useState(false);
  const { openSnackbar } = useSnackbar();

  const selectedTestScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );

  const isLoading = useSelector((state) => state.testScenario.isLoading);
  const isSaving = useSelector((state) => state.testScenario.isSaving);
  const allTags = useSelector((state) => state.testScenario.testScenarioTags);
  const allOwners = useSelector((state) => state.user.owners);

  const allStatuses = ["draft", "active", "archived"];

  useEffect(() => {
    setInitialState();
  }, []);

  const setInitialState = () => {
    const detailObj = {
      title: selectedTestScenarioDetail?.title || "",
      description: selectedTestScenarioDetail?.description || "",
      tags: selectedTestScenarioDetail?.tags || [],
      owner: selectedTestScenarioDetail?.owner || {},
      status: selectedTestScenarioDetail?.status || "draft",
    };
    setDetail(detailObj);
  };

  const updateTestScenarioDetail = async (data) => {
    await dispatch(updateTestScenario({ testScenarioId, data: data }));
    setIsEdit(false);
    openSnackbar({
      message: "Test scenario updated successfully.",
      severity: "success",
    });
  };

  const onChange = (name, value) => {
    if (name === "title") updateErrors("title", "");
    setDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateErrors = (key, msg = "") => {
    setErrors((prevState) => {
      const newState = { ...prevState };
      newState[key] = msg;
      if (!msg) delete newState[key];
      return newState;
    });
  };

  const onOwnerChange = (event, newValue) => {
    setDetail((prevState) => ({
      ...prevState,
      owner: newValue,
    }));
  };

  const onEdit = () => {
    setInitialState();
    setIsEdit(true);
  };

  const onClone = async () => {
    /** @todo : before cloning | Show a warning prompt to save data */
    closeDrawer();
    const response = await dispatch(
      cloneTestScenario({ testScenarioId: testScenarioId })
    );
    if (response?.payload?.id) {
      openSnackbar({
        message: "Test scenario cloned successfully.",
        severity: "success",
      });
      history.push(`/test-scenarios/${response.payload.id}/`);
    } else {
      openSnackbar({
        message: "Test scenario cloning failed!",
        severity: "error",
      });
    }
  };

  const toggleArchiveModal = () => {
    setShowArchiveModal((prevState) => !prevState);
  };

  const onArchiveTestScenario = async () => {
    await dispatch(
      updateTestScenario({
        testScenarioId: testScenarioId,
        data: { status: "archived" },
      })
    );
    openSnackbar({
      message: "Test scenario archived successfully.",
      severity: "success",
    });
    toggleArchiveModal();
  };

  const onDeleteTestScenario = async () => {
    await dispatch(deleteTestScenario({ testScenarioId: testScenarioId }));
    openSnackbar({
      message: "Test scenario deleted successfully.",
      severity: "success",
    });
    closeDrawer();
    history.push("/test-scenarios/");
  };

  const onSave = () => {
    const formattedDetail = {
      ...detail,
      owner_id: detail?.owner?.id,
    };
    let error = false;
    if (!formattedDetail?.owner_id) {
      updateErrors("owner", "Required");
      error = true;
    }
    if (!formattedDetail?.title || !formattedDetail.title.trim()) {
      updateErrors("title", "Required");
      error = true;
    }
    if (error) {
      return;
    }
    updateTestScenarioDetail(formattedDetail);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
    setInitialState();
  };

  const handleDeleteTag = (newValue) => {
    setDetail((prevState) => ({
      ...prevState,
      tags: [...prevState.tags.filter((tag) => newValue !== tag)],
    }));
  };

  const onChangeTag = (event, newValue) => {
    if (newValue && newValue.trim() && detail?.tags?.indexOf(newValue) === -1) {
      setDetail((prevState) => ({
        ...prevState,
        tags: [...prevState.tags, newValue],
      }));
    } else {
      event.preventDefault();
    }
  };

  const onStatusChange = (event, newValue) => {
    setDetail((prevState) => ({
      ...prevState,
      status: newValue,
    }));
  };

  const getFilteredTags = () => {
    return allTags.filter((tag) => detail?.tags?.indexOf(tag) === -1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && tagText && tagText.trim()) {
      event.preventDefault();
      event.stopPropagation();
      const trimmedTag = tagText.trim();
      const tagIndex = detail.tags.findIndex(
        (tag) => tag.toLowerCase() === trimmedTag.toLowerCase()
      );
      if (tagIndex >= 0) {
        return;
      }
      setDetail((prevState) => ({
        ...prevState,
        tags: [
          ...prevState.tags,
          trimmedTag[0].toUpperCase() + trimmedTag.slice(1),
        ],
      }));
      setTagText("");
    }
  };

  if (isLoading) {
    return <LoadingOverlay isLoading={isLoading} />;
  }

  let content = null;
  content = (
    <>
      <div className="section">
        <h6>ID</h6>
        {isEdit ? (
          <TextField
            sx={{ mb: 2, width: "100%" }}
            disabled
            size="small"
            variant="outlined"
            value={selectedTestScenarioDetail?.code}
          />
        ) : (
          <Typography>{selectedTestScenarioDetail?.code}</Typography>
        )}
      </div>
      <div className="section">
        <h6>Title</h6>
        {isEdit ? (
          <TextField
            sx={{ mb: 2, width: "100%" }}
            size="small"
            variant="outlined"
            value={detail?.title}
            onChange={(e) => {
              onChange("title", e.target.value);
            }}
          />
        ) : (
          <Typography>{selectedTestScenarioDetail?.title}</Typography>
        )}
        {errors?.title && (
          <Typography sx={{ color: "red" }}>{errors.title}</Typography>
        )}
      </div>
      <div className="section">
        <h6>Description</h6>
        {isEdit ? (
          <textarea
            rows={3}
            size="small"
            variant="outlined"
            value={detail?.description}
            onChange={(e) => {
              onChange("description", e.target.value);
            }}
            className="description"
          />
        ) : (
          <div className="truncate-2-lines description" title={selectedTestScenarioDetail?.description}>{selectedTestScenarioDetail?.description}</div>
        )}
        {errors?.description && (
          <Typography sx={{ color: "red" }}>{errors.description}</Typography>
        )}
      </div>
      <div className="section">
        <h6>Status</h6>
        <RadioGroup
          aria-labelledby="status-radio-buttons-group-label"
          onChange={onStatusChange}
          value={isEdit ? detail.status : selectedTestScenarioDetail.status}
        >
          {!isEdit && (
            allStatuses.filter(
              (status) => status === selectedTestScenarioDetail.status
            )
            ).map((status) => (
              <Status status={status} />
            )
          )}
          {isEdit && allStatuses.map((status) => (
            <FormControlLabel
              key={status}
              sx={{ height: "30px" }}
              value={status}
              control={<Radio />}
              label={(<Status status={status} />)}
            />
          ))}
        </RadioGroup>
      </div>
      <div className="section">
        <h6>Owner</h6>
        {isEdit ? (
          <Autocomplete
            options={allOwners}
            getOptionLabel={(option) => option.name || option.email}
            onChange={onOwnerChange}
            value={detail?.owner}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ mt: 1 }}
                size="small"
                variant="outlined"
              />
            )}
          />
        ) : (
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              alt={selectedTestScenarioDetail?.owner?.name}
              src={selectedTestScenarioDetail?.owner?.avatar}
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="body1">
              {selectedTestScenarioDetail?.owner?.name}
            </Typography>
          </Stack>
        )}
        {errors?.owner && (
          <Typography sx={{ color: "red" }}>{errors.owner}</Typography>
        )}
      </div>
      <div className="tagsContainer section">
        <h6>Tags</h6>
        {isEdit ? (
          <div className="tags">
            {detail?.tags?.map((tag) => (
              <Chip
                key={tag}
                sx={{ mr: 1, mt: 1 }}
                label={tag}
                onDelete={() => {
                  handleDeleteTag(tag);
                }}
              />
            ))}
            <Autocomplete
              options={getFilteredTags()}
              onChange={onChangeTag}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ mt: 1 }}
                  onChange={(e) => {
                    setTagText(e.target.value);
                  }}
                  value={tagText}
                  onKeyDown={handleKeyDown}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </div>
        ) : (
          <div>
            {selectedTestScenarioDetail?.tags?.map((tag) => (
              <Chip key={tag} sx={{ mr: 1, mt: 1 }} label={tag} />
            ))}
          </div>
        )}
      </div>
      {!isEdit && (
        <>
          <div className="section">
            <h6>Associated Test Suites</h6>
            {selectedTestScenarioDetail?.test_suite_scenarios?.length === 0 &&
              "No associated test suites"}
            {selectedTestScenarioDetail?.test_suite_scenarios?.map(
              (testSuiteScenario) => (
                <div key={testSuiteScenario.id}>
                  {testSuiteScenario?.test_suite?.title}
                  <OpenInNew
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/test-suites/${testSuiteScenario?.test_suite?.id}`,
                        "_blank"
                      );
                    }}
                  />
                </div>
              )
            )}
          </div>
          <div className="section">
            <h6>Last Update</h6>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Avatar
                alt={selectedTestScenarioDetail?.updated_by?.name}
                src={selectedTestScenarioDetail?.updated_by?.avatar}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="body1">
                {selectedTestScenarioDetail?.updated_by?.name}
              </Typography>
            </Stack>
            {selectedTestScenarioDetail?.updated_at && (
              <Typography>
                {new Date(selectedTestScenarioDetail.updated_at * 1000).toString()}
              </Typography>
            )}
          </div>
          <div className="section">
            <h6>Created At</h6>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Avatar
                alt={selectedTestScenarioDetail?.created_by?.name}
                src={selectedTestScenarioDetail?.created_by?.avatar}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="body1">
                {selectedTestScenarioDetail?.created_by?.name}
              </Typography>
            </Stack>
            {selectedTestScenarioDetail?.created_at && (
              <Typography>
                {new Date(selectedTestScenarioDetail.created_at * 1000).toString()}
              </Typography>
            )}
          </div>
        </>
      )}
    </>
  );

  const nonEditModeActions = (
    <Grid container spacing={2} sx={{ flexDirection: "row-reverse" }}>
      <CustomButton
        variant="outlined"
        color="primary"
        disabled={false}
        onClick={onEdit}
      >
        <CreateOutlined sx={{ mr: 1 }} />
        Edit
      </CustomButton>
      <CustomButton
        sx={{ mr: 2 }}
        variant="outlined"
        color="primary"
        disabled={false}
        onClick={onClone}
      >
        <ContentCopyOutlined sx={{ mr: 1 }} />
        Clone
      </CustomButton>
    </Grid>
  );

  const editModeActions = (
    <Grid container spacing={2}>
      <CustomButton
        variant="outlined"
        sx={{ mr: 2 }}
        color="primary"
        disabled={isSaving}
        onClick={onSave}
      >
        <SaveOutlined sx={{ mr: 1 }} />
        Save
      </CustomButton>
      <CustomButton
        variant="outlined"
        color="primary"
        disabled={isSaving}
        onClick={onCancelEdit}
      >
        <Close sx={{ mr: 1 }} />
        Cancel
      </CustomButton>
    </Grid>
  );

  return (
    <Box sx={{ p: 2, px: 4, color: "black" }} className="editDetail">
      {isSaving && <LoadingOverlay isLoading={isSaving} />}
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          {isEdit ? (
            <>
              <h5>
                <CreateOutlined sx={{ mr: 1 }} />
                Edit Basic Details
              </h5>
            </>
          ) : (
            <>
              <h5>
                <ArticleOutlined sx={{ mr: 1 }} />
                Basic Details
              </h5>
            </>
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          {!isEdit && nonEditModeActions}
        </Grid>
        <Grid item xs={12} md={12}>
          {content}
        </Grid>
        <Grid item xs={12} md={12} style={{ padding: "32px 40px" }}>
          {isEdit && editModeActions}
        </Grid>
        {!isEdit && (
          <div style={{ borderBottom: "1px solid grey", width: "100%" }}></div>
        )}
        <Grid item xs={12} md={12}>
          {!isEdit && (
            <>
              <h5 style={{ color: "#d32f2f" }}>Danger Zone</h5>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                color="error"
                disabled={false}
                onClick={toggleArchiveModal}
              >
                Delete
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {showArchiveModal && (
        <DeleteScenarioModal
          onClose={toggleArchiveModal}
          onArchive={onArchiveTestScenario}
          onDelete={onDeleteTestScenario}
        />
      )}
    </Box>
  );
};

export default EditDetail;
