import React from 'react';

const AppLayout = ({ children, isPostAuth }) => {
  return (
    <div>
      {isPostAuth ? <div className='text_title'></div> : null}
      {children}
      {isPostAuth ? <div className='text_title'></div> : null}
    </div>
  );
}

export default AppLayout;
