import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';

export const fetchCollection = createAsyncThunk(
  "fetchCollection",
  async (_, { dispatch, rejectWithValue , history}) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "patch",
        url: '/api/v1/collections/search',
        params: {
          ...(_.collectionId && { entityable_id: _.collectionId }),
          ...(_.collectionSlug && { slug: _.collectionSlug }),
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        },
        data: {
          test_case: _?.testCase
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCollectionsTree = createAsyncThunk(
  "fetchCollectionsTree",
  async (_, { dispatch, rejectWithValue , history}) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/projects/${_.projectId}/collections/collection_tree`,
        params: {
          ...(_.collectionId && { entityable_id: _.collectionId }),
          ...(_.collectionSlug && { slug: _.collectionSlug }),
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        },
        data: {
          test_case: _?.testCase
        },
      });
      if (res.status === 200) {
        return res.data.collections;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCollectionsList = createAsyncThunk(
  "fetchCollectionsList",
  async (_, { dispatch, rejectWithValue , history}) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/projects/${_.projectId}/collections/`,
        params: {
          ...(_.collectionId && { entityable_id: _.collectionId }),
          ...(_.collectionSlug && { slug: _.collectionSlug }),
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        },
        data: {
          test_case: _?.testCase
        },
      });
      if (res.status === 200) {
        return res.data.collections;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPublicCollection = createAsyncThunk(
  'fetchPublicCollection',
  async ({collectionId}, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'get',
        url: '/api/v1/public_apis/collection',
        params: {
          collection_id: collectionId
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const importCollection = createAsyncThunk(
  'collections/importPostman',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "post",
        url: `/api/v1/projects/${_.projectId}/collections/import_collection`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          s3_url: _.s3Url,
          import_type: _.importType
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forkCollection = createAsyncThunk(
  'collections/fork',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/collections/${_.collectionId}/fork`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          project_id: _.projectId,
          collection_name: _.collectionName,
        }
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err?.payload?.response);
    }
  }
);

export const makeCollectionPublic = createAsyncThunk(
  'collections/makePublic',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/collections/${_.collectionId}/make_public`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const makeCollectionPrivate = createAsyncThunk(
  'collections/makePrivate',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/collections/${_.collectionId}/make_private`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const importCollectionViaURL = createAsyncThunk(
  'collections/importPostman',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "post",
        url: `/api/v1/projects/${_.projectId}/collections/import_collection`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          external_url: _.external_url,
          import_type: _.import_type
        },
      });
      if (res.status === 200) {
        return res.data;
      } 
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const exportCollection = createAsyncThunk(
  'collections/exportCollection',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "post",
        url: `/api/v1/collections/${args.collectionId}/export`,
        headers: {
          Authorization: authToken ? `Bearer ${authToken}` : undefined,
          'Content-Type': 'application/json',
        }
      });

      console.log("response data is ", res.data);
      
      if (res.status === 200) {
        return res.data;
      } 
      
      return rejectWithValue('Unexpected response status'); // In case status is not 200

    } catch (err) {
      // Handle cases where err.response is undefined
      return rejectWithValue(err.response?.data || 'Network error or unexpected issue');
    }
  }
);

