import {createSlice} from '@reduxjs/toolkit';

const testScenarioBuildSlice = createSlice({
  name: "testScenarioBuildSlice",
  initialState: {
    isLoading: false,
    buildLogs: {},
    isSaving: false,
    errors: {},
    total: 0,
    totalPages: 0,
    testScenarioBuildList: [],
    builds: {}
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    setBuildLogs: (state, action) => {
      return {
        ...state,
        setBuildLogs: {
          ...state.buildLogs,
          [action.payload.id]: {...action.payload}
        },
      };
    },
    setBuilds: (state, action) => {
      return {
        ...state,
        builds: {
          ...state.builds,
          ...{ ...action.payload },
        },
      };
    },
    setTestScenarioBuildList: (state, action) => {
      return {
        ...state,
        total: action?.payload?.total,
        totalPages: action?.payload?.total_pages,
        testScenarioBuildList: [...action?.payload?.test_scenario_builds]
      }
    }
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setBuildLogs,
  setBuilds,
  setTestScenarioBuildList
} = testScenarioBuildSlice.actions;

export default testScenarioBuildSlice.reducer;