import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  Alert
} from '@mui/material';
import { Close, PlayCircleFilledWhiteOutlined } from '@mui/icons-material';

import CustomButton from '../Common/CustomButton';
import LoadingOverlay from '../Common/LoadingOverlay';
import { createBuild } from '../../redux-store/testPlanReducers/testPlanActions';

const BuildPlan = ({ testPlanId, closeDrawer }) => {
  const dispatch = useDispatch();

  const isSaving = useSelector((state) => state.testPlan.isSaving);
  const [alertMessage, setAlertMessage] = useState('');
  const timeoutIdRef = useRef();

  const setAlert = (message) => {
    if (message && message.trim()) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      setAlertMessage(message);
      timeoutIdRef.current = setTimeout(() => {
        setAlertMessage('');
        clearTimeout(timeoutIdRef.current);
      }, 5000);
    }
  };

  const onCancelBuild = () => {
    closeDrawer();
  };

  const onRunBuild = async () => {
    await dispatch(
      createBuild({
        testPlanId,
      })
    );
    setAlert('Build was triggered successfully');
    // closeDrawer();
  };

  return (
    <Box sx={{ p: 2, color: 'black', width: '300px' }} className="editDetail">
      {isSaving && <LoadingOverlay isLoading={isSaving} />}
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item xs={6} md={6} sx={{ pt: 0 }}>
          <h4>
            <PlayCircleFilledWhiteOutlined sx={{ mr: 1 }} />
            Run
          </h4>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 4, ml: 2 }}>
          <CustomButton
            variant="outlined"
            sx={{ mr: 2 }}
            color="primary"
            disabled={isSaving}
            onClick={onRunBuild}
          >
            <PlayCircleFilledWhiteOutlined sx={{ mr: 1 }} />
            Run
          </CustomButton>
          <CustomButton
            variant="outlined"
            color="primary"
            disabled={isSaving}
            onClick={onCancelBuild}
          >
            <Close sx={{ mr: 1 }} />
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
      {alertMessage && (
        <Alert sx={{ position: 'fixed', top: 20, right: 20 }}>
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default BuildPlan;
