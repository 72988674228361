import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Stack, Typography, IconButton, Tooltip, MenuItem, MenuList} from "@mui/material";
import CustomTable from "../Common/CustomTable";
import CustomMenu from '../Common/CustomMenu';
import {MoreVert} from "@mui/icons-material";

const FunctionsList = ({pageNumber, onAction}) => {
  const isLoading = useSelector(state => state.function.isLoading);
  const functionsList = useSelector(state => state.function.functionsList || []);
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const getTableContent = () => {
    if (functionsList?.length === 0 && isLoading) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>Curating functions list...</h3>
        </Box>
      )
    }
    if (functionsList?.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No functions found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'name',
            type: 'text',
            text: 'Name',
          },
          {
            id: 'arguments',
            type: 'text',
            text: 'Arguments',
          },
          {
            id: 'count',
            type: 'text',
            text: 'Count',
          },
          {
            id: 'type',
            type: 'text',
            text: 'Type',
          },
          {
            id: 'actions',
            type: 'text',
            text: 'Actions',
            align: 'center',
          }
        ]}
        rows={functionsList.map(
          ({
            id,
            function_name,
            function_args,
            js_code,
            is_global,
            count="-"
          }) => ({
            id,
            cells: [
              {
                id: 'name-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Tooltip title={function_name} placement="top-start">
                      <Typography variant="body1" className="truncate-1-lines">{function_name}</Typography>
                    </Tooltip>
                  </Stack>
                ),
              },
              {
                id: 'function-args-' + id,
                type: 'component',
                content: (
                  <Tooltip title={function_args} placement="top-start">
                    <Typography variant="" className="truncate-1-lines">
                      {function_args}
                    </Typography>
                  </Tooltip>
                )
              },
              {
                id: 'function-count-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status">
                    {count || ""}
                  </Typography>
                )
              },
              {
                id: 'function-type-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status">
                    {is_global ? "Global" : "Local"}
                  </Typography>
                )
              },
              {
                id: 'actions-' + id,
                type: 'component',
                align: 'center',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem onClick={() => {onAction("edit", {function_name, function_args, js_code, id}); handleClose(id);}}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => {onAction("duplicate", {function_name, function_args, js_code, id}); handleClose(id);}}>
                          Duplicate
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: '50px' },
              }
            ],
          })
        )}
      />
    );
  }

  return (
    <Box sx={{p: 2}}>
      <Box sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
        {getTableContent()}
      </Box>
    </Box>
  )
}

export default FunctionsList;
