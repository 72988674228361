import { useState } from 'react';
import dayjs from 'dayjs';
import {
  Stack,
  Typography,
  Avatar,
  IconButton,
  MenuList,
  MenuItem,
  Box
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import CustomTable from '../Common/CustomTable';
import CustomChip from '../Common/CustomChip';
import CustomMenu from '../Common/CustomMenu';
import LoadingOverlay from '../Common/LoadingOverlay';

import { STATUS_TO_ICON, BUILD_STATUS_TO_COLOR } from '../../config/constants';

const TestPlanSuiteTable = ({
  attachedSuites = [],
  onDetachTestSuiteClick = () => {},
  isLoading = false
}) => {
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  if (isLoading) {
    return (<LoadingOverlay isLoading={isLoading} />);
  }

  if (!attachedSuites.length) {
    return (
      <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
        <h3>No test suite is attached.</h3>
        <h6>Go to test suite's list and select suites to attach with test plan</h6>
      </Box>
    )
  }

  return (
    <CustomTable
      headers={[
        {
          id: 'code',
          type: 'text',
          text: 'Code',
        },
        {
          id: 'title',
          type: 'text',
          text: 'Title',
        },
        {
          id: 'status',
          type: 'text',
          text: 'Status',
        },
        {
          id: 'tags',
          type: 'text',
          text: 'Tags',
        },
        {
          id: 'owner',
          type: 'text',
          text: 'Owner',
        },
        {
          id: 'lastRunResult',
          type: 'text',
          text: 'Last Run Result',
        },
        {
          id: 'actions',
          type: 'text',
          text: 'Actions',
          align: 'center',
        },
      ]}
      rows={attachedSuites.map(
        ({
          id: testSuitePlanId,
          test_suite: { id, code, title, description, status, tags, owner, test_suite_statuses=[] },
        }) => ({
          id,
          cells: [
            {
              id: 'code-' + id,
              type: 'text',
              text: code,
              sx: { width: '100px' },
            },
            {
              id: 'title-' + id,
              type: 'component',
              content: (
                <Stack direction="column" className="title">
                  <Typography variant="body1" className="truncate-1-lines" title={title}>{title}</Typography>
                  <Typography
                    variant="body2"
                    color="gray"
                    className="truncate-1-lines"
                    title={title}
                  >
                    {description}
                  </Typography>
                </Stack>
              ),
              sx: { width: '300px' },
            },
            {
              id: 'status-' + id,
              type: 'component',
              content: (
                <Typography variant="body1" className="status">
                  {STATUS_TO_ICON[status] || ''} {status || ''}
                </Typography>
              ),
              sx: { width: '120px' },
            },
            {
              id: 'tags-' + id,
              type: 'component',
              content: (
                <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips">
                  {(tags || []).map((tag) => (
                    <CustomChip key={tag} label={tag} />
                  ))}
                </Stack>
              ),
              sx: { width: '240px' },
            },
            {
              id: 'owner-' + id,
              type: 'component',
              content: owner ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar
                    alt={owner.name}
                    src={owner.avatar}
                    sx={{ width: 24, height: 24 }}
                  />
                  <Typography variant="body1">{owner.name}</Typography>
                </Stack>
              ) : null,
              sx: { width: '140px' },
            },
            {
              id: 'suite-status-' + id,
              type: 'component',
              content: (
                <Stack useFlexGap direction="row" className="chips lastrun" flexWrap="wrap" spacing={1}>
                  {(test_suite_statuses || []).map(
                    (
                      {
                        environment_set: env,
                        status: buildStatus,
                        last_run_at: lastRunAt,
                      },
                      i
                    ) => (
                      <CustomChip
                        key={env?.id || `build-status-${i}`}
                        className={buildStatus}
                        title={buildStatus}
                        label={
                          <span>
                            {env?.title || '-'}:{' '}
                            <span
                              style={{
                                color:
                                  BUILD_STATUS_TO_COLOR[buildStatus] ||
                                  'black',
                              }}
                            >
                              {lastRunAt
                                ? dayjs(lastRunAt * 1000).fromNow() // multiply timestamp with 1000 to make it js compatible
                                : buildStatus || '-'}
                            </span>
                          </span>
                        }
                      />
                    )
                  )}
                </Stack>
              ),
              sx: { width: '150px' },
            },
            {
              id: 'actions-' + id,
              type: 'component',
              align: 'center',
              content: (
                <>
                  <IconButton
                    id={`actions-btn-${id}`}
                    aria-label="actions"
                    onClick={(e) => handleClick(e, id)}
                  >
                    <MoreVert />
                  </IconButton>

                  <CustomMenu
                    labelledBy={'actions-btn-' + id}
                    anchorEl={anchorEls[id]}
                    onClose={() => handleClose(id)}
                  >
                    <MenuList sx={{ minWidth: '100px' }}>
                      <MenuItem
                        onClick={() => {
                          onDetachTestSuiteClick(testSuitePlanId);
                          handleClose(id);
                        }}
                      >
                        Detach
                      </MenuItem>
                    </MenuList>
                  </CustomMenu>
                </>
              ),
              sx: { width: '50px' },
            },
          ],
        })
      )}
    />
  );
};

export default TestPlanSuiteTable;
