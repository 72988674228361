import React, { useRef, useEffect, useState } from "react";
import { Box, IconButton, Popover } from "@mui/material";
import {
  Close,
  PlayCircleFilledWhiteOutlined,
  ArticleOutlined,
  SearchOffOutlined,
  BuildCircleOutlined,
  DataObject,
} from "@mui/icons-material";
import SVGIcon from "../Common/SVGIcon.jsx";

import "../../styles/components/Common/SideDrawer.scss";

const iconsMap = {
  play: <PlayCircleFilledWhiteOutlined />,
  article: <ArticleOutlined />,
  search: <SearchOffOutlined />,
  build: <BuildCircleOutlined />,
  functions: <DataObject />,
};

const getIcons = (icon) => {
  return iconsMap[icon];
};

const SideDrawer = ({
  selectedItem,
  onChange,
  drawerItems,
  children,
  onClose,
  disabled,
}) => {
  const drawerRef = useRef(null);
  const drawerMenuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick, true);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick, true);
    };
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      setAnchorEl(null);
    }
  }, [selectedItem]);

  const onAnchorElChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(drawerMenuRef.current);
  }

  const handleOutsideClick = (event) => {
    // if (event.target.closest("#side-drawer-popover")) {
    //   event.stopPropagation();
    //   return;
    // }
    try {
      const isAutoCompleteClicked =
        (typeof event.target.className === "string" &&
          event.target.className?.indexOf("MuiAutocomplete-popper") !== -1) ||
        !!event.target.closest(".MuiAutocomplete-popper");
      const isDialogClicked =
        (typeof event.target.className === "string" &&
          event.target.className?.indexOf("MuiDialog-root") !== -1) ||
        !!event.target.closest(".MuiDialog-root");
      const isPopperClicked =
        (typeof event.target.className === "string" &&
          event.target.className?.indexOf("MuiPickersPopper-root") !== -1) ||
        !!event.target.closest(".MuiPickersPopper-root");
      const isPopoverClicked =
        (typeof event.target.className === "string" &&
          event.target.className?.indexOf("side-drawer-popover") !== -1) ||
        !!event.target.closest(".side-drawer-popover");
      const isPaperClicked =
        (typeof event.target.className === "string" &&
          event.target.className?.indexOf("MuiPaper-root") !== -1) ||
        !!event.target.closest(".MuiPaper-root");
      const isBackdropClicked =
        (typeof event.target.className === "string" &&
          event.target.className?.indexOf("MuiBackdrop-root") !== -1) ||
        !!event.target.closest(".MuiBackdrop-root");
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        !isAutoCompleteClicked &&
        !isDialogClicked &&
        !isPopperClicked &&
        !isPopoverClicked && 
        !isPaperClicked &&
        !isBackdropClicked
      ) {
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let drawerContent = null;
  if (selectedItem && children) {
    drawerContent = (
      <Box>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
        <Box
          sx={{
            // maxHeight: "calc(100vh - 100px)",
            // minHeight: "250px",
            height: "calc(100vh - 100px)",
            width: "450px"
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
  return (
    <>
    <Box
      className="sideDrawer"
      ref={drawerRef}
    >
      <Box className="drawerMenuContainer" ref={drawerMenuRef}>
        {drawerItems.map((item) => {
          let onClick;
          if (!item.disabled)
            onClick = (event) => {
              onAnchorElChange(event);
              onChange(item.id);
            };
          return (
            <div
              key={item.id}
              variant="outlined"
              className={`drawerItem${
                selectedItem === item.id ? " selected" : ""
              }`}
              onClick={onClick}
              aria-describedby={"side-drawer-popover"}
            >
              {getIcons(item.icon)}
              <div>{item.title}</div>
            </div>
          );
        })}
      </Box>
    </Box>
    <Popover
      id={"side-drawer-popover"}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{height: "calc(100vh - 110px)", zIndex: 10}}
    >
      {drawerContent}
    </Popover>
  </>
  );
};

export default SideDrawer;
