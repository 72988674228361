import { Menu } from '@mui/material';

const CustomMenu = ({ labelledBy, anchorEl, onClose, children, sx = {}, paperProps={bgcolor: "white", boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px"} }) => {
  return (
    <Menu
      anchorEl={anchorEl ? anchorEl : null}
      open={anchorEl ? Boolean(anchorEl) : false}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': labelledBy,
        sx: {
          p: 0,
        },
      }}
      PaperProps={{
        sx: {
          bgcolor: 'rgb(245 245 245)',
          borderRadius: '16px',
          boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
          outline: 'none',
          ...paperProps
        },
      }}
      sx={sx}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;
