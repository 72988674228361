import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import TestPlan from './TestPlan';
import TestPlansListPage from './TestPlansListPage';

const TestPlanPage = function({project, testPlanId}) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (testPlanId) {
      setCurrentView("testPlan");
    } else {
      setCurrentView("testPlanList");
    }
  }, [testPlanId]);

  let content = null;
  if (currentView === 'testPlanList') {
    content = (<TestPlansListPage project={project} />);
  } else if (currentView === 'testPlan') {
    content = (<TestPlan project={project} testPlanId={testPlanId} />);
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
}

export default TestPlanPage;
