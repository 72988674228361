import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import {Box} from "@mui/material";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import {
  createVariableSet,
  getAllVariableSets,
  deleteVariableSet,
} from '../../redux-store/variableSetReducers/variableSetActions';

import useSearchParams from '../../hooks/useSearchParams';
import MainLayout from '../../layouts/MainLayout';
import VariableSetList from './VariableSetList';
import CustomButton from '../Common/CustomButton';

const VariableSetListPage = ({ project }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');

  const isLoading = useSelector((state) => state.variableSet.isLoading);

  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [variableSetList, setVariableSetList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalVariableSets, setTotalVariableSets] = useState(0);

  useEffect(() => {
    getVariableSetList();
    // keep page query-param in sync with state
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  const getVariableSetList = async () => {
    const variableSetListResponse = await dispatch(
      getAllVariableSets({
        projectId: project.id,
        pageNumber: pageNumber,
        pageSize: 10,
        skipEnvironment: true
      })
    );

    if (variableSetListResponse?.payload) {
      setVariableSetList(variableSetListResponse.payload?.variable_sets || []);
      setTotalPages(variableSetListResponse.payload?.total_pages);
      setTotalVariableSets(variableSetListResponse.payload?.total);
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const onDeleteVariableSet = async (variableSetId) => {
    await dispatch(deleteVariableSet({ variableSetId }));
    openSnackbar({
      message: "Variable set deleted successfully.",
      severity: "success",
    });

    if (variableSetList.length === 1 && pageNumber !== 1) {
      onPageChange('', pageNumber - 1 > 0 ? pageNumber - 1 : 1);
    } else {
      getVariableSetList();
    }
  };

  const createNewVariableSet = async () => {
    const title = `Variable Set ${
      variableSetList ? totalVariableSets + 1 : 1
    }`;
    openSnackbar({
      message: `Variable set ${title} created successfully.`,
      severity: "success",
    });
    const variableSetResponse = await dispatch(
      createVariableSet({ projectId: project.id, title: title })
    );
    history.push(`/variable-set/${variableSetResponse.payload.id}`);
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Variable Sets"
      subtitleText={`Showing ${totalVariableSets || 0} variable set(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <CustomButton startIcon={<Add />} onClick={createNewVariableSet}>
          Create Variable Set
        </CustomButton>
      }
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto"}}>
        <VariableSetList
          variableSetList={variableSetList}
          isLoading={isLoading}
          pageNumber={pageNumber}
          onDelete={onDeleteVariableSet}
        />
      </Box>
    </MainLayout>
  );
};

export default VariableSetListPage;
