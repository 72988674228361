import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import DataSetListPage from './DataSetListPage';
import DataSetDetailPage from './DataSetDetailPage';

const DataSetPage = function({projectId, dataSetId}) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (dataSetId) {
      setCurrentView("dataSetDetail");
    } else {
      setCurrentView("dataSetList");
    }
  }, [dataSetId]);

  let content = null;
  if (currentView === 'dataSetList') {
    content = (<DataSetListPage projectId={projectId} />);
  } else if (currentView === 'dataSetDetail') {
    content = (<DataSetDetailPage projectId={projectId} dataSetId={dataSetId} />);
  }

  return (
    <Box>
      {content}
    </Box>
  )
}

export default DataSetPage;
