import { createSlice } from "@reduxjs/toolkit";

const buildReportSlice = createSlice({
  name: "buildReport",
  initialState: {
    appliedFilters: {
      testScenario: {},
      testSuite: {},
      testPlan: {}
    }
  },
  reducers: {
    saveAppliedFilters: (state, action) => {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          [action.payload.sourceType]: action.payload.appliedFilters ? {...action.payload.appliedFilters} : {}
        },
      };
    }
  }
});

export const {
  saveAppliedFilters
} = buildReportSlice.actions;

export default buildReportSlice.reducer;
