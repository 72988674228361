import { useLocation } from 'react-router-dom';

/**
 * Hook to get search params from URL
 * @returns {URLSearchParams} URLSearchParams instance
 */
const useSearchParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return searchParams;
};

export default useSearchParams;
