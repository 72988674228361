import { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Chip,
  Stack,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { isEqual } from 'lodash';

import CustomCheckbox from './CustomCheckbox';
import CustomMenu from './CustomMenu';

const CustomFilter = ({
  id = '',
  label = '',
  type = 'checkbox',
  icon = <></>,
  searchInputPlaceholder = '',
  options = [],
  additionalOptions = [],
  isAdditionalFilter = true,
  appliedFilters = [],
  appliedFilter = null,
  additionalAppliedFilter = null,
  setAppliedFilters = () => {},
  setAppliedFilter = () => {},
  setAdditionalAppliedFilter = () => {},
}) => {
  const [localAppliedFilters, setLocalAppliedFilters] =
    useState(appliedFilters);
  const [localAppliedFilter, setLocalAppliedFilter] = useState(appliedFilter);
  const [localAdditionalAppliedFilter, setLocalAdditionalAppliedFilter] =
    useState(additionalAppliedFilter);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!isEqual(localAppliedFilters, appliedFilters))
      setLocalAppliedFilters(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    if (localAppliedFilter !== appliedFilter)
      setLocalAppliedFilter(appliedFilter);
  }, [appliedFilter]);

  useEffect(() => {
    if (localAdditionalAppliedFilter !== additionalAppliedFilter)
      setLocalAdditionalAppliedFilter(additionalAppliedFilter);
  }, [additionalAppliedFilter]);

  const searchLower = searchText.toLowerCase();
  const filteredOptions = searchText
    ? options.filter((option) => option.toLowerCase().includes(searchLower))
    : options;

  const hasAppliedFilters =
    appliedFilters.length || (appliedFilter && (isAdditionalFilter ? additionalAppliedFilter : true));
  const filtersUpdated = useMemo(() => {
      if (isAdditionalFilter) {
        return (
          !isEqual(localAppliedFilters, appliedFilters) ||
          (localAppliedFilter && localAdditionalAppliedFilter && 
            (localAppliedFilter !== appliedFilter || localAdditionalAppliedFilter !== additionalAppliedFilter)
          )
        )
      } else {
        return (
          !isEqual(localAppliedFilters, appliedFilters) || (localAppliedFilter && (localAppliedFilter !== appliedFilter))
        )
      }
    },
    [localAppliedFilters, appliedFilters, localAppliedFilter, appliedFilter, localAdditionalAppliedFilter, additionalAppliedFilter]
  );

  const handleClick = (event) =>
    setAnchorEl(
      event.currentTarget.tagName === 'svg'
        ? event.currentTarget.parentNode
        : event.currentTarget
    );

  const handleClose = (syncLocalState = true) => {
    if (syncLocalState) {
      setLocalAppliedFilters(appliedFilters);
      setLocalAppliedFilter(appliedFilter);
      setLocalAdditionalAppliedFilter(additionalAppliedFilter);
    }

    setAnchorEl(null);
  };

  const handleCheckChange = (event, option) => {
    if (event.target.checked) {
      setLocalAppliedFilters([...localAppliedFilters, option]);
    } else {
      setLocalAppliedFilters(
        localAppliedFilters.filter((appliedFilter) => appliedFilter !== option)
      );
    }
  };

  const handleRadioChange = (event) => {
    setLocalAppliedFilter(event.target.value);
  };

  const handleAdditionalRadioChange = (event) => {
    setLocalAdditionalAppliedFilter(event.target.value);
  };

  const resetFilters = () => {
    setAppliedFilters([]);
    setAppliedFilter(null);
    setAdditionalAppliedFilter(null);
  };

  return (
    <>
      <Chip
        id={id}
        label={label}
        icon={icon}
        deleteIcon={<ExpandMore />}
        onClick={handleClick}
        onDelete={handleClick}
        size="small"
        sx={{background: "rgb(229 229 229)", border: hasAppliedFilters ? "1px solid #6241d4" : "1px solid rgb(229 229 229)", borderRadius: "4px", fontWeight: 500, padding: "14px 0"}}
      />

      <CustomMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        labelledBy={id}
        sx={{ mt: 0.5 }}
        paperProps={{bgcolor: "white", boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px", maxHeight: "400px", overflow: "auto"}}
      >
        <Stack spacing={1} style={{ padding: '14px' }}>
          {type === 'checkbox' && searchInputPlaceholder ? (
            <input
              type="text"
              className="search-input"
              placeholder={searchInputPlaceholder}
              disabled={!options.length}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          ) : null}

          {filteredOptions.length ? (
            type === 'checkbox' ? (
              <FormGroup>
                {filteredOptions.map((option) =>
                  typeof option === 'string' ? (
                    <CustomCheckbox
                      key={option}
                      checked={localAppliedFilters.includes(option)}
                      label={option}
                      onChange={(e) => handleCheckChange(e, option)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  ) : (
                    <CustomCheckbox
                      key={option.id}
                      checked={localAppliedFilters.includes(option.id)}
                      label={option.name || option.title || option.email}
                      onChange={(e) => handleCheckChange(e, option.id)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )
                )}
              </FormGroup>
            ) : (
              <RadioGroup
                name="radio-buttons-group-1"
                value={localAppliedFilter}
                onChange={handleRadioChange}
              >
                {filteredOptions.map((option) =>
                  typeof option === 'string' ? (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                      sx={{textTransform: "capitalize"}}
                    />
                  ) : (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.name || option.title}
                      sx={{textTransform: "capitalize"}}
                    />
                  )
                )}
              </RadioGroup>
            )
          ) : (
            <p>No filters found.</p>
          )}

          {additionalOptions?.length && type === 'radio' ? (
            <>
              <Divider sx={{ border: '1px solid black' }} />

              <RadioGroup
                name="radio-buttons-group-2"
                value={localAdditionalAppliedFilter}
                onChange={handleAdditionalRadioChange}
              >
                {additionalOptions.map((option) =>
                  typeof option === 'string' ? (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ) : (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.name || option.title}
                    />
                  )
                )}
              </RadioGroup>
            </>
          ) : null}

          {filtersUpdated && (
            <Button
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                setAppliedFilters(localAppliedFilters);
                setAppliedFilter(localAppliedFilter);
                setAdditionalAppliedFilter(localAdditionalAppliedFilter);
                handleClose(false);
              }}
            >
              Apply
            </Button>
          )}

          {hasAppliedFilters ? (
            <Button
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                resetFilters();
                handleClose();
              }}
            >
              Reset
            </Button>
          ) : null}
        </Stack>
      </CustomMenu>
    </>
  );
};

export default CustomFilter;
