import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingOverlay = ({ isLoading, sx={} }) => {
    if (!isLoading) return null;

    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#e9e9e952',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
            ...sx
        }}>
            <CircularProgress color="inherit" size={ 25 } thickness={ 5 }/>
        </Box>
    );
}

export default LoadingOverlay;
