import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { confirmEmail } from '../../redux-store/currentUserActions';
import { setConfirmationMessage, setConfirmationError } from '../../redux-store/currentUserSlice';
import { Container, Grid, Card, Typography, Link } from '@mui/material';
import './index.css';

const EmailConfirmation = ({confirmationMessage, confirmationError}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      dispatch(confirmEmail(token))
      .then(response => {
        if (response.payload && response.payload.message) {
          dispatch(setConfirmationMessage(response.payload.message));
        } else {
          dispatch(setConfirmationError(response.payload.error));
        }
      })
      .catch(error => {
        if (error.payload && error.payload.error) {
          dispatch(setConfirmationError(error.payload.error));
        } else {
          dispatch(setConfirmationError('Invalid or expired token.'));
        }
      });
    }
  }, [dispatch]);

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="confirmation-card">
            <div className="confirmation-content">
              {confirmationMessage && (
                <>
                  <Typography variant="body1" className="confirmation-message">
                    {confirmationMessage}
                  </Typography>
                  <Link href="/login">Go to Login Page</Link>
                </>
              )}
              {confirmationError && (
                <>
                  <Typography variant="body1" className="confirmation-error">
                    {confirmationError}
                  </Typography>
                  <Link href="/login">Go to Login Page</Link>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

function mapStateToProps(state, ownProps){
  return {
    confirmationMessage: state.user.confirmationMessage,
    confirmationError: state.user.confirmationError
  }
}

export default connect(mapStateToProps)(EmailConfirmation);
