import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabs: [],
  activeTabId: null,
};

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    addTab: (state, action) => {
      const existingIndex = state.tabs.findIndex(tab => tab.id === action.payload.id);
      if (existingIndex === -1) {
        if (state.tabs.length >= 5) {
          state.tabs.shift();
        }
        state.tabs.push(action.payload);
      }
      state.activeTabId = action.payload.id;
    },
    selectTab: (state, action) => {
      const exists = state.tabs.some(tab => tab.id === action.payload);
      if (exists) {
        state.activeTabId = action.payload;
      }
    },    
    removeTab: (state, action) => {
      state.tabs = state.tabs.filter(tab => tab.id !== action.payload);
      if (state.activeTabId === action.payload) {
        state.activeTabId = state.tabs.length > 0 ? state.tabs[0].id : null;
      }
    },
    renameTab: (state, action) => {
      const { id, newName } = action.payload;
      const tab = state.tabs.find(tab => tab.id === id);
      if (tab) {
        tab.name = newName;
      }
    },
    resetTabs: state => {
      state.tabs = [];
      state.activeTabId = null;
    }
  }
});

export const { addTab, selectTab, removeTab, renameTab, resetTabs } = tabsSlice.actions;

export default tabsSlice.reducer;
