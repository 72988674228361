import React, { useState, useEffect } from "react";
import CustomButton from "../Common/CustomButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { attachScenariosWithSuites } from "../../redux-store/testSuiteScenarioReducers/testSuiteScenarioActions";
import { getAllTestSuites } from "../../redux-store/testSuiteReducers/testSuiteActions";
import LoadingOverlay from "../Common/LoadingOverlay";

const AttachTestSuiteModal = ({ projectId, open, onClose, onCancel }) => {
  const dispatch = useDispatch();
  const selectedTestScenarios = useSelector(
    (state) => state.testScenario.selectedTestScenarios
  );
  const allTestSuites = useSelector((state) => state.testSuite.testSuitesList);
  const isLoading = useSelector((state) => state.testSuite.isLoading);
  const [errors, setErrors] = useState({});
  const [testSuite, setTestSuite] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchAllTestSuites();
    validate(["scenario"]);
  }, []);

  const fetchAllTestSuites = async () => {
    await dispatch(getAllTestSuites({projectId: projectId, pageNumber: 1, pageSize: 100000}));
  }

  const onTestSuiteChange = (event, testSuite) => {
    setTestSuite((prevState) => testSuite);
  };

  const handleCancel = () => {
    setTestSuite(null);
    setErrors({});
    onCancel?.();
  };

  const validate = (tests = ["scenario", "suite"]) => {
    let isValid = true;
    if (
      (!selectedTestScenarios || selectedTestScenarios?.length === 0) &&
      tests.indexOf("scenario") !== -1
    ) {
      setErrors({ scenario: "Please select atleast one test scenario." });
      isValid = false;
    }
    if (!testSuite?.id && tests.indexOf("suite") !== -1) {
      setErrors({ suite: "Invalid test suite." });
      isValid = false;
    }
    return isValid;
  };

  const onAddConfirm = async () => {
    setIsSaving(true);
    if (!validate()) {
      setIsSaving(false);
      return;
    }
    await dispatch(
      attachScenariosWithSuites({
        testSuiteIds: [testSuite?.id],
        testScenarioIds: [...selectedTestScenarios.map((test) => test.id)],
      })
    );
    setTestSuite(null);
    setErrors({});
    setIsSaving(false);
    onCancel?.();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attach-test-suite-dialog-title"
    >
      <DialogTitle id="attach-test-suite-dialog-title" sx={{ fontSize: 20 }}>
        Add to existing test suite
      </DialogTitle>
      <DialogContent
        sx={{
          width: "500px",
          height: "auto",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <span style={{ fontSize: "14px" }} className="text_caption_error">
          {errors.suite || errors.scenario}
        </span>
        <h5 style={{ marginTop: 16 }}>Select Test Suite</h5>
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <Autocomplete
            options={allTestSuites}
            getOptionLabel={(option) => option.title}
            onChange={onTestSuiteChange}
            value={testSuite}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ mt: 1 }}
                size="small"
                variant="outlined"
              />
            )}
          />
        )}
        <h5 style={{ marginTop: 32, marginBottom: 8 }}>
          Selected Test Sceanrios
        </h5>
        {selectedTestScenarios.map((test) => (
          <Chip
            key={test.id}
            id={test.id}
            label={test.title}
            size="small"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {!selectedTestScenarios?.length && <h6>None selected</h6>}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <CustomButton
          onClick={onAddConfirm}
          color="primary"
          disabled={Boolean(!testSuite?.id) || !!errors.scenario}
        >
          Add test scenarios to test suite
        </CustomButton>
        <CustomButton
          onClick={handleCancel}
          color="secondary"
          disabled={isSaving}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AttachTestSuiteModal;
