import React, { useState, useRef, useEffect } from "react";
import { Popover, Button } from "@mui/material";
import { ScheduleSend } from "@mui/icons-material";

import "../../../styles/components/Common/FloatingMenu/FloatingMenu.scss";

const FloatingMenu = ({ icon, renderMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const id = `floating-menu-popover-${Date.now()}`;
  const buttonRef = useRef(null);

  useEffect(() => {
    // if (buttonRef.current) {
    //   setAnchorEl(buttonRef.current);
    // }
    // buttonRef.current.click();
  }, []);

  const handleClick = (event) => {
    if (event.currentTarget.tagName === "svg") {
      setAnchorEl(event.currentTarget.parentNode);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="floatingMenu">
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: {boxShadow: "0px 5px 5px -3px rgba(98, 65, 212, 0.2), 0px 8px 10px 1px rgba(98, 65, 212, 0.14), 0px 3px 14px 2px rgba(98, 65, 212, 0.12);"}
          }
        }}
      >
        {renderMenu({ onClose: handleClose })}
      </Popover>
      <Button
        ref={buttonRef}
        className="floatingButton"
        title="Create Task"
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {icon ? icon : <ScheduleSend size="large" />}
      </Button>
    </div>
  );
};

export default FloatingMenu;
