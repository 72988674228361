import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { connect, useDispatch } from 'react-redux';
import { closeGlobalErrorSnackbar } from 'redux-store/globalErrorSnackbarActions'; 

const GlobalErrorSnackbar = ({ message, severity, open, verticalPos, horizontalPos }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeGlobalErrorSnackbar()); 
  };

  if (!message) return null;

  return (
    <Snackbar
      open={open} 
      autoHideDuration={5000} 
      onClose={handleClose} 
      anchorOrigin={{ vertical: verticalPos, horizontal: horizontalPos }}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({
  message: state.globalErrorSnackbar.message,
  severity: state.globalErrorSnackbar.severity,
  open: Boolean(state.globalErrorSnackbar.message), 
  verticalPos: state.globalErrorSnackbar.verticalPos || 'top', 
  horizontalPos: state.globalErrorSnackbar.horizontalPos || 'right', 
});

export default connect(mapStateToProps)(GlobalErrorSnackbar);
