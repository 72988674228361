import React, { useReducer, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { updateLoadError } from "../../redux-store/currentUserSlice";
import { useSelector, useDispatch } from "react-redux";
import { Signup, GoogleSignup } from "../../redux-store/currentUserActions";
import { GoogleLogin } from "@react-oauth/google";
import "../../styles/App.scss"
import "./index.css";
import { InputAdornment } from '@mui/material';
import { Typography, Box, Button, IconButton, Container, Grid, Card, InputLabel, TextField, FormControlLabel, Checkbox, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Visibility, VisibilityOff, FiberManualRecord as FiberManualRecordIcon } from "@mui/icons-material";
import logo from "../../assets/logos/SignupLogo.png";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const SignUpForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationToken = queryParams.get('token');
  const { openSnackbar } = useSnackbar();

  const [state, setState] = useReducer(reducer, {
    email: "",
    password: "",
    token: "",
    isLoading: false,
    errors: {},
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    const redirectPath = urlParams.get('redirect');

    if (tokenFromUrl) {
      setState({
        type: "SET_STATE",
        payload: { token: tokenFromUrl },
      });
    }

    if (redirectPath) {
      setState({
        type: "SET_STATE",
        payload: { redirectPath: decodeURIComponent(redirectPath) },
      });
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const responseMessage = async (response) => {
    const res = await dispatch(GoogleSignup({ credential: response.credential, invitationToken: invitationToken }));

    if (res.payload.message === 'Signed up successfully' || res.payload.message === 'Logged in successfully') {
      if (res.payload.has_organisation_or_project) history.push("/dashboard");
      else history.push("/home");
    }
  };

  const errorMessage = (error) => {
    dispatch(updateLoadError(error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!state.email || !/\S+@\S+\.\S+/.test(state.email)) {
      errors["email"] = "Please enter a valid email.";
    }
    if (!state.username || state.username.length < 3) {
      errors["username"] = "Username must be at least 3 characters long.";
    }
    if (!state.password || state.password.length < 6) {
      errors["password"] = "Password must be at least 6 characters long.";
    }
    return errors;
  };

  const handleSubmit = async () => {
    const payload = {
      ...state,
      ...({token: state.token ? state.token : null})
    };

    const res = await dispatch(Signup(payload));

    if (res.payload && (res.payload.message === 'User created successfully' || res.payload.message === 'User added to the project successfully')) {
      // Check if invitation token is present in the url, no need to show confirmation email sent message
      if (!state.token) {
        localStorage.setItem('showEmailSentMessage', 'true');
      }

      if (state.redirectPath) {
        const pathToRedirect = state.redirectPath;

        setState({
          type: "SET_STATE",
          payload: { redirectPath: '' },
        });

        history.push(pathToRedirect);
      } else {
        history.push("/login");
      }
    } else {
      const errorMessage = res.payload ? (res.payload.error ? res.payload.error : res.payload.errors?.join(', ') ) : "Sign up failed. Please try again.";
      setState({
        type: "SET_STATE",
        payload: {
          isLoading: false,
          loginError: errorMessage,
        },
      });

      openSnackbar({ message: errorMessage, severity: 'error' });
    }
  };

  const onFormSubmit = async () => {
    const errors = validateForm();
    setState({
      type: "SET_STATE",
      payload: { errors },
    });

    if (Object.keys(errors).length === 0) {
      await handleSubmit();
    } else {
      setState({
        type: "SET_STATE",
        payload: {
          isLoading: false,
          loginError: "Please correct the errors before proceeding.",
        },
      });
    }
  };

  const _errors = useSelector((state) => state.user.errors);
  var email_error = "";
  var username_error = "";

  if (
    _errors &&
    _errors !== "Unvalidated User" &&
    Object.keys(_errors).length > 0
  ) {
    _errors?.forEach((err) => {
      if (err.split(" ")[0] === "Email") {
        email_error = err;
      }
      if (err.split(" ")[0] === "username") {
        username_error = err;
      }
    });
  }

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="grid-card">
            <div className="left-column">
              <Box className="fw-bold mb-5 center primary-color centerContent" style={{ fontSize: 29 }}>
                Why Sign up?
              </Box>
              <Grid container spacing={2} className='centerContent' style={{ marginTop: 29 }}>
                <Grid item xs={12}>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Achieve 60% Automation Coverage in a Day" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Save Time with Always Up-to-Date API Docs" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Cut 90% of Manual Effort in API Testing" />
                    </ListItem>
                </List>
                </Grid>
                <Grid item xs={12} className="centerContent">
                  <img className='responsive-image' src={logo} alt="Logo" />
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="rounded-corners grid-card">
            <div className="right-column">
              <Box className="heading-container">
                <Box className="create-account-title">
                  Create Qodex Account
                </Box>
                <Box className="signin-link-container">
                  or
                  <a href="/login" className="signin-link">
                    Sign In
                  </a>
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="username" className="input-label">Name</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="username"
                  value={state.username}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["username"] || username_error)}
                  helperText={state.errors["username"] || username_error || ' '}
                />

                <InputLabel htmlFor="email" className="input-label">Email</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="email"
                  value={state.email}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["email"] || email_error)}
                  helperText={state.errors["email"] || email_error || ' '}
                />

                <InputLabel htmlFor="password" className="input-label">Password</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={state.password}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["password"])}
                  helperText={state.errors["password"] || ' '}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className="mb-2 mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="updatesOptIn"
                      color="primary"
                      checked={state.updatesOptIn}
                      // onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography className='text-color'>
                      Sign up to get product updates, news, and marketing communications.
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="staySignedIn"
                      color="primary"
                      checked={state.staySignedIn}
                      // onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography className='text-color'>
                      Stay signed in for 30 days.
                    </Typography>
                  }
                />
              </Box>
              <div className="btn_login">
                <Button variant="contained" onClick={onFormSubmit} size="medium" style={{ padding: '10px 0', width: '100%' }}>
                  Create Account
                </Button>
              </div>
              <Box className="mb-4 mt-4 center">
                By creating an account, I agree to the{" "}
                <a href="https://www.qodex.ai/termsandconditions" className="terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
              </Box>
              <Box className="line-container primary-color mb-4">
                <span className="cutting-line"></span>
                <Typography className="overlay-text">or</Typography>
                <span className="cutting-line"></span>
              </Box>
              <div style={{ width: "100%" }}>
                <GoogleLogin
                  buttonText="Sign up with Google"
                  onSuccess={responseMessage}
                  onError={errorMessage}
                  shape="rectangular"
                  style={{ width: "100%", color: "rgb(0, 68, 239)" }}
                />
              </div>
              <div style={{ marginTop: "8px" }}></div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignUpForm;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
