import { Button as MaterialButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextButton = styled(MaterialButton)({
  boxShadow: 'none',
  fontFamily: 'Inter, Sans-serif',
  textTransform: 'none',
  fontSize: '12px',
  backgroundColor: 'none',
  borderColor: 'none',
  color: '#6241D4',
  fontWeight: 500,
  outline: 'none',
  '&:hover': {
    fontWeight: 600,
    borderColor: 'none',
    boxShadow: 'none',
    background: 'none'
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.99,
    color: '#4d4d4d'
  }
});

const Button = styled(MaterialButton)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '12px',
  backgroundColor: 'rgb(229 229 229)',
  borderColor: 'rgb(229 229 229)',
  color: 'black',
  fontWeight: 'normal',
  '&:hover': {
    backgroundColor: 'rgb(229 229 229)',
    borderColor: 'rgb(229 229 229)',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

const DeleteButton = styled(MaterialButton)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '12px',
  backgroundColor: 'rgb(211, 47, 47)',
  borderColor: 'rgb(211, 47, 47)',
  borderRadius: '8px',
  color: 'white',
  fontWeight: 'normal',
  padding: '4px 14px',
  '&:hover': {
    backgroundColor: 'rgb(211, 47, 47)',
    borderColor: 'rgb(211, 47, 47)',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

const SecondaryButton = styled(MaterialButton)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '12px',
  backgroundColor: 'white',
  borderColor: 'rgb(163 163 163)',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '8px',
  color: 'black',
  fontWeight: 'normal',
  padding: '4px 14px',
  '&:hover': {
    backgroundColor: 'white',
    borderColor: 'rgb(163 163 163)',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

const CustomButton = (props) => {
  return <Button {...props} />;
};

const LabelButton = (props) => {
  return <TextButton {...props} />
}

export { DeleteButton, SecondaryButton, LabelButton };
export default CustomButton;
