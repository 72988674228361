import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Typography, Box, TextField, Button, Grid, Link } from '@mui/material';

import { updateOrganisation, getOrganisation, invalidateCache } from '../../redux-store/currentUserActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import CustomTable from 'components/Common/CustomTable';
import LoadingOverlay from 'components/Common/LoadingOverlay';

const HostConfig = ({ organisation, project }) => {
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [host, setHost] = useState(organisation?.host);
  const [isLoading, setIsLoading] = useState(false);
  const initialMount = useRef(true);

  useEffect(() => {
    if (organisation !== null && initialMount.current) {
      initialMount.current = false;
      fetchOrganisation();
    }
    if (!host && organisation?.host) {
      setHost(organisation.host);
    }
  }, [organisation]);

  const fetchOrganisation = async () => {
    setIsLoading(true);
    await dispatch(getOrganisation({ id: organisation.id }));
    setIsLoading(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await dispatch(updateOrganisation({
      host: host,
      id: organisation.id
    }));
    if (response?.payload && !response?.error) {
      if (response?.payload?.organisation) {
        openSnackbar({ message: "Host updated successfully!", severity: 'success' });
      } else {
        openSnackbar({ message: "Something went wrong, please try again!", severity: 'error' });
      }
    } else {
      openSnackbar({ message: response?.payload?.error || "Project updation failed!", severity: 'error' });
    }
    setIsLoading(false);
  };

  const onClearCache = async () => {
    try {
      setIsLoading(true); 
      const response = await dispatch(invalidateCache({ orgId: organisation.id }));

      if (response?.payload?.success === true) {
        openSnackbar({ message: "Cache cleared successfully!", severity: 'success' });
      } else {
        openSnackbar({ message: response?.payload?.error || "Failed to clear cache!", severity: 'error' });
      }
    } catch (error) {
      openSnackbar({ message: "An error occurred while clearing cache!", severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const addButtons = (
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClearCache}
          disabled={isLoading}
        >
          Clear Cache
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isLoading}
        >
          Save
        </Button>
      </Box>
    </Grid>
  );

  const renderTable = () => {
    if (!organisation?.cloudfront_metadata?.dns_records) {
      return null;
    }
    return (
      <CustomTable
        toolbarSx={{ padding: "0px !important" }}
        headers={[
          {
            id: 'host',
            type: 'text',
            text: 'Host',
            sx: { width: '400px', padding: "18px 20px" },
          },
          {
            id: 'type',
            type: 'text',
            text: 'Type',
            sx: { width: '100px', padding: "18px 20px" }
          },
          {
            id: 'value',
            type: 'text',
            text: 'Value',
            sx: { width: 'calc(100% - 500px)', padding: "18px 20px" }
          }
        ]}
        rows={organisation.cloudfront_metadata.dns_records?.map(
          ({
            host,
            type,
            value
          }, index) => ({
            id: host + "-" + value,
            cells: [
              {
                id: "host-" + host + index,
                type: 'text',
                text: host,
                sx: { width: '400px', cursor: 'pointer', padding: "18px 20px" }
              },
              {
                id: "type-" + type + index,
                type: 'text',
                text: type,
                sx: { width: '100px', cursor: 'pointer', padding: "18px 20px" }
              },
              {
                id: "value-" + value + index,
                type: 'text',
                text: value,
                sx: { width: 'calc(100% - 500px)', cursor: 'pointer', padding: "18px 20px" }
              }
            ],
          })
        )}
      />
    );
  };

  const renderDefaultHosts = () => {
    const defaultHosts = organisation?.default_hosts || [];
    // Check if there are any hosts
    if (defaultHosts.length === 0) return null;
  
    return (
      <Box>
        {defaultHosts.map((host, index) => (
          <span key={index}>
            <Link href={host} target="_blank" sx={{ cursor: "pointer" }}>
              {host}
            </Link>
            {index < defaultHosts.length - 1 && ', '} {/* Add a comma if not the last item */}
          </span>
        ))}
      </Box>
    );
  };
  

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{ fontFamily: "Inter, sans-serif", mb: 2, fontWeight: 600 }}
          >
            Default Hosts
          </Typography>
          {renderDefaultHosts()}
          
          <Typography
            sx={{ fontFamily: "Inter, sans-serif", mb: 2, fontWeight: 600, mt: 3 }}
          >
            Custom Host
          </Typography>
          <span>https://</span>
          <TextField
            sx={{ fontFamily: "Inter, sans-serif", width: "calc(100% - 60px)", display: "inline-block", verticalAlign: "top", mt: "-8px", ml: "8px" }}
            fullWidth
            value={host}
            size="small"
            onChange={(e) => setHost(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          {renderTable()}
        </Grid>
        {addButtons}
      </Grid>
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  const organisation = state.common.selectedOrganisation;
  return {
    ...ownProps,
    organisation: organisation
  };
}
export default connect(mapStateToProps)(HostConfig);
