import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  Box,
  Stack,
  Typography,
  Avatar,
  IconButton,
  MenuItem,
  MenuList
} from '@mui/material';
import {
  Inventory2Outlined,
  MoreVert
} from '@mui/icons-material';

import { createBuild } from '../../redux-store/testScenarioBuildReducers/testScenarioBuildActions';
import { setSelectedTestScenarios } from "../../redux-store/testScenarioReducers/testScenarioSlice";
import LoadingOverlay from '../Common/LoadingOverlay';
import CustomChip from '../Common/CustomChip';
import CustomCheckbox from '../Common/CustomCheckbox';
import CustomDialog from '../Common/CustomDialog';
import CustomTable from '../Common/CustomTable';
import CustomMenu from '../Common/CustomMenu';
import DeleteDialog from '../Common/DeleteDialog';
import { STATUS_TO_ICON, BUILD_STATUS_TO_COLOR } from '../../config/constants';

const TestScenariosList = function ({ testScenarios, onDelete, onArchive }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.testScenario.isLoading);
  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );

  const [checkedTestScenarios, setCheckedTestScenarios] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTestScenarioId, setDeleteTestScenarioId] = useState('');
  const [buildTestId, setBuildTestId] = useState('');
  const [anchorEls, setAnchorEls] = useState({});

  const allChecked = testScenarios.filter((test) => test.status === "active").length === checkedTestScenarios.length;
  const buildDialogOpen = !!buildTestId;

  useEffect(() => {
    updateCheckedTestScenariosIds([]);
  }, [testScenarios]);

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const handleAllCheckboxChange = () => {
    if (allChecked) {
      updateCheckedTestScenariosIds([]);
    } else {
      updateCheckedTestScenariosIds(testScenarios.filter((test) => test.status === "active").map((test) => test.id));
    }
  };

  const handleCheckboxChange = (e, tId) => {
    if (e.target.checked) {
      updateCheckedTestScenariosIds([...checkedTestScenarios, tId]);
    } else {
      updateCheckedTestScenariosIds(checkedTestScenarios.filter((id) => id !== tId));
    }
  };

  const updateCheckedTestScenariosIds = async (scenarioIds) => {
    setCheckedTestScenarios(scenarioIds);
    const selectedTestScenarios = testScenarios
      .filter((test) => scenarioIds.indexOf(test.id) !== -1)
      .map((test) => ({
        title: test.title,
        id: test.id,
      }));
    await dispatch(setSelectedTestScenarios([...selectedTestScenarios]));
  };

  const handleBuildDialogClose = () => setBuildTestId('');

  const onClick = (e, id) => {
    history.push(`/test-scenarios/${id}`);
  };

  const onBuild = async (envId) => {
    const buildResponse = await dispatch(
      createBuild({ testScenarioId: buildTestId, environmentSetId: envId })
    );

    history.push(
      `/test-scenarios/${buildTestId}/build/${buildResponse.payload.id}`
    );
  };

  const onViewBuilds = async (id) => {
    history.push(`/test-scenarios/${id}/build/`);
  };

  const onClickDelete = (testScenarioId) => {
    setDeleteTestScenarioId(testScenarioId);
    onToggleDeleteDialog();
  };

  const onDeleteConfirm = () => {
    onDelete(deleteTestScenarioId);
    onToggleDeleteDialog();
  };

  const onArchiveConfirm = () => {
    onArchive(deleteTestScenarioId);
    onToggleDeleteDialog();
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deleteTestScenarioId) setDeleteTestScenarioId('');
  };

  const renderConfirmDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        title="Delete Test Scenario? Archive instead!"
        deleteBtnLabel="Delete Permanently"
        secondaryBtnLabel="Archive"
        secondaryBtnIcon={<Inventory2Outlined />}
        onDeleteAction={onDeleteConfirm}
        onSecondaryAction={onArchiveConfirm}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          We recommend archiving Test Scenarios instead of deleting.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Archiving disables the Test Scenario from all Test Suites & Builds,
          but you can always access it in ‘Archived’ Test Scenarios for later
          reference.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Are you sure you want to permanently delete this Test Scenario?
        </Typography>
      </DeleteDialog>
    );
  };

  const renderTable = () => {
    if (testScenarios.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'checkbox',
            type: 'component',
            content: (
              <CustomCheckbox
                checked={allChecked}
                onChange={handleAllCheckboxChange}
                disabled={testScenarios?.filter((scenario) => scenario.status === "active").length === 0}
              />
            ),
          },
          {
            id: 'code',
            type: 'text',
            text: 'Code',
          },
          {
            id: 'title',
            type: 'text',
            text: 'Title',
          },
          {
            id: 'status',
            type: 'text',
            text: 'Status',
          },
          {
            id: 'tags',
            type: 'text',
            text: 'Tags',
          },
          {
            id: 'owner',
            type: 'text',
            text: 'Owner',
          },
          {
            id: 'lastRunResult',
            type: 'text',
            text: 'Last Run Result',
          },
          {
            id: 'actions',
            type: 'text',
            text: 'Actions',
            align: 'center',
          },
        ]}
        rows={testScenarios.map(
          ({
            id,
            code,
            title,
            description,
            status,
            tags,
            owner,
            test_scenario_statuses,
          }) => ({
            id,
            cells: [
              {
                id: 'checkbox-' + id,
                type: 'component',
                content: (
                  <CustomCheckbox
                    checked={checkedTestScenarios.includes(id)}
                    onChange={(e) => {
                      handleCheckboxChange(e, id);
                    }}
                    disabled={status !== 'active'}
                  />
                ),
                sx: { width: '50px' },
              },
              {
                id: 'code-' + id,
                type: 'text',
                text: code,
                sx: { width: '100px' },
              },
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Typography variant="body1" className="truncate-1-lines" title={title}>{title}</Typography>
                    <Typography
                      variant="body2"
                      color="gray"
                      className="truncate-1-lines"
                      title={description}
                    >
                      {description}
                    </Typography>
                  </Stack>
                ),
                sx: { cursor: 'pointer', width: '300px' },
                onClick: (e) => {
                  onClick(e, id);
                },
              },
              {
                id: 'status-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status">
                    {STATUS_TO_ICON[status] || ''} {status || ''}
                  </Typography>
                ),
                sx: { width: '100px' },
              },
              {
                id: 'tags-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips">
                    {(tags || []).map((tag) => (
                      <CustomChip key={tag} label={tag} />
                    ))}
                  </Stack>
                ),
                sx: { width: '240px' },
              },
              {
                id: 'owner-' + id,
                type: 'component',
                content: owner ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      alt={owner.name}
                      src={owner.avatar}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1" className="truncate-2-lines">{owner.name}</Typography>
                  </Stack>
                ) : null,
                sx: { width: '140px' },
              },
              {
                id: 'scenario-status-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" className="chips lastrun" flexWrap="wrap" spacing={1}>
                    {(test_scenario_statuses || []).map(
                      (
                        {
                          environment_set: env,
                          status: buildStatus,
                          last_run_at: lastRunAt,
                        },
                        i
                      ) => (
                        <CustomChip
                          key={env?.id || `build-status-${i}`}
                          className={buildStatus}
                          title={buildStatus}
                          label={
                            <span>
                              {env?.title || '-'}:{' '}
                              <span
                                style={{
                                  color:
                                    BUILD_STATUS_TO_COLOR[buildStatus] ||
                                    'black',
                                }}
                              >
                                {lastRunAt
                                  ? dayjs(lastRunAt * 1000).fromNow() // multiply timestamp with 1000 to make it js compatible
                                  : buildStatus || '-'}
                              </span>
                            </span>
                          }
                        />
                      )
                    )}
                  </Stack>
                ),
                sx: { width: '150px' },
              },
              {
                id: 'actions-' + id,
                type: 'component',
                align: 'center',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem onClick={() => {setBuildTestId(id); handleClose(id);}}>
                          Run
                        </MenuItem>
                        <MenuItem onClick={() => {onViewBuilds(id); handleClose(id);}}>
                          All builds
                        </MenuItem>
                        <MenuItem onClick={() => {onClickDelete(id); handleClose(id);}}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: '50px' },
              },
            ],
          })
        )}
      />
    );
  };

  return (
    <>
      <Box>
        <LoadingOverlay isLoading={isLoading} />
        {!isLoading && renderTable()}
      </Box>
      {deleteDialogOpen && renderConfirmDialog()}

      <CustomDialog
        open={buildDialogOpen}
        title="Select environment"
        btnText="Run"
        onClose={handleBuildDialogClose}
        options={environmentSets || []}
        handleSubmit={(env) => onBuild(env)}
      />
    </>
  );
};

export default TestScenariosList;
