import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllBuilds } from "../../redux-store/testSuiteBuildReducers/testSuiteBuildActions";

import { Box, Grid } from "@mui/material";
import CustomButton from "../Common/CustomButton";
import LoadingOverlay from "../Common/LoadingOverlay";
import BuildCard from "../Common/BuildCard";
import { Visibility } from "@mui/icons-material";

const TestSuiteBuildsDrawer = ({ testSuiteId }) => {
  const dispatch = useDispatch();
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [testSuiteBuildList, setTestSuiteBuildList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const isLoading = useSelector((state) => state.testSuiteBuild.isLoading);
  const [expandedId, setExpandedId] = useState("");
  const isInitial = useRef(true);

  useEffect(() => {
    getTestSuiteBuildList(pageNumber, pageSize);
  }, []);

  const getTestSuiteBuildList = async (pageNumber, pageSize) => {
    const response = await dispatch(
      getAllBuilds({
        testSuiteId: testSuiteId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      })
    );
    if (response?.payload) {
      setTestSuiteBuildList((prevState) => ([
        ...prevState,
        ...response.payload?.test_suite_builds,
      ]));
      setTotalPages(response.payload?.total_pages);
    }
    isInitial.current = false;
  };

  const onLoadMore = () => {
    setPageNumber((prevState) => prevState + 1);
    getTestSuiteBuildList(pageNumber + 1, pageSize);
  };

  const onExpand = (buildId) => {
    if (buildId === expandedId) {
      setExpandedId("");
    } else {
      setExpandedId(buildId);
    }
  };

  const onViewBuild = (id) => {
    window.open(
      `${window.location.origin}/test-suites/${testSuiteId}/build/${id}`,
      "_blank"
    );
  };

  if (isLoading && isInitial.current) {
    return (<LoadingOverlay isLoading={isLoading} sx={{width: "450px"}} />);
  }

  const renderBuilds = () => {
    return testSuiteBuildList.map((build) => (
      <BuildCard
        key={build.id}
        testSuiteId={testSuiteId}
        build={build}
        isExpanded={expandedId === build.id}
        onExpand={() => {
          onExpand(build.id);
        }}
        onViewBuild={onViewBuild}
        buildType="testSuite"
      />
    ));
  };

  return (
    <Box sx={{p: 2}}>
      <Grid container alignItems="center" sx={{mb: 2}}>
        <h4 style={{ marginLeft: "16px" }}>
          All builds({testSuiteBuildList.length})
        </h4>
        <CustomButton
          sx={{ mr: 2, ml: "auto" }}
          variant="outlined"
          color="primary"
          disabled={false}
          onClick={() => {
            window.open(
              `${window.location.origin}/test-suites/${testSuiteId}/build/?page=1`,
              "_blank"
            );
          }}
        >
          <Visibility sx={{ mr: 1 }} />
          View all builds
        </CustomButton>
      </Grid>
      {renderBuilds()}
      <div style={{ width: "100%", textAlign: "center" }}>
        {pageNumber < totalPages && !isLoading && (
          <CustomButton sx={{ my: 2 }} onClick={onLoadMore}>
            Load more
          </CustomButton>
        )}
      </div>
    </Box>
  );
};

export default TestSuiteBuildsDrawer;
