import { Divider, TextField, Box, Grid, Typography, Select, MenuItem, FormControlLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResourceSummary } from "../../redux-store/currentUserActions";
import AuthTypeText from "./AuthTypeText";
import { ExpandMore } from "@mui/icons-material";

const AWSAuthorization = ({ selectedType }) => {
  const selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();
  const [authType, setAuthType] = useState('Request Headers');
  const [accessKey, setAccessKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [awsRegion, setAwsRegion] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [sessionToken, setSessionToken] = useState('');

  useEffect(() => {
    if (selectedSummary?.authorization_value) {
      setAuthType(selectedSummary?.authorization_value?.authType);
      setAccessKey(selectedSummary?.authorization_value?.accessKey);
      setSecretKey(selectedSummary?.authorization_value?.secretKey);
      setAwsRegion(selectedSummary?.authorization_value?.awsRegion);
      setServiceName(selectedSummary?.authorization_value?.serviceName);
      setSessionToken(selectedSummary?.authorization_value?.sessionToken);
    }
  }, [selectedSummary]);

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'authType':
        setAuthType(value);
        break;
      case 'accessKey':
        setAccessKey(value);
        break;
      case 'secretKey':
        setSecretKey(value);
        break;
      case 'awsRegion':
        setAwsRegion(value);
        break;
      case 'serviceName':
        setServiceName(value);
        break;
      default:
        setSessionToken(value);
    }
  };

  const handleApiCall = async () => {
    const state = {
      id: selectedSummary?.id,
      summary: {
        authorization_type: selectedType,
        authorization_value: {
          authType: authType,
          accessKey: accessKey,
          secretKey: secretKey,
          awsRegion: awsRegion,
          serviceName: serviceName,
          sessionToken: sessionToken
        }
      }
    };
    const res = await dispatch(updateResourceSummary(state));
    if (res.payload.summary) {
      setAuthType(res.payload.summary.authorization_value.authType);
      setAccessKey(res.payload.summary.authorization_value.accessKey);
      setSecretKey(res.payload.summary.authorization_value.secretKey);
      setAwsRegion(res.payload.summary.authorization_value.awsRegion);
      setServiceName(res.payload.summary.authorization_value.serviceName);
      setSessionToken(res.payload.summary.authorization_value.sessionToken);
    }
  };

  return (
    <Box p={2}>
      <AuthTypeText />
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body">Add Auth data to:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Select
            size="small"
            variant="outlined"
            value={authType}
            onChange={(e) => handleInputChange('authType', e.target.value)}
            style={{ width: '40%' }}
          >
            <MenuItem value={"Request Headers"}>Request Headers</MenuItem>
            <MenuItem value={"Request Url"}>Request Url</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Access Key:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={accessKey}
            onChange={(e) => handleInputChange('accessKey', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Secret Key:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={secretKey}
            onChange={(e) => handleInputChange('secretKey', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">AWS Region:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={awsRegion}
            onChange={(e) => handleInputChange('awsRegion', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Service Name:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={serviceName}
            onChange={(e) => handleInputChange('serviceName', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body">Session Token:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={sessionToken}
            onChange={(e) => handleInputChange('sessionToken', e.target.value)}
            onBlur={handleApiCall}
            style={{ width: '40%' }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<ExpandMore />}
            label={<Typography variant="body2" color="grey">ADVANCED</Typography>}
          />
          <Typography variant="body2" color="grey">
            These are advanced configuration options. They are optional. Qodex will auto generate values for some fields if left blank.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AWSAuthorization;
