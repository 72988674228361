import { useState } from "react";
import { InputBox } from "../../utils/input_utils/InputBox";
import PrimaryButton from "../../utils/cta_utils/button";
import ToggleSwitchComponent from "../Collections/ToggleSwitchComponent";
import { Box, Typography } from '@mui/material';

const SettingPageComponent = ({ state }) => {
  const [selectedType, setSelectedType] = useState(" ");
  const [enteredToken, setEnteredToken] = useState("");

  const authTypes = [
    "Bearer Token",
    "No Auth",
    "Api Key",
    "JWT Bearer",
    "Basic Auth",
    "OAuth 1.0",
    "OAuth 2.0",
    "AWS Signature",
  ];

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleInputChange = (e) => {
    setEnteredToken(e.target.value);
  };

  return (
    <> 
      <Box style={{ marginLeft: '10px', marginRight: '10px' }}>
        <ToggleSwitchComponent
          labelHead={"Enable SSL certificate verification"}
          label={
            "Verify SSL certificates hen sending a request. Verification failures will result in the request being aborted."
          }
          showsettings={true}
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Automatically follow redirects"}
          label={"Follow HTTP 3xx responses as redirects."}
          showsettings={true}
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Follow original HTTP Method"}
          label={
            "Redirect with the original HTTP method instead of the default behaviour of redirecting with GET."
          }
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Follow Authorization header"}
          label={
            "Retain authorization header when a redirect happens to a different hostname."
          }
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Remove referrer header on redirect"}
          label={"Remove the referrer header when a redirect happens."}
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Remove referrer header on redirect"}
          label={"Remove the referrer header when a redirect happens."}
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Enable strict HTTP parser"}
          label={"Restrict responses with invalid HTTP headers."}
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Encode URL automatically"}
          label={
            "Encode the URL's path, query parameters, and authentication fields."
          }
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Disable cookie jar"}
          label={
            "Prevent cookies used in ths request from being stored in the cookie jar. Existing cookies in the cookie jar will not be added as headers for this request."
          }
        ></ToggleSwitchComponent>

        <ToggleSwitchComponent
          labelHead={"Use server cipher suite during handshake"}
          label={
            "Use the server cipher suite order instead of the client's during handshake."
          }
        ></ToggleSwitchComponent>

        <div style={{ marginTop: "30px" }}>
          <div style={{ color: "grey", marginBottom: "20px", fontSize: '12px', fontWeight: '800' }}>
            {"Maximum number of redirects"}
          </div>

          <div
            style={{ marginLeft: "auto", marginRight: "0", marginTop: "-50px" }}
          >
            <InputBox label={" "}>
              <input
                type="text"
                id="token"
                name="token"
                value={""}
                onChange={() => {}}
                placeholder={""}
                style={{
                  width: "100px",
                  height: "43px",
                  top: "1080.783935546875px",
                  left: "880.361328125px",
                  border: "0.936554491519928px",
                  marginLeft: "auto",
                  backgroundColor: "#white",
                  color: "white",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                  fontFamily: "Inter, Sans-serif",
                  fontWeight: "550",
                  fontSize: "11px"
                }}
              />
            </InputBox>
          </div>

          <div
            style={{
              marginTop: "-25px",
              color: "grey",
              fontWeight: "normal",
              display: "flex",
              fontWeight: "500",
              fontSize: "11px"
            }}
          >
            {"Set a cap on the maximum number of redirects to follow."}
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <div style={{ color: "grey", marginBottom: "20px",fontWeight: "800",
              fontSize: "12px" }}>
            {"Protocols disabled during handshake"}
          </div>

          <div
            style={{ marginLeft: "auto", marginRight: "0", marginTop: "-50px" }}
          >
            <InputBox label={" "}>
              <input
                type="text"
                id="token"
                name="token"
                value={""}
                onChange={() => {}}
                placeholder={""}
                style={{
                  width: "213px",
                  height: "53px",
                  top: "1080.783935546875px",
                  left: "880.361328125px",
                  border: "0.936554491519928px",
                  marginLeft: "auto",
                  backgroundColor: "#eef0f6",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              />
            </InputBox>
          </div>

          <div
            style={{
              marginTop: "-30px",
              color: "grey",
              fontWeight: "normal",
              display: "flex",
              fontWeight: "500",
              fontSize: "11px"
            }}
          >
            {"Set a cap on the maximum number of redirects to follow."}
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <div style={{ color: "grey", marginBottom: "20px",fontWeight: "800",
              fontSize: "12px" }}>
            {"Cipher suite selection"}
          </div>

          <div
            style={{ marginLeft: "auto", marginRight: "0", marginTop: "-50px" }}
          >
            <InputBox label={" "}>
              <input
                type="text"
                id="token"
                name="token"
                value={""}
                onChange={() => {}}
                placeholder={"Enter cipher suites"}
                style={{
                  width: "213px",
                  height: "75px",
                  top: "1080.783935546875px",
                  left: "880.361328125px",
                  border: "0.936554491519928px",
                  marginLeft: "auto",
                  backgroundColor: "#eef0f6",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              />
            </InputBox>
          </div>

          <div
            style={{
              marginTop: "-55px",
              color: "grey",
              fontWeight: "normal",
              display: "flex",
              fontWeight: "500",
              fontSize: "11px"
            }}
          >
            {
              "Order of  cipher suites that the SSL server profile uses to establish a secure connection."
            }
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "7%",
            marginLeft: "-20%",
          }}
        >
          <div style={{}}>
            <PrimaryButton
              buttonSize="Big"
              style={{ width: "220%", height: "500px" }}
            >
              Done
            </PrimaryButton>
          </div>
        </div>
      </Box>
    </>
  );
};

export default SettingPageComponent;
