import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Help } from '@mui/icons-material';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import { Box } from '@mui/material';
import logoSmall from "../../assets/logos/logo_small.png";
import MixedContent from '../Common/MixedContent';
import MainLayout from '../../layouts/MainLayout';
import LoadingOverlay from '../Common/LoadingOverlay';

import {getPublishedQuestionsDetail} from '../../redux-store/chatsReducers/chatsActions';

const FAQDetail = function ({ faqSlug }) {
  const [isLoading, setIsLoading] = useState(true);
  const [questionDetail, setQuestionDetail] = useState({});
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  useEffect(() => {
    fetchQuestionDetail();
  }, []);

  const fetchQuestionDetail = async () => {
    setIsLoading(true);
    const response = await dispatch(getPublishedQuestionsDetail({faqSlug: faqSlug}));
    if (response?.payload) {
      setQuestionDetail(response?.payload);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openSnackbar({
        message: "Something went wrong. Please try again!",
        severity: "error",
      });
    }
  }

  return (
    <MainLayout
      isLoading={isLoading}
      renderHeader={() => (
        <Box sx={{color: "rgb(47, 47, 47)", textTransform: "capitalize", fontFamily: "Inter, Sans-serif"}}>
          <span style={{fontSize: "12px"}}>FAQ detail</span>
          <h3 style={{fontWeight: "500", marginTop: "4px", fontSize: "24px"}}>{isLoading ? <LoadingOverlay /> : questionDetail.question_content}</h3>
        </Box>
      )}
    >
      {
        !isLoading && (
          <Box sx={{mb: 2, textTransform: "capitalize", p: 2, borderRadius: "8px"}}>
            {questionDetail.question_content ? (
                <Box>
                  <Help sx={{width: "28px", height: "28px", mr: 1}} />
                  <Box sx={{width: "calc(100% - 50px)", display: "inline-block"}}>
                    <h5>{questionDetail.question_content}</h5>
                  </Box>
                </Box>
              ) : null}
            {
              questionDetail.answer_content ? (
                <Box sx={{mt: 1}}>
                  <img src={logoSmall} width="30px" style={{border: "1px solid #f8f8f8", borderRadius: "50%", marginRight: "8px"}} />
                  <Box sx={{px: 1/2, mt: 1/2, width: "calc(100% - 50px)", display: "inline-block", verticalAlign: "top"}}>
                    <MixedContent content={questionDetail.answer_content} />
                  </Box>
                </Box>
              ) : null
            }
          </Box>
        )
      }
    </MainLayout>
  )
};

export default FAQDetail;
