import React, {useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';

import {Stack, Typography, Chip, Avatar, Box} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Status from '../Common/Status';
import CustomDivider from "../Common/CustomDivider";

import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import coreUtils from '../../utils/coreUtils';

import "../../styles/components/Common/MetadataHeader.scss";

const typeHash = {
  testScenario: "Test Scenario",
  testSuite: "Test Suite",
  testPlan: "Test Plan"
}

const MetadataHeader = ({onUpdate, type}) => {
  const { openSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);
  const divisionRef = useRef(null);
  let detail = {...useSelector(state => {
    if (type === "testSuite") {
      return {...state.testSuite.selectedTestSuiteDetail};
    } else if (type === "testScenario") {
      return {...state.testScenario.selectedTestScenarioDetail};
    } else if (type === "testPlan") {
      return {...state.testPlan.selectedTestPlanDetail};
    }
  })};
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!isEdit || (event.target === divisionRef?.current)) {
        return false;
      } else {
        event.stopPropagation();
      }
      if (event.target !==  divisionRef?.current) {
        handleUpdate(divisionRef?.current?.innerText);
      } else {
        setIsEdit(true);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick, true);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
    };
  }, [detail]);

  const handleKeyDown = (event) => {
    if (!isEdit) {
      return false;
    }
    if (event.key === 'Enter') {
      if (event.target === divisionRef.current) {
        event.preventDefault();
        event.stopPropagation();
      }
      handleUpdate( divisionRef.current.innerText);
    } else if (event.key === 'Escape') {
      if (event.target === divisionRef.current) {
        event.preventDefault();
        event.stopPropagation();
      }
      divisionRef?.current?.blur();
      divisionRef.current.innerText = detail.title;
      setIsEdit(false);
    }
  }

  const handleUpdate = (newText) => {
    if (coreUtils.isStringInvalidOrBlank(newText)) {
      openSnackbar({
        message: "This field is required!",
        severity: "error",
      });
      return;
    }
    const trimmedText = newText.trim();
    divisionRef?.current?.blur();
    setIsEdit(false);
    if (trimmedText !== detail.title) {
      handleTestScenarioUpdate(trimmedText);
    }
  };

  const handleTestScenarioUpdate = async (newTitle) => {
    const response = await onUpdate({title: newTitle});
    if (response?.payload) {
      openSnackbar({
        message: "Title updated successfully.",
        severity: "success",
      });
    } else {
      divisionRef.current.innerText = detail.title;
      openSnackbar({
        message: "Title update failed!",
        severity: "error",
      });
    }
  }

  const handleClick = () => {
    if (!isEdit)
      setIsEdit(true);
  }

  const topRow = [];
  const bottomRow = [];

  if (detail?.code) {
    topRow.push(
      <span className="code">{typeHash?.[type]} : {detail?.code}</span>
    );
  }
  if (detail?.status) {
    if (topRow.length > 0) {
      topRow.push(<CustomDivider />);
    }
    topRow.push(
      <Status status={detail.status} />
    )
  }
  if (detail?.owner) {
    bottomRow.push(
      <>
        <Avatar
          alt={detail?.owner?.name}
          src={detail?.owner?.avatar}
          sx={{ width: 24, height: 24 }}
          className="avatar"
        />
        <Typography variant="body1">{detail?.owner?.name || detail?.owner?.email}</Typography>
      </>
    );
  }
  if (detail?.tags?.length > 0) {
    if (bottomRow.length > 0) {
      bottomRow.push(<CustomDivider />);
    }
    bottomRow.push(<LocalOfferIcon />);
    bottomRow.push(
      <div className="tags" title={detail?.tags?.join(", ")}>
        {detail?.tags?.map((tag, index) => (<Chip key={index} sx={{mr: 1, mb: 1}} label={tag} />))}
      </div>
    );
  }

  const titleClasses = ["title"];
  if (!isEdit) {
    titleClasses.push("truncate-1-lines");
  } else {
    titleClasses.push("edit");
  }

  return (
    <Box className="metadataHeader">
      <Stack direction="row" spacing={1} alignItems="center" sx={{mb: 1/2}}>
        {topRow}
      </Stack>
      {/* <InlineEditableDivision textContent={detail.title} onUpdate={handleTestScenarioUpdate} enableEdit={!isLoading} /> */}
      <div className={titleClasses.join(" ")} onClick={handleClick} contentEditable="true" ref={divisionRef} onKeyDown={handleKeyDown} >
        {detail?.title}
      </div>
      <Stack direction="row" spacing={1} alignItems="center">
        {bottomRow}
      </Stack>
    </Box>
  )
}

export default MetadataHeader;
