import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";

import FAQList from './FAQList';
import FAQDetail from './FAQDetail';

const FAQPage = ({ project, faqSlug }) => {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (faqSlug) {
      setCurrentView("faqDetail");
    } else {
      setCurrentView("faqList");
    }
  }, [faqSlug]);

  let content = null;
  if (currentView === 'faqList') {
    content = (
      <FAQList project={project} />
    );
  } else if (currentView === 'faqDetail') {
    content = (
      <FAQDetail project={project} faqSlug={faqSlug} />
    );
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
};

export default FAQPage;
