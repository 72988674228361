import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Card, CardContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OrganisationForm from '../Settings/OrganisationForm';

import { createOrganisation } from '../../redux-store/currentUserActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const OrganisationScreen = ({ onChild }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {openSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () =>{
    onChild();
  }

  const handleSubmit = async (data) => {
    setIsLoading(true);
    const response = await dispatch(createOrganisation(data))
    if (response?.payload) {
      openSnackbar({ message: "Organisation created successfully!", severity: 'success' });
      setIsLoading(false);
      onChild();
      history.push("/dashboard");
    } else {
      openSnackbar({ message: "Organisation creation failed!", severity: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Create Organisation</Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <OrganisationForm onSubmit={handleSubmit} isLoading={isLoading} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrganisationScreen;