import React, {useState} from 'react';
import { Box, Dialog, DialogTitle, DialogContent, MenuItem, Divider, Link } from '@mui/material';
import {
  LayersOutlined,
  BorderColor,
  QueryBuilder,
  Close
} from '@mui/icons-material';
import Monitoring from "./monitoring.jsx";
import ProductDocs from './productDocs.jsx';
import TestScenarios from "./testScenarios.jsx";
import TestPlans from './testPlans.jsx';
import ApiCollection from './apiCollection.jsx';

import customUtils from '../../utils/customUtils.js';

import "../../styles/components/Guide/GuideModal.scss";

const GuideModal = ({ open = false, onClose = () => {} }) => {
  const guideMenuItems = [
    {label: "API Collection", icon: <LayersOutlined className="menuIcon mui" />, id: "apiCollection"},
    {label: "Product Docs", id: "productDocs"},
    {label: "Test Scenarios", icon: <BorderColor className="menuIcon mui" />, id: "testScenarios"},
    {label: "Test Plans", icon: <QueryBuilder className="menuIcon mui" />, id: "testPlans"},
    {label: "Monitoring", id: "monitoring"},
  ]

  const [selectedItem, setSelectedItem] = useState("apiCollection");

  const handleMenuItemClick = (event, id) => {
    event.stopPropagation();
    setSelectedItem(id);
  }

  const renderMenuContent = () => {
    let content;
    switch(selectedItem) {
      case "productDocs":
        content = (<ProductDocs />);
        break;
      case "testScenarios":
        content = (<TestScenarios icon={<BorderColor className="menuIcon mui" />} />);
        break;
      case "testPlans":
        content = (<TestPlans icon={<QueryBuilder className="menuIcon mui" />} />);
        break;
      case "monitoring":
        content = (<Monitoring />);
        break;
      default:
        content = (<ApiCollection icon={<LayersOutlined className="menuIcon mui" />} />);
        break;
    }
    return content;
  }

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open} fullWidth={true} className="guideModal">
      <Box className="contentWrapper">
        <DialogTitle className="title">
          <span>Onboarding Guide</span>
          <Close onClick={onClose} size="lg" className="closeIcon" />
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent className="guideContent">
          <Box className="menu">
            {
              guideMenuItems.map((item) => (
                <MenuItem key={item.label} className={selectedItem === item.id ? "menuItem selected" : "menuItem"} selected={selectedItem === item.id} onClick={(e) => {handleMenuItemClick(e, item.id)}}>
                  {item.icon ? item.icon : <div className={`menuIcon ${item.id}`}></div>}
                  <span>{item.label}</span>
                </MenuItem>
              ))
            }
          </Box>
          <Box className="content">
            {renderMenuContent()}
          </Box>
          <Divider className="divider" />
          <p className="footer">In case of any queries, please schedule a <Link className="link" href={customUtils.calendlyDemoUrl} target="_blank">call</Link> here or drop a mail to <Link href="mailto:care@qodex.ai">care@qodex.ai</Link></p>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default GuideModal;
