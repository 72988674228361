import React from "react";
import { Box } from "@mui/material";

const TestScenarios = ({icon}) => {
  return (
    <Box>
      {/* <div className="title">
        {icon ? icon : <div className="menuIcon testScenarios" />}
        <h3>Test Scenarios</h3>
      </div> */}
      <p style={{marginTop: "0px"}}>
        QodexAI generates three types of test scenarios:
      </p>
      <ul>
        <li>
          Unit Tests: QodexAI writes various micro tests for an API, including validations, authentication, error handling, and heavy resource consumption.
        </li>
        <li>
          Integration/Functional Tests: QodexAI explores all kinds of expected and unexpected user flows to build functional test scenarios, which can be nearly impossible for humans to do.
        </li>
        <li>
          Security Tests: QodexAI analyzes your API vulnerabilities and writes all the test scenarios needed to protect your system from OWASP Top 10, Top 100, and other detailed exposures.
        </li>
      </ul>
      <p>
        These test scenarios keep evolving as more code is pushed into the system. You can always group these test scenarios according to your team convention or functionality. It also prepares release notes that summarise intentional and unintentional changes for any release.
      </p>
      <p>
        You are always in control of the testing scenarios. QodexAI will continue to suggest more as the product expands.
      </p>
    </Box>
  )
};

export default TestScenarios;
