import { Box, Tab, Tabs, Button } from '@mui/material';
import { useState, useMemo } from 'react';
import ResponseBodyComponent from "./ResponseBodyComponent";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedResponseTab } from '../../redux-store/currentUserSlice';
import { useGuestUser } from '../../contexts/GuestUserContext';

const ResponseComponent = ({ state, entity, toggleHeight }) => {
  const dispatch = useDispatch();
  const { isGuestUser } = useGuestUser();
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const selectedResponseTab = useSelector((state) => state.user.selectedResponseTab);
  const [activeTab, setActiveTab] = useState(selectedResponseTab);

  const tabStyles = (isActive) => ({
    color: isActive ? '#fff' : '#575757',
    backgroundColor: isActive ? '#6241D4' : '#e9e9e9',
    height: '30px',
    minHeight: '30px',
    textTransform: 'none',
    fontSize: "12px",
    fontFamily: "Inter, Sans-serif"
  });

  const tabsData = [
    { label: null, component: <ResponseBodyComponent entity={entity} /> }
  ];

  const getTabLabel = (label, failedTests, passedTests) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ fontSize: '0.875rem', lineHeight: 1.25 }}>{label}</span>
        {failedTests > 0 ? (
          <Box
            sx={{
              backgroundColor: '#F44336',
              color: '#fff',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              fontSize: '0.65rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '5px',
            }}
          >
            {failedTests}
          </Box>
        ) : passedTests > 0 && (
          <Box
            sx={{
              backgroundColor: '#008000',
              color: '#fff',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              fontSize: '0.65rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '5px',
            }}
          >
            {passedTests}
          </Box>
        )}
      </Box>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    dispatch(setSelectedResponseTab(newValue));
  };

  // FIXME :: Get rid of this useEffect. It would switch to Current Response tab everytime a new request is made.
  // useEffect(() => {
  //   if (selectedSidebarState?.entityable_id) {
  //     setActiveTab(0);
  //   }
  // }, [selectedSidebarState]);

  return (
    <Box sx={{ borderTop: '2px solid #e9e9e9', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {tabsData.some(tab => tab.label) && ( // Check if at least one tab has a label
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0px' }}>
          <Tabs
            orientation="horizontal"
            value={activeTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{ borderRadius: '5px', minHeight: '30px', height: '30px' }}
          >
            {tabsData.map((tab, index) => (
              tab.label ? ( // Check if tab.label exists
                <Tab
                  disabled={isGuestUser && index === 1}
                  key={index}
                  style={{ ...tabStyles(activeTab === index) }}
                  label={
                    tab.label === "AI Tests"
                      ? getTabLabel(tab.label, entity?.tests_failed_count || 0, entity?.tests_count || 0)
                      : tab.label
                  }
                />
              ) : null
            ))}
          </Tabs>
        </Box>
      )}
      <Box>
        {tabsData[activeTab]?.component}
      </Box>
    </Box>
  );
  
}

export default ResponseComponent;
