import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import VariableSet from './VariableSet';
import VariableSetListPage from './VariableSetListPage';

const VariableSetPage = function ({ project, variableSetId }) {
  const [currentView, setCurrentView] = useState('');

  useEffect(() => {
    if (variableSetId) {
      setCurrentView('variableSet');
    } else {
      setCurrentView('variableSetList');
    }
  }, [variableSetId]);

  let content = null;
  if (currentView === 'variableSetList') {
    content = <VariableSetListPage project={project} />;
  } else if (currentView === 'variableSet') {
    content = <VariableSet project={project} variableSetId={variableSetId} />;
  } else {
    // content = <div>Loading...</div>;
  }

  return <Box>{content}</Box>;
};

export default VariableSetPage;
