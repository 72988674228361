import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const testCaseSetSlice = createSlice({
  name: "testCaseSet",
  initialState: {
    isLoading: false,
    isSaving: false,
    testCaseSetList: [],
    pageNumber: 1,
    pageSize: 10,
    errors: {},
    total: 0,
    totalPages: 0,
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    updateTestCaseSetList: (state, action) => {
      return {
        ...state,
        testCaseSetList: [...action.payload.testCaseSetList],
      };
    },
    setSelectedTestCase: (state, action) => {
      return {
        ...state,
        selectedTestCase: { ...action.payload.selectedTestCase },
      };
    },
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  updateTestCaseSetList,
  setSelectedTestCase,
} = testCaseSetSlice.actions;

export default testCaseSetSlice.reducer;
