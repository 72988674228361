import React from "react";
import {Box} from "@mui/material"

const EntityStatus = ({status}) => {
  return (
    <Box sx={{p: 1}}>
      {status}
    </Box>
  )
}

export default EntityStatus;
