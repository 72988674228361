import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Select,
  MenuItem,
  MenuList,
  TextField,
  Typography
} from "@mui/material";
import CustomMenu from "../Common/CustomMenu";
import {LabelButton} from "../Common/CustomButton";

import {
  createDataSet,
  updateDataSet,
} from "../../redux-store/dataSetReducers/dataSetActions";
import {
  setSelectedDataSet,
  setDataSetList,
  setSelectedDataSetModifiedDetail,
} from "../../redux-store/dataSetReducers/dataSetSlice";
import coreUtils from "../../utils/coreUtils";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import "../../styles/components/TestScenarios/TestCaseDataSet.scss";

const TestCaseDataSet = ({ testCase, onTestCaseSave, updateIsModified }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [titleAnchorEl, setTitleAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  const [dataSetId, setDataSetId] = useState("");
  const selectedDataSetModifiedDetail = useSelector(
    (state) => state.dataSet.selectedDataSetModifiedDetail
  );
  const selectedDataSetDetail = useSelector(
    (state) => state.dataSet.selectedDataSet
  );
  const dataSetList = useSelector((state) => state.dataSet.dataSetList);
  const [isModified, setIsModified] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setInitialState();
  }, [testCase]);

  useEffect(() => {
    checkIfModified(selectedDataSetDetail, selectedDataSetModifiedDetail);
  }, [selectedDataSetModifiedDetail]);

  const checkIfModified = (oldDetail, newDetail) => {
    let isParamsModified = coreUtils.isDifferentArray(
      oldDetail?.params,
      newDetail?.params
    );
    let isHeadersModified = coreUtils.isDifferentArray(
      oldDetail?.headers,
      newDetail?.headers
    );
    let isBodyModified = coreUtils.isDifferentJson(
      oldDetail?.body,
      newDetail?.body
    );
    let isBodyTypeModified = oldDetail?.body_type !== newDetail?.bodyType;

    const isDataSetModified = isParamsModified || isHeadersModified || isBodyModified || isBodyTypeModified;

    setIsModified(isDataSetModified);
    updateIsModified(isDataSetModified);
  };

  const setInitialState = async () => {
    const dataSets = testCase?.entity?.data_sets;
    const dataSetId = testCase?.data_set_id;
    if (dataSets) {
      await dispatch(setDataSetList({dataSetList: [...dataSets]}));
    }
    if (dataSetId) {
      const dataSet = dataSets.filter((ds) => dataSetId === ds.id)[0];
      setDataSetId(dataSet.id);
      await dispatch(setSelectedDataSet({ ...dataSet, bodyType: dataSet?.body_type }));
    }
  }

  const handleSelection = async (dataSetId) => {
    const dataSet = dataSetList.filter((ds) => dataSetId === ds.id)[0];
    setDataSetId(dataSet.id);
    await dispatch(
      setSelectedDataSet({ ...dataSet, bodyType: dataSet?.body_type })
    );
  };

  const handleTitleChange = (event) => {
    setError("");
    setTitle(event.target.value);
  };

  const handleReset = async () => {
    await dispatch(setSelectedDataSet({...selectedDataSetDetail}));
    setIsModified(false);
  };

  const handleUpdate = async () => {
    const data = { ...selectedDataSetDetail, ...selectedDataSetModifiedDetail };
    const response = await dispatch(updateDataSet(data));
    if (response?.payload) {
      openSnackbar({ message: "Data set updated.", severity: "success" });
      const newDataSet = response.payload;
      const dataSetIndex = dataSetList.findIndex(
        (ds) => ds.id === newDataSet.id
      );
      const newDataSetList = [...dataSetList];
      newDataSetList[dataSetIndex] = { ...newDataSet };
      await dispatch(
        setSelectedDataSet({
          ...newDataSet,
          bodyType: newDataSet?.body_type
        })
      );
      await dispatch(setDataSetList({ dataSetList: newDataSetList }));
      onTestCaseSave({dataSetId: newDataSet.id});
      setIsModified(false);
    } else {
      openSnackbar({ message: "Data set updation failed!", severity: "error" });
    }
  };

  const handleCreate = async (event) => {
    if (coreUtils.isStringInvalidOrBlank(title)) {
      setError("Title is Required!");
      return;
    }
    const requestData = {
      body: selectedDataSetModifiedDetail?.body || null,
      body_type: selectedDataSetModifiedDetail?.bodyType || null,
      params: selectedDataSetModifiedDetail?.params || [],
      headers: selectedDataSetModifiedDetail?.headers || [],
      title: title,
      entityId: testCase?.entity?.id
    };

    const response = await dispatch(createDataSet(requestData));
    const newDataSet = response?.payload;
    if (newDataSet) {
      await dispatch(setSelectedDataSet({...newDataSet}));
      await dispatch(setSelectedDataSetModifiedDetail({...newDataSet, bodyType: newDataSet.body_type}));
      await dispatch(setDataSetList({dataSetList: [...dataSetList, {...newDataSet}]}));
      onTestCaseSave({dataSetId: newDataSet.id});
      setDataSetId(newDataSet.id);
      openSnackbar({message: "Data set created successfully.", severity: "success"});
      checkIfModified();
      handleCreateClose();
    } else {
      openSnackbar({message: "Data set creation failed!", severity: "error"});
    }
  };

  const handleClickCreate = (event) => {
    setTitleAnchorEl(event.target);
  };

  const handleCreateClose = () => {
    setTitleAnchorEl(null);
    setTitle("");
    setError("");
  };

  return (
    <Box className="testCaseDataSet">
      <Typography>Data Set</Typography>
      <Select
        size="small"
        value={dataSetId}
        onChange={(event) => {
          handleSelection(event.target.value);
        }}
        className="dataSetSelect"
      >
        {dataSetList.map((dataSet) => {
          return <MenuItem value={dataSet.id}>{dataSet.title}</MenuItem>;
        })}
      </Select>
      <div className="actions">
        {dataSetId && (
          <LabelButton className="labelButton primary" onClick={handleUpdate} disabled={!isModified}>
            Save
          </LabelButton>
        )}
        <LabelButton className="labelButton primary" onClick={handleClickCreate} disabled={!isModified}>
          Save As
        </LabelButton>
        <LabelButton className="labelButton primary" onClick={handleReset} disabled={!isModified}>
          Reset
        </LabelButton>
      </div>
      <CustomMenu
        labelledBy={"title-dropdown-" + dataSetId}
        anchorEl={titleAnchorEl}
        onClose={handleCreateClose}
      >
        <MenuList sx={{ minWidth: "100px", p: 1 }}>
          <span>Title</span>
          <TextField
            size="small"
            sx={{ minWidth: "250px", mr: 1, ml: 1/2 }}
            value={title}
            onChange={handleTitleChange}
            error={!coreUtils.isStringInvalidOrBlank(error)}
            helperText={error}
          />
          <LabelButton onClick={handleCreate} type="label" className="labelButton primary" disabled={!title?.trim()}>Confirm</LabelButton>
        </MenuList>
      </CustomMenu>
    </Box>
  );
};

export default TestCaseDataSet;
