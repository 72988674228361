import React from 'react';

const SVGIcon = ({IconSvg, size}) => {
  let width, height;
  if (size === "sm") {
    width = "20px";
    height = "20px";
  } else if (size === "md") {
    width = "24px";
    height = "24px";
  } else if (size === "lg") {
    width = "30px";
    height = "30px";
  }
  return (
    <img src={IconSvg} width={width} height={height} alt="" />
  )
}

export default SVGIcon;
