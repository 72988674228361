import { createSlice } from "@reduxjs/toolkit";

const dataSetSlice = createSlice({
  name: "dataSet",
  initialState: {
    isLoading: false,
    isSaving: false,
    total: 0,
    totalPages: 0,
    dataSetList: [],
    selectedDataSet: {},
    selectedDataSetModifiedDetail: {}
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSavingState: (state, action) => {
      return {
        ...state,
        isSaving: action.payload,
      };
    },
    setDataSetList: (state, action) => {
      return {
        ...state,
        dataSetList: [...action.payload.dataSetList],
      };
    },
    setSelectedDataSet: (state, action) => {
      return {
        ...state,
        selectedDataSet: {...action.payload}
      }
    },
    setSelectedDataSetModifiedDetail: (state, action) => {
      return {
        ...state,
        selectedDataSetModifiedDetail: {...state.selectedDataSetModifiedDetail, ...action.payload}
      }
    },
    setTotal: (state, action) => {
      return {
        ...state,
        total: action.payload.total
      }
    },
    setTotalPages: (state, action) => {
      return {
        ...state,
        totalPages: action.payload.totalPages
      }
    }
  },
});

export const {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setDataSetList,
  setSelectedDataSet,
  setSelectedDataSetModifiedDetail,
  setTotal,
  setTotalPages
} = dataSetSlice.actions;

export default dataSetSlice.reducer;
