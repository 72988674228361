import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputBase } from '@mui/material';

const HeadersComponentView = ({ headers }) => {
  const headersObject = headers || {
    'Accept': '*/*',
    'Accept-Encoding': 'identity',
    'Connection': 'keep-alive'
  };

  return (
    <Box>
      <Typography variant="body1">
        Headers
      </Typography>
      <TableContainer sx={{ backgroundColor: '#fff', borderRadius: '5px' }}>
        <Table size='small' aria-label="simple" sx={{ border: '1px solid' }}>
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(headersObject).map(([key, value], index) => (
              <TableRow key={index}>
                <TableCell>{key}</TableCell>
                <TableCell>
                  <InputBase
                    fullWidth
                    readOnly
                    value={value}
                    sx={{
                      cursor: 'text',
                      color: 'inherit',
                      '&.MuiInputBase-root': {
                        fontSize: 'inherit',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '& input': {
                          padding: 0,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      }
                    }}
                    inputProps={{
                      style: { WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text', userSelect: 'text', WebkitTapHighlightColor: 'transparent' },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HeadersComponentView;
