import _ from 'lodash';

export const findCollectionBySlug = (data, slug) => {
  return _.find(data, { slug: slug });
};

export const findFolderBySlug = (data, slug) => {
  let _folder = null;
  _.forEach(Object.keys(data), (collectionId) => {
    const folder = _.find(data[collectionId], { slug: slug });
    if (folder) {
      _folder = folder;
      return false;
    }
  });
  return _folder;
};

export const findEntityBySlug = (data, slug) => {
  let _entity = null;
  _.forEach(Object.keys(data), (collectionId) => {
    const entity = _.find(data[collectionId], { slug: slug });
    if (entity) {
      _entity = entity;
      return false;
    }
  });

  return _entity;
};

export const findEntityBySlugInFolders = (data, entitySlug) => {
  let _entity = null;
  _.forEach(Object.keys(data), (folderId) => {
    const entity = _.find(data[folderId], { slug: entitySlug });
    if (entity) {
      _entity = entity;
      return false;
    }
  });

  return _entity;
};

export const findCollectionByFolderId = (data, collections, folderId) => {
  let _collection = null;
  _.forEach(Object.keys(data), (collectionId) => {
    const folder = _.find(data[collectionId], { id: folderId });
    if (folder) {
      _collection = _.find(collections, { id: collectionId });
      return false;
    }
  });
  return _collection;
};

export const findCollectionByEntityId = (data, collections, entityId) => {
  let _collection = null;
  _.forEach(Object.keys(data), (collectionId) => {
    const entity = _.find(data[collectionId], { id: entityId });
    if (entity) {
      _collection = _.find(collections, { id: collectionId });
      return false;
    }
  });
  return _collection;
};

export const findFolderByEntityId = (data, entityId) => {
  let _folder = null;
  _.forEach(Object.keys(data), (folderId) => {
    const entity = _.find(data[folderId], { id: entityId });
    if (entity) {
      _folder = folderId;
      return false;
    }
  });
  return _folder;
};

export const findFolderById = (data, folderId) => {
  let _folder = null;
  _.forEach(Object.keys(data), (collectionId) => {
    const folder = _.find(data[collectionId], { id: folderId });
    if (folder) {
      _folder = folder;
      return false;
    }
  });
  return _folder;
};

export const filterProjectCollections = (projectCollections, searchTerm) => {
  // todo : rewrite as per new structure of collections, folders and entities states
  if (!searchTerm) return projectCollections;

  return projectCollections
    .map((collection) => {
      const filteredFolders = collection.folders
        ?.filter(
          (folder) =>
            folder.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            folder.entities.some((entity) =>
              entity.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
        .map((folder) => ({
          ...folder,
          entities: folder.entities?.filter((entity) =>
            entity.name.toLowerCase().includes(searchTerm.toLowerCase())
          ),
        }));

      const filteredEntities = collection.entities?.filter((entity) =>
        entity.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return {
        ...collection,
        folders: filteredFolders,
        entities: filteredEntities,
      };
    })
    .filter(
      (collection) =>
        collection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        collection.folders?.length > 0 ||
        collection.entities?.length > 0
    );
};
