import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    isLoading: false,
    errors: {}
  },
  reducers: {
    updateLoadError: (state, action) => {
      return {
        ...state,
        errors: action.payload.error_message,
      };
    },
    setLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
  },
});

export const {
  updateLoadError,
  setLoadingState,
} = globalSlice.actions;

export default globalSlice.reducer;
