import { Chip, Typography } from '@mui/material';

const CustomChip = ({ label, sx, ...props }) => {
  return (
    <Chip
      {...props}
      sx={{
        height: '20px',
        padding: '1px 2px',
        background: "rgba(0, 0, 0, 0.05)",
        ...sx
      }}
      label={<Typography variant="body2">{label}</Typography>}
      size="small"
    />
  );
};

export default CustomChip;
