import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from '@mui/material';

const CustomDialog = ({
  open = false,
  title = '',
  btnText = 'Save',
  options = [],
  onClose = () => {},
  handleSubmit = () => {},
}) => {
  const [selectedValue, setSelectedValue] = useState(
    typeof options[0] === 'string' ? options[0] : options[0]?.id
  );

  const handleRadioChange = (event) => setSelectedValue(event.target.value);

  return (
    <Dialog maxWidth="xs" onClose={onClose} open={open}>
      {title ? <DialogTitle>{title}</DialogTitle> : null}

      <div style={{ minWidth: '300px', padding: '0px 16px 10px 16px' }}>
        <RadioGroup
          name="radio-buttons-group"
          value={selectedValue}
          onChange={handleRadioChange}
        >
          {options.map((option) =>
            typeof option === 'string' ? (
              <div key={option}>
                <FormControlLabel
                  value={option}
                  control={<Radio />}
                  label={option}
                  sx={{ m: 0 }}
                />
              </div>
            ) : (
              <div key={option.id}>
                <FormControlLabel
                  value={option.id}
                  control={<Radio />}
                  label={option.name || option.title}
                  sx={{ m: 0 }}
                />
              </div>
            )
          )}
        </RadioGroup>
      </div>

      <Button
        disabled={!selectedValue}
        onClick={() => handleSubmit(selectedValue)}
      >
        {btnText}
      </Button>
    </Dialog>
  );
};

export default CustomDialog;
