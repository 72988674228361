import { Divider, TextField, Box, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResourceSummary } from "../../redux-store/currentUserActions";
import { setResourceSummary } from '../../redux-store/currentUserSlice';
import AuthTypeText from "./AuthTypeText";

const BearerTokenAuthorization = ({ selectedType }) => {
  const selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();
  const [enteredToken, setEnteredToken] = useState('');

  useEffect(() => {
    if (selectedSummary?.authorization_value?.token) {
      setEnteredToken(selectedSummary?.authorization_value?.token);
    }
  }, [selectedSummary]);

  const handleInputChange = (e) => {
    setEnteredToken(e.target.value);
  };

  const handleCollectionCall = async () => {
    const state = {
      id: selectedSummary?.id,
      summary: {
        authorization_type: selectedType,
        authorization_value: {
          token: enteredToken
        }
      }
    };
    const res = await dispatch(updateResourceSummary(state));
    if (res.payload.summary) {
      setEnteredToken(res.payload.summary.authorization_value.token);
    }
  };

  return (
    <Box p={2}>
      <AuthTypeText />
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body">Token:</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            variant="outlined"
            value={enteredToken}
            onChange={handleInputChange}
            onBlur={handleCollectionCall}
            style={{ width: '40%' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BearerTokenAuthorization;
