import React from "react";
import {Box, Grid, Button, Link} from "@mui/material";
import {GitHub} from "@mui/icons-material";
import SVGIcon from "../Common/SVGIcon.jsx";

import "../../styles/components/TestPlans/CICDIntegrations.scss";
import circleCI from "../../assets/icons/svg/circleCI.svg";

const CICDIntegrations = ({webhookBuildCurl="", webhookBuildStatusCurl=""}) => {
  return (
    <Box className="ciCDIntegrations">
      <h6>Default Integrations</h6>
      <Box className="actions">
        <Button>
          <SVGIcon IconSvg={circleCI} size="sm" /><span>CircleCI</span>
        </Button>
        <Button>
          <GitHub />
          <span>GitHub</span>
        </Button>
      </Box>
      <Box>
        <h6>REST API to integrate with other tools</h6>
        <Grid container direction="row" justifyContent="center" className="sectionWrapper">
          <Grid item md={6} sx={12} className="section">
            <h6>REST API call to start Test Plan</h6>
            <div className="curlUrl">
              {webhookBuildCurl}
            </div>
            <ul>
              <li>
                Response of the above REST API Call is the Build-ID of the Test Plan that has been started.
              </li>
              <li>
                You can use this in the next REST API Call to check the status. API_KEY can be found from API Keys under Settings Tab.
              </li>
              <li>
                Please check the below guide for more details - <Link href="#">Start/Trigger a Test Plan in QodeX using REST API</Link>
              </li>
            </ul>
          </Grid>
          <Grid item md={6} xs={12} className="section">
            <h6>Test Plan status check REST API Call</h6>
            <div className="curlUrl">
              {webhookBuildStatusCurl}
            </div>
            <ul>
              <li>
                Response of the above REST API Call returns the details of the Test Plan with the given Build-ID such as current status and the result of the Test Plan.
              </li>
              <li>
                API_KEY can be found from API Keys under Settings Tab.
              </li>
              <li>
                Please check the below guide for more details - <Link href="#">Start/Trigger a Test Plan in QodeX using REST API</Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CICDIntegrations;
