import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Stack, Divider } from '@mui/material';

import { setBuildLogs } from '../../../redux-store/testScenarioBuildReducers/testScenarioBuildSlice';

import LoadingOverlay from '../LoadingOverlay';
import BuildLogStep from './BuildLogStep';
import CustomButton from '../../Common/CustomButton';

import {RefreshOutlined} from '@mui/icons-material';

const BuildLogs = ({initialFetch, fetchBuildLogs, goToAllBuilds, isLoading, hideAllBuilds, getStatus, root=false, isExpanded}) => {
  const [buildLogs, setBuildLogs] = useState({});
  const dispatch = useDispatch();
  const [buildLogIds, setBuildLogIds] = useState([]);
  // const buildLogs = useSelector(state => state.testScenarioBuild.buildLogs);
  const isInitial = useRef(true);
  useEffect(() => {
    if (initialFetch && isInitial.current)
      fetchLogs();
  }, [initialFetch]);

  const fetchLogs = async (parentLogId) => {
    const response = await fetchBuildLogs(parentLogId);
    if (response) {
        setParsedBuildLogs(response, parentLogId);
    }
  }

  const updateBuildLogs = async (logs) => {
    // await dispatch(setBuildLogs(logs));
    setBuildLogs(logs);
  }

  const setParsedBuildLogs = async (logs, parentLogId) => {
    const logHash = {...buildLogs};
    const logIds = logs.map((log) => {
      const logObj = {...log, isExpanded: false};
      if (log.has_child_logs)
        logObj.childLogIds = [];
      logHash[log.id] = {...logObj};
      return log.id;
    })
    if (parentLogId)
      logHash[parentLogId] = {...logHash[parentLogId], childLogIds: [...logIds], isExpanded: true};
    updateBuildLogs(logHash);
    if (isInitial.current) {
      setBuildLogIds(logIds);
      isInitial.current = false;
    }
  }

  const refreshLogs = () => {
    isInitial.current = true;
    fetchLogs();
  }

  const onExpandLog = (event, parentLogId) => {
    event.stopPropagation();
    if (buildLogs?.[parentLogId] && buildLogs?.[parentLogId]?.isExpanded) {
      updateBuildLogs({...buildLogs, [parentLogId]: {...buildLogs[parentLogId], isExpanded: false}});
    } else {
      if (buildLogs?.[parentLogId]?.has_child_logs) { //(!buildLogs?.[parentLogId]) {
        const childLogIds = buildLogs?.[parentLogId]?.childLogIds || [];
        const lastChildId = childLogIds[childLogIds.length - 1];
        if (lastChildId && buildLogs[lastChildId]) {
          updateBuildLogs({...buildLogs, [parentLogId]: {...buildLogs[parentLogId], isExpanded: true}});
        } else {
          fetchLogs(parentLogId);
        }
      }
    }
  }

  if (isInitial.current || !buildLogIds) {
    return (
      <LoadingOverlay isLoading={isLoading} />
    )
  }

  if (buildLogIds && buildLogIds.length === 0) {
    return (
      <>
        <div>Logs are getting ready, try refreshing!!</div>
        {!hideAllBuilds && (<CustomButton variant="outlined" color="primary" sx={{mr: 2}} disabled={false} onClick={goToAllBuilds}>All Builds</CustomButton>)}
        <CustomButton variant="outlined" startIcon={<RefreshOutlined/>} onClick={refreshLogs} />
      </>
    )
  }

  const renderSummary = (buildDetail) => {
    const {status, statusIcon} = getStatus(buildDetail?.status);
    return (
      <>
        <div style={{ width: "calc(100% - 200px)", display: "inline-block" }}>
          <h6>{buildDetail?.log_message}</h6>
        </div>
        <div style={{ width: "200px", display: "inline-block"}}>
          <Stack direction="row-reverse" spacing={1} sx={{ color: "#4d4d4d", mb: 1}} >
            {buildDetail?.execution_time > 0 &&
              <>
                <span>{buildDetail?.execution_time?.toFixed(2)} seconds</span>
                <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
              </>
            }
            {status}
            {statusIcon}
          </Stack>
        </div>
      </>
    );
  };

  const renderRootLogs = (id) => {
    const parentLogs = buildLogs?.[id] || {};
    let sx = {border: "1px solid #e4e4e4", mb: 1, borderRadius: "8px"};
    return (
      <Accordion key={id} expanded={parentLogs?.isExpanded} onChange={(event) => {onExpandLog(event, id)}} sx={{boxShadow: "none", ...sx }}>
        <AccordionSummary
          aria-controls="build-content"
          id="build-header"
        >
          {renderSummary(parentLogs)}
        </AccordionSummary>
        <div style={{height: "28px", background: "#4E4E4E", borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}></div>
        {parentLogs?.has_child_logs && (
          <AccordionDetails sx={{background: "rgb(47, 47, 47)", color: "rgb(238, 238, 238)", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}}>
            <div >
              {parentLogs?.isExpanded && (
                parentLogs?.childLogIds.map((childLogId) => {
                  return (<BuildLogStep key={childLogId} allLogs={buildLogs} logId={childLogId} onExpandLog={onExpandLog} renderChildren={renderChildrenLogs} />);
                })
              )}
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    )
  }

  const renderChildrenLogs = (childrenIds, isRoot=false) => {
    const childrens = childrenIds.map((id) => {
      if (isRoot) {
        return renderRootLogs(id);
      } else {
        return (<BuildLogStep key={id} allLogs={buildLogs} logId={id} onExpandLog={onExpandLog} renderChildren={renderChildrenLogs} />)
      }
    })
    return childrens;
  }

  return (
    <Box sx={{overflow: 'auto', width: "100%"}}>
      {!hideAllBuilds && (<CustomButton variant="outlined" color="primary" sx={{mr: 2}} disabled={false} onClick={goToAllBuilds}>All Builds</CustomButton>)}
      {/* <CustomButton variant="outlined" startIcon={<RefreshOutlined />} onClick={refreshLogs} sx={{my: 2}} /> */}
      {renderChildrenLogs(buildLogIds, root)}
    </Box>
  )
}

export default BuildLogs;
