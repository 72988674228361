import React from 'react';
import JsonView from '@uiw/react-json-view';

// Create the JsonPrettyViewer component
const JsonPrettyViewer = ({ data = {} }) => {

  return (
    <JsonView value= {data} displayDataTypes={false} displayObjectSize={false} displayArrayKey={false} enableClipboard={false} style={customTheme} />
  );
};

export default JsonPrettyViewer;


const customTheme = {
  '--w-rjv-font-family': 'monospace',
 '--w-rjv-key-string': '#A31515',
  '--w-rjv-info-color': '#A31515',
  '--w-rjv-quotes-color': 'var(--w-rjv-key-string)',
  '--w-rjv-type-string-color': '#0451A5',
  '--w-rjv-quotes-string-color': 'var(--w-rjv-type-string-color)',
  '--w-rjv-type-int-color': '#098658',
  '--w-rjv-type-float-color': '#098658',
  '--w-rjv-type-bigint-color': '#b5cea8',
  '--w-rjv-type-boolean-color': '#FF8C00',
  '--w-rjv-type-date-color': '#FF8C00',
  '--w-rjv-type-url-color': '#3b89cf',
  '--w-rjv-type-null-color': '#569cd6',
  '--w-rjv-type-nan-color': '#859900',
  '--w-rjv-type-undefined-color': '#569cd6',
};