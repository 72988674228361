import { Typography, Box } from '@mui/material';
import ConditionalRender from "../conditionalRender";

export const InputBox = ({
  inputClass = "an_input_box",
  searchLensClass = null,
  ...props
}) => {
  return (
    <Box style={props.style}>
      {props.label && (
        <Typography variant="body2" className="text_body_light">
          {props.label}
        </Typography>
      )}
      <Box className={`${inputClass} ${props.error ? 'an_input_error' : ''}`}>
        <div style={{ position: "relative" }}>
          {props.children}
          <ConditionalRender if={searchLensClass}>
            <span className={searchLensClass}>
              {/* <img src="/search.svg" /> */}
            </span>
          </ConditionalRender>
        </div>
      </Box>
      {props.error && (
        <Typography variant="caption" color="error" sx={{ mt: 1 }}>
          {props.error}
        </Typography>
      )}
      {props.helper && (
        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
          {props.helper}
        </Typography>
      )}
    </Box>
  );
}
