import { Box, Tab, Tabs, Modal, TextField, Button, IconButton, FormControlLabel, Checkbox, Chip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { updateCollection } from "../../redux-store/currentUserActions";
import AuthorizationComponent from "./AuthorizationComponent"
import VariablesComponent from "../Common/VariablesComponent"
import OverviewComponent from "./OverviewComponent";

const ProjectPage = ({collection , folder}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [settings, setSettings] = useState({
    allowedHosts: collection?.allowed_hosts || [],
    blockedPaths: collection?.blocked_paths || [],
    piiMaskingKeys: collection?.pii_masking || [],
    autoDocumentation: collection?.auto_documentation || false,
    enableMonitoring: collection?.enable_monitoring || false,
  });
  const [tempSetting, setTempSetting] = useState({ allowedHosts: '', blockedPaths: '', piiMaskingKeys: '' });
  const dispatch = useDispatch();

  const handleSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setSettings({ ...settings, [name]: checked });
    } else {
      setTempSetting({ ...tempSetting, [name]: value });
    }
  };

  const handleAddTag = (field) => {
    if (tempSetting[field]) {
      setSettings({ ...settings, [field]: [...settings[field], tempSetting[field]] });
      setTempSetting({ ...tempSetting, [field]: '' });
    }
  };

  const handleDeleteTag = (field, tagToDelete) => {
    setSettings({ ...settings, [field]: settings[field].filter(tag => tag !== tagToDelete) });
  };

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  const handleSettingsSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCollection({ settings: settings, id: collection?.id }));
    toggleSettingsModal();
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <OverviewComponent resource={collection}/>;
      case 1:
        return <AuthorizationComponent collection={collection}/>;
      case 2:
        return <VariablesComponent resource={collection} resourceType={'Collection'} />;
      default:
        return <OverviewComponent resource={collection}/>;
    }
  };

  useEffect(() => {
    setActiveTab(0);
  }, [collection?.id]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    p: 4,
  };

  const renderTagField = (label, fieldName) => (
    <Box>
      <TextField
        label={label}
        name={fieldName}
        value={tempSetting[fieldName]}
        onChange={handleSettingsChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleAddTag(fieldName);
          }
        }}
        fullWidth
        margin="normal"
      />
      <Box sx={{ color: 'gray', fontSize: '0.75rem' }}>
        Press Enter to add a tag
      </Box>
      <Box>
        {settings[fieldName].map((tag, index) => (
          <Chip key={index} label={tag} onDelete={() => handleDeleteTag(fieldName, tag)} style={{ margin: '5px' }} />
        ))}
      </Box>
    </Box>
  );

  const renderNumberField = (label, fieldName) => (
    <Box>
      <TextField
        label={label}
        name={fieldName}
        type="number"
        value={settings[fieldName].toString()}
        onChange={handleSettingsChange}
        fullWidth
        margin="normal"
      />
    </Box>
  );

  const getTabStyle = (index) => {
    return {
      minHeight: '32px',
      height: '32px',
      textTransform: 'none',
      color: activeTab === index ? '#fff' : '#575757',
      backgroundColor: activeTab === index ? '#6241D4' : '#e9e9e9',
      fontFamily: "Inter, Sans-serif",
      fontWeight: "550",
      fontSize: "12px"
    }
  }

  return (
    <Box sx={{ backgroundColor: '#fff', flexGrow: 1, height: 'calc(100vh - 100px)', marginLeft: '-5px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', paddingRight: '10px' }}>
        <Tabs className='tab-style' value={activeTab} onChange={handleTabChange} TabIndicatorProps={{ style: { display: "none" } }}>
          <Tab label="Overview" style={getTabStyle(0)} />
          <Tab label="Authorization" style={getTabStyle(1)} />
          <Tab label="Variables" style={getTabStyle(2)} />
        </Tabs>
        <IconButton onClick={toggleSettingsModal} sx={{ padding: 0, paddingRight: '8px', height: '44px' }}>
          <SettingsIcon />
        </IconButton>
      </Box>
      <Box sx={{ height: 'calc(100% - 50px)', padding: '0px 15px', paddingBottom: 10, overflowY: 'auto' }}>{renderTabContent()}</Box>
      <Modal open={isSettingsModalOpen} onClose={toggleSettingsModal}>
        <Box sx={modalStyle}>
          <form onSubmit={handleSettingsSubmit}>
            {renderTagField("Allowed Hosts", "allowedHosts")}
            {renderTagField("Blocked Paths", "blockedPaths")}
            {renderTagField("PII Masking Keys", "piiMaskingKeys")}
            <FormControlLabel
              control={<Checkbox checked={settings.autoDocumentation} onChange={handleSettingsChange} name="autoDocumentation" />}
              label="Auto Documentation"
              style={{ marginTop: '20px' }}
            />
            <FormControlLabel
              control={<Checkbox checked={settings.enableMonitoring} onChange={handleSettingsChange} name="enableMonitoring" />}
              label="Enable Monitoring"
              style={{ marginTop: '20px' }}
            />
            <Button type="submit" variant="contained" style={{ marginTop: '20px', display: 'block', width: '100%' }}>
              Save Settings
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}

export default ProjectPage