import React, { useState, useEffect } from 'react';

import TestScenarioListPage from './TestScenarioListPage';
import TestScenarioDetail from './TestScenarioDetail';
import TestScenarioBuildLogs from '../TestScenarioBuildLogs/TestScenarioBuildLogs';

const TestScenariosPage = function({testScenarioId, buildId}) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (testScenarioId && buildId) {
      setCurrentView("testScenarioBuild");
    } else if (testScenarioId) {
      setCurrentView("testScenarioDetail");
    } else {
      setCurrentView("testScenariosList");
    }
  }, [testScenarioId, buildId]);



  if (currentView === 'testScenariosList') {
    return <TestScenarioListPage />
  }

  if (currentView === 'testScenarioDetail') {
    return <TestScenarioDetail testScenarioId={testScenarioId} />
  }

  if (currentView === 'testScenarioBuild') {
    return <TestScenarioBuildLogs buildId={buildId} />
  }

  return null;
}

export default TestScenariosPage;
