import React, {useEffect, useState, useRef} from 'react';
import { Box, Toolbar, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { getBuildLogs } from '../../../redux-store/testScenarioBuildReducers/testScenarioBuildActions';

import BuildLogs from './BuildLogs';

const BuildLogsContainer = ({buildId, testScenarioId, getStatus}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.testScenarioBuild.isLoading);

  const fetchBuildLogs = async (parentLogId) => {
    if (buildId) {
      const buildLogsResponse = await dispatch(getBuildLogs({buildId: buildId, parentLogId: parentLogId}));
      return buildLogsResponse?.payload?.test_build_logs;
    }
  }

  const goToAllBuilds = () => {
    /**
     * const queryParams = pageNumber ? `?page=${pageNumber}` : '';
     * history.push(`/test-scenarios/${testScenarioId}/build/${queryParams}`);
     */
  }

  return (
    <Box sx={{width: "100%"}}>
      <BuildLogs goToAllBuilds={goToAllBuilds} fetchBuildLogs={fetchBuildLogs} initialFetch isLoading={isLoading} isExpanded={false} getStatus={getStatus} hideAllBuilds root />
    </Box>
  )
}

export default BuildLogsContainer;
