import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import MainLayout from '../../layouts/MainLayout';
import CustomButton from '../Common/CustomButton';
import DataSetList from './DataSetList';
import DeleteDialog from '../Common/DeleteDialog';

import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import {getDataSetList, deleteDataSet} from "../../redux-store/dataSetReducers/dataSetActions";


const DataSetListPage = ({ projectId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = useSelector((state) => state.dataSet.totalPages);
  const total = useSelector((state) => state.dataSet.total);
  const isLoading = useSelector((state) => state.dataSet.isLoading);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletableDataSetId, setDeletableDataSetId] = useState('');
  const initialMount = useRef(true);
  
  useEffect(() => {
    fetchDataSets();
  }, []);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }
    fetchDataSets();
  }, [pageNumber]);

  const fetchDataSets = async (page) => {
    await dispatch(getDataSetList({ projectId: projectId, pageNumber: page || pageNumber, pageSize: 10 }));
  };

  const handleAction = async (actionType, id) => {
    if (actionType === "edit") {
      history.push(`/data-sets/${id}`);
    } else {
      setDeletableDataSetId(id);
      onToggleDeleteDialog();
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const handleCreateDataSet = () => {
    history.push(`/data-sets/new-data-set`);
  }

  const onDeleteConfirm = async () => {
    const response = await dispatch(deleteDataSet({dataSetId: deletableDataSetId}));
    if (response?.payload) {
      openSnackbar({ message: "Data set deleted successfully!", severity: 'success' });
      setDeletableDataSetId("");
      onToggleDeleteDialog();
      setPageNumber(1);
      fetchDataSets(1);
    } else {
      openSnackbar({ message: "Data set deletion failed!", severity: 'error' });
    }
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deletableDataSetId) setDeletableDataSetId('');
  };

  const renderConfirmDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        title="Delete Data Set!"
        deleteBtnLabel="Delete Permanently"
        secondaryBtnLabel="Cancel"
        onDeleteAction={onDeleteConfirm}
        onSecondaryAction={onToggleDeleteDialog}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Are you sure you want to permanently delete this Data Set? This can not be undone!
        </Typography>
      </DeleteDialog>
    );
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Data Sets List"
      subtitleText={`Showing ${total || 0} data set(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <>
          {/* <SearchWithIcon
            placeholder="Search test scenarios"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{marginRight: "16px"}}
          /> */}
          <CustomButton startIcon={<Add />} onClick={handleCreateDataSet}>
            Create Data Set
          </CustomButton>
        </>
      }
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto"}}>
        <DataSetList isLoading={isLoading} onAction={handleAction} />
      </Box>
      {deleteDialogOpen && renderConfirmDialog()}
    </MainLayout>
  );
};

export default DataSetListPage;
