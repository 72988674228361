import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, TextField, Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography, IconButton, MenuList, MenuItem } from "@mui/material";
import DeleteDialog from '../Common/DeleteDialog';
import CustomTable from "../Common/CustomTable";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import useSearchParams from "../../hooks/useSearchParams";
import {Add, MoreVert} from "@mui/icons-material";
import CustomMenu from '../Common/CustomMenu';
import {
  deletePublishedQuestionAnswer,
  getPublishedQuestionList,
  getPublishedQuestionsDetail,
  publishQuestionAnswer,
  updatePublishedQuestionAnswer
} from '../../redux-store/chatsReducers/chatsActions';
import MainLayout from '../../layouts/MainLayout';
import CustomButton from '../Common/CustomButton';
import TextEditor from "../Common/TextEditor/TextEditor";
import LoadingOverlay from '../Common/LoadingOverlay';

const FAQList = function ({ project }) {
  const { openSnackbar } = useSnackbar();
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const page = parseInt(searchParams.get('page') || '1');
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [faqList, setFaqList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalFaqs, setTotalFaqs] = useState(0);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishContent, setPublishContent] = useState({question: "", answer: ""});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteFAQId, setDeleteFAQId] = useState('');
  const [anchorEls, setAnchorEls] = useState({});
  const [isLoadingFAQDetail, setIsLoadingFAQDetail] = useState(false);

  useEffect(() => {
    fetchFaqList();
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const onClick = (faqSlug) => {
    history.push(`/faqs/${faqSlug}`);
  }

  const onDeleteFAQ = (id) => {
    setDeleteFAQId(id);
    onToggleDeleteDialog();
  };

  const onDeleteConfirm = async () => {
    const response = await dispatch(
      deletePublishedQuestionAnswer({faqId: deleteFAQId})
    );
    if (!response?.error && response?.payload) {
      fetchFaqList();
      openSnackbar({
        message: "FAQ deleted successfully!",
        severity: "success",
      });
    } else if (response?.error) {
      openSnackbar({
        message: "FAQ deleting failed! Please try again!",
        severity: "error",
      });
    }
    onToggleDeleteDialog();
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deleteFAQId) setDeleteFAQId('');
  };

  const renderConfirmDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        title="Delete FAQ?"
        deleteBtnLabel="Delete"
        secondaryBtnLabel="Cancel"
        onDeleteAction={onDeleteConfirm}
        onSecondaryAction={onToggleDeleteDialog}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Are you sure you want to delete this FAQ?
        </Typography>
      </DeleteDialog>
    );
  };

  const onClose = () => {
    setIsPublishModalOpen(false);
    setPublishContent({question: "", answer: "", faqId: ""});
  }

  const onPublishFaq = (questionText="", answerText="") => {
    setIsPublishModalOpen(true);
    setPublishContent({question: questionText, answer: answerText});
  };

  const onPublishConfirm = async () => {
    let response;
    if (publishContent?.faqId) {
      response = await dispatch(
        updatePublishedQuestionAnswer({ questionText: publishContent?.question?.trim() || "", answerText: publishContent?.answer?.trim() || "", faqId: publishContent.faqId })
      );
    } else {
      response = await dispatch(
        publishQuestionAnswer({ projectId: project.id, questionText: publishContent?.question?.trim() || "", answerText: publishContent?.answer?.trim() || "" })
      );
    }
    if (!response?.error && response?.payload) {
      fetchFaqList();
      openSnackbar({
        message: publishContent.faqId ? "Question updated successfully!" : "Question published successfully!",
        severity: "success",
      });
      onClose();
    } else if (response?.error) {
      openSnackbar({
        message: publishContent.faqId ? "Question updation failed! Please try again!" : "Question publishing failed! Please try again!",
        severity: "error",
      });
    }
  }

  const onQuestionChange = (e) => {
    setPublishContent(state => ({...state, question: e.target.value}))
  }

  const onAnswerChange = (value) => {
    setPublishContent(state => ({...state, answer: value}))
  }

  const onEditFAQ = async (faqSlug, faqId) => {
    setIsLoadingFAQDetail(true);
    setIsPublishModalOpen(true);
    const response = await dispatch(getPublishedQuestionsDetail({faqSlug: faqSlug}));
    if (response?.payload) {
      setPublishContent({question: response?.payload?.question_content || "", answer: response?.payload?.answer_content || "", faqId: faqId})
      setIsLoadingFAQDetail(false);
    } else {
      setIsLoadingFAQDetail(false);
      openSnackbar({
        message: "Something went wrong. Please try again!",
        severity: "error",
      });
    }
  }

  const fetchFaqList = async () => {
    setIsLoading(true);
    const response = await dispatch(
      getPublishedQuestionList({
        projectId: project.id,
        pageNumber: pageNumber,
        pageSize: 10
      })
    );

    if (response?.payload) {
      setFaqList([...response.payload?.question_answers] || []);
      setTotalPages(response.payload?.total_pages);
      setTotalFaqs(response.payload?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openSnackbar({
        message: "Something went wrong. Please try again!",
        severity: "error",
      });
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const renderList = () => {
    if (faqList.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }
    return (
      <CustomTable
        headers={[
          {
            id: 'title',
            type: 'text',
            text: 'Title',
            sx: {width: 'calc(100% - 540px)', padding: "18px 20px !important"},
          },
          {
            id: 'time',
            type: 'text',
            text: 'Date Time',
            sx: {width: '250px', padding: "18px 20px !important"}
          },
          {
            id: 'actions',
            type: 'text',
            text: 'Actions',
            align: 'center',
          }
        ]}
        rows={faqList.map(
          ({
            id,
            created_at,
            question_content,
            slug
          }) => ({
            id,
            cells: [
              {
                id: id,
                type: 'text',
                text: question_content ?? "",
                sx: {width: 'calc(100% - 540px)', cursor: 'pointer', padding: "18px 20px !important"},
                onClick: () => {
                  onClick(slug)
                },
              },
              {
                id: "ipAddress-"+id,
                type: 'text',
                text: created_at ? new Date(created_at * 1000).toUTCString() : "",
                sx: {width: '180px', cursor: 'pointer', padding: "18px 20px !important"},
                onClick: () => {
                  onClick(slug)
                },
              },
              {
                id: 'actions-' + id,
                type: 'component',
                align: 'center',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem onClick={() => {onEditFAQ(slug, id); handleClose(id);}}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => {onDeleteFAQ(id); handleClose(id);}}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: '50px' },
              },
            ],
          })
        )}
      />
    )
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="FAQs"
      subtitleText={`Showing ${totalFaqs || "-"} FAQ(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <CustomButton startIcon={<Add />} onClick={() => {onPublishFaq("", "")}}>
          Create FAQ
        </CustomButton>
      }
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto", px: 1}}>
        {
          !isLoading && (
            renderList()
          )
        }
      </Box>
      <Dialog fullWidth maxWidth="md" onClose={onClose} open={isPublishModalOpen}>
        <DialogTitle sx={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "18px"}}>Publish a question answer.</DialogTitle>
        <DialogContent>
          {
            !isLoadingFAQDetail ? (
              <>
                <Box sx={{mb: 2}}>
                  <h5>Question</h5>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    defaultValue={publishContent.question}
                    onChange={onQuestionChange}
                  />
                </Box>
                <Box sx={{mb: 2}}>
                  <h5>Answer</h5>
                  <TextEditor initialContent={publishContent.answer} onChange={onAnswerChange} />
                </Box>
              </>
            ) : <LoadingOverlay isLoading={isLoadingFAQDetail} />
          }
        </DialogContent>
        <DialogActions sx={{p: "20px 24px"}}>
          <Button variant="outlined" onClick={onClose} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onPublishConfirm} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}} disabled={!publishContent?.question || !publishContent?.answer || !publishContent?.question?.trim() || !publishContent?.answer?.trim()}>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
      {deleteDialogOpen && renderConfirmDialog()}
    </MainLayout>
  );
};

export default FAQList;
