import React from 'react';

import "../../styles/components/Common/CustomDivider.scss";

const CustomDivider = ({horizontal=false}) => {
  const classNames = ["customDivider"];
  if (horizontal) {
    classNames.push("horizontal");
  } else {
    classNames.push("vertical");
  }
  return (
    <hr className={classNames.join(" ")} />
  )
}

export default CustomDivider;
