
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Box, Tabs, Tab, Menu, MenuItem, Typography, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { ExpandMore, FiberManualRecord } from '@mui/icons-material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import LoadingOverlay from '../../Common/LoadingOverlay'


const Response = ({ entity, response }) => {
  const [currentResponse, setCurrentResponse] = useState(response?.body);
  const responseStatus = response?.status || 200;
  const responseTime = response?.time || 0;
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);

  const [format, setSelectedFormat] = useState('JSON');
  const [selectedTab, setSelectedTab] = useState('pretty');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentResponse(response?.body || {});
  }, [response]);

  const tabsData = [
    { label: "Pretty", component: "pretty" },
    { label: "Headers", component: "response_headers" }
  ];

  const handleFormatClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFormatClose = (option) => {
    setSelectedFormat(option);
    setAnchorEl(null);
  };

  const renderTabContent = () => {
    switch (format) {
      case 'JSON':
        // return <ReactJson src={ currentResponse } name={false} displayObjectSize={false} displayDataTypes={false} enableClipboard={false} />
        return <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-all' }}>{JSON.stringify(currentResponse, null, 2)}</pre>;
        return <SyntaxHighlighter language="json" style={docco}>
          {JSON.stringify(currentResponse, null, 2)}
        </SyntaxHighlighter>;
      case 'XML':
      case 'Text':
      case 'HTML':
        return <pre>{JSON.stringify(currentResponse, null, 2)}</pre>;
      case 'Auto':
        try {
          return <SyntaxHighlighter language="json" style={docco}>
            {JSON.stringify(currentResponse, null, 2)}
          </SyntaxHighlighter>;
        } catch (error) {
          return <pre>{JSON.stringify(currentResponse, null, 2)}</pre>;
        }
      default:
        return <SyntaxHighlighter language="json" style={docco}>
          {JSON.stringify(currentResponse, null, 2)}
        </SyntaxHighlighter>;
    }
  };

  const ResponseHeadersTable = () => {
    const headers = response?.headers || {};

    return (
      <TableContainer sx={{ borderRadius: '5px', marginBottom: 5 }}>
        <Table size='small' aria-label="simple" sx={{ border: '1px solid' }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(headers).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box style={{ width: '100%', padding: '16px', display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6"><strong>Response</strong></Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{ mr: 1 }}>Status Code: {responseStatus}</Typography>
          <Typography variant="body2">Time: {responseTime} ms</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            sx={{ height: '37px', minHeight: '37px', border: '1px solid #e9e9e9', borderRadius: '5px' }}
          >
            {tabsData.map((tab) => (
              <Tab
                key={tab.component}
                label={tab.label}
                value={tab.component}
                sx={{ textTransform: 'capitalize', height: '37px', minHeight: '37px', color: '#575757 !important', backgroundColor: 'transparent' }}
              />
            ))}
          </Tabs>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleFormatClose(format)}
          >
            {['JSON', 'XML', 'HTML', 'Text', 'Auto'].map((option) => (
              <MenuItem key={option} onClick={() => handleFormatClose(option)} style={{ fontSize: '10px' }}>
                {option}
              </MenuItem>
            ))}
          </Menu>

          {selectedTab === 'pretty' && (
            <Button
              endIcon={<ExpandMore />}
              onClick={handleFormatClick}
              style={{
                color: '#575757',
                borderColor: '#e9e9e9',
                marginLeft: '10px'
              }}
            >
              {format}
            </Button>
          )}
        </Box>
      </Box>

      <Box flexGrow={1} sx={{ marginTop: '10px', overflow: 'auto' }}>
        {selectedTab === 'pretty' && currentResponse && renderTabContent()}
        {selectedTab === 'response_headers' && <ResponseHeadersTable />}
      </Box>

      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
}

export default Response;
