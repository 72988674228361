import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box} from "@mui/material";

import MetadataHeader from '../Common/MetadataHeader';

import { updateTestSuite } from '../../redux-store/testSuiteReducers/testSuiteActions';

const TestSuiteHeader = ({testSuiteId}) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(state => state.testSuite.isSaving);

  const handleTestSuiteUpdate = async (data) => {
    return dispatch(updateTestSuite({testSuiteId: testSuiteId, data: data}));
  }

  return (
    <Box className="testSuiteHeader">
      <MetadataHeader id={testSuiteId} onUpdate={handleTestSuiteUpdate} type="testSuite" isLoading={isSaving} />
    </Box>
  )
}

export default TestSuiteHeader;
