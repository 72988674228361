import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";

import CustomButton from "../Common/CustomButton";
import LoadingOverlay from "../Common/LoadingOverlay";

import { attachPlansWithSuites } from "../../redux-store/testSuitePlanReducers/testSuitePlanActions";
import { getAllTestPlans } from "../../redux-store/testPlanReducers/testPlanActions";

const AttachTestPlanModal = ({ projectId, open, onClose, onCancel }) => {
  const dispatch = useDispatch();
  const selectedTestSuites = useSelector(
    (state) => state.testSuite.selectedTestSuites
  );
  const allTestPlans = useSelector((state) => state.testPlan.testPlansList);
  const isLoading = useSelector((state) => state.testPlan.isLoading);
  const [errors, setErrors] = useState({});
  const [testPlan, setTestPlan] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchAllTestPlans();
    validate(["suite"]);
  }, []);

  const fetchAllTestPlans = async () => {
    await dispatch(getAllTestPlans({projectId: projectId, pageNumber: 1, pageSize: 100000}))
  }

  const onTestPlanChange = (event, testPlan) => {
    setTestPlan((prevState) => testPlan);
  };

  const handleCancel = () => {
    setTestPlan(null);
    setErrors({});
    onCancel?.();
  };

  const validate = (tests = ["suite", "plan"]) => {
    let isValid = true;
    if (
      (!selectedTestSuites || selectedTestSuites?.length === 0) &&
      tests.indexOf("suite") !== -1
    ) {
      setErrors({ suite: "Please select atleast one test suite." });
      isValid = false;
    }
    if (!testPlan?.id && tests.indexOf("plan") !== -1) {
      setErrors({ plan: "Invalid test plan." });
      isValid = false;
    }
    return isValid;
  };

  const onAddConfirm = async () => {
    setIsSaving(true);
    if (!validate()) {
      setIsSaving(false);
      return;
    }
    await dispatch(
      attachPlansWithSuites({
        testPlanIds: [testPlan?.id],
        testSuiteIds: [...selectedTestSuites.map((plan) => plan.id)],
      })
    );
    setIsSaving(false);
    handleCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attach-test-plan-dialog-title"
    >
      <DialogTitle id="attach-test-plan-dialog-title" sx={{ fontSize: 20 }}>
        Add to existing test plan
      </DialogTitle>
      <DialogContent
        sx={{
          width: "500px",
          height: "auto",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <span style={{ fontSize: "14px" }} className="text_caption_error">
          {errors.plan || errors.suite}
        </span>
        <h5 style={{ marginTop: 16 }}>Select Test Plan</h5>
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <Autocomplete
            options={allTestPlans}
            getOptionLabel={(option) => option.title}
            onChange={onTestPlanChange}
            value={testPlan}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ mt: 1 }}
                size="small"
                variant="outlined"
              />
            )}
          />
        )}
        <h5 style={{ marginTop: 32, marginBottom: 8 }}>Selected Test Suites</h5>
        {selectedTestSuites.map((suite) => (
          <Chip
            key={suite.id}
            id={suite.id}
            label={suite.title}
            size="small"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {!selectedTestSuites?.length && <h6>None selected</h6>}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <CustomButton
          onClick={onAddConfirm}
          color="primary"
          disabled={Boolean(!testPlan?.id) || !!errors.suite}
        >
          Add test suites to test plan
        </CustomButton>
        <CustomButton
          onClick={handleCancel}
          color="secondary"
          disabled={isSaving}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AttachTestPlanModal;
