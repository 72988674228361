import {createSlice} from '@reduxjs/toolkit';

const testSuiteScenarioSlice = createSlice({
    name: "testSuiteScenarioSlice",
    initialState: {
        isLoading: false,
        errors: {},
        total: 0,
        totalPages: 0
    },
    reducers: {
        updateLoadError: (state, action) => {
            return {
                ...state,
                errors: action.payload.error_message
            };
        },
        setLoadingState: (state, action) => {
            return {
                ...state,
                isLoading: action.payload
            }
        },
        setSavingState: (state, action) => {
            return {
                ...state,
                isSaving: action.payload
            }
        },
        // setTestRules: (state, action) => {
        //     return {
        //         ...state,
        //         testRules: {
        //             ...state.testRules,
        //             [action.payload.testCaseSetId]: action.payload.testRules
        //         }
        //     }
        // }
    }
})

export const {
    updateLoadError,
    setLoadingState,
    setSavingState,
    // setTestRules
} = testSuiteScenarioSlice.actions;

export default testSuiteScenarioSlice.reducer;