import { FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/system';

const CustomIcon = styled('span')(() => ({
  borderRadius: "4px",
  width: 16,
  height: 16,
  boxShadow: 'none',
  background: "white", // Background color for the unchecked state
  border: "1px solid grey",
  'input:disabled ~ &': {
    boxShadow: 'none',
    border: "1px solid #eeeeee",
    background: "rgba(0, 0, 0, .02)",
  },
}));

const CustomCheckedIcon = styled(CustomIcon)(() => ({
  border: "none",
  backgroundColor: '#1976d2', // Blue background color for the checked state
  position: 'relative',
  '&::before': {
    display: 'block',
    content: '""',
    position: 'absolute',
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    width: 16, // Increase width of the checkmark icon
    height: 16, // Increase height of the checkmark icon
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M9 16.2l-4.2-4.2a1 1 0 10-1.4 1.4l5 5c.2.2.5.3.7.3s.5-.1.7-.3l10-10a1 1 0 00-1.4-1.4L9 16.2z' fill='%23fff'/%3E%3C/svg%3E\")", // White checkmark with thicker lines
    backgroundSize: 'contain', // Ensures the SVG scales properly
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: 'translate(-50%, -50%)', // Center the checkmark
  },
}));

const CustomCheckbox = ({ label = '', ...props }) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            {...props}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{
              p: 0,
              mr: 1,
              my: 1,
              '&:hover': { bgcolor: 'transparent' },
            }}
            checkedIcon={<CustomCheckedIcon />}
            icon={<CustomIcon />}
          />
        }
        label={label}
        sx={{
          margin: 0,
        }}
      />
    </div>
  );
};

export default CustomCheckbox;
