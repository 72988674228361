import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
} from "@mui/material";

import CustomButton from "../Common/CustomButton";

import { createTestSuite } from "../../redux-store/testSuiteReducers/testSuiteActions";
import { attachScenariosWithSuites } from "../../redux-store/testSuiteScenarioReducers/testSuiteScenarioActions";
import coreUtils from "../../utils/coreUtils";

const CreateTestSuiteModal = ({ projectId, open, onClose, onCancel }) => {
  const dispatch = useDispatch();
  const selectedTestScenarios = useSelector(
    (state) => state.testScenario.selectedTestScenarios
  );
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    validate(["scenario"]);
  }, []);

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCancel = () => {
    setTitle("");
    setErrors({});
    onCancel?.();
  };

  const validate = (tests = ["scenario", "title"]) => {
    let isValid = true;
    if (
      (!selectedTestScenarios || selectedTestScenarios?.length === 0) &&
      tests.indexOf("scenario") !== -1
    ) {
      setErrors({ scenario: "Please select atleast one test scenario." });
      isValid = false;
    }
    if (
      coreUtils.isStringInvalidOrBlank(title) &&
      tests.indexOf("title") !== -1
    ) {
      setErrors({ title: "Please enter a valid Title" });
      isValid = false;
    }
    return isValid;
  };

  const onCreateConfirm = async () => {
    setIsSaving(true);
    if (!validate()) {
      setIsSaving(false);
      return;
    }
    const reponse = await dispatch(
      createTestSuite({ projectId: projectId, title: title })
    );
    if (reponse?.payload?.id) {
      await dispatch(
        attachScenariosWithSuites({
          testSuiteIds: [reponse.payload.id],
          testScenarioIds: [...selectedTestScenarios.map((test) => test.id)],
        })
      );
    } else {
      //toast with error message
    }
    setIsSaving(false);
    setTitle("");
    setErrors({});
    onCancel?.();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attach-test-suite-dialog-title"
    >
      <DialogTitle id="attach-test-suite-dialog-title" sx={{ fontSize: 20 }}>
        Create new test suite
      </DialogTitle>
      <DialogContent
        sx={{
          width: "500px",
          height: "auto",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <span style={{ fontSize: "14px" }} className="text_caption_error">
          {errors.title || errors.scenario}
        </span>
        <h5 style={{ marginTop: 16 }}>Title</h5>
        <TextField
          sx={{ mt: 1, width: 1 }}
          size="small"
          variant="outlined"
          value={title}
          onChange={handleChange}
        />
        <h5 style={{ marginTop: 32, marginBottom: 8 }}>
          Selected Test Sceanrios
        </h5>
        {selectedTestScenarios.map((test) => (
          <Chip
            key={test.id}
            id={test.id}
            label={test.title}
            size="small"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {!selectedTestScenarios?.length && <h6>None selected</h6>}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <CustomButton
          onClick={onCreateConfirm}
          color="primary"
          disabled={
            coreUtils.isStringInvalidOrBlank(title) || !!errors.scenario
          }
        >
          Create new test suite
        </CustomButton>
        <CustomButton
          onClick={handleCancel}
          color="secondary"
          disabled={isSaving}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTestSuiteModal;
