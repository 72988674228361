import React, {useEffect, useState} from 'react';
import { Box } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';

import MainLayout from '../../layouts/MainLayout';
import TabsComponent from '../TestScenarios/TabsComponent';
import TestScenarioBuilds from "./TestScenarioBuilds";
import TestSuiteBuilds from "./TestSuiteBuilds";
import TestPlanBuilds from "./TestPlanBuilds";

import { getAllVariableSets } from '../../redux-store/variableSetReducers/variableSetActions';

const BuildReportsPage = ({projectId}) => {
  const dispatch = useDispatch();
  const tabLabels = ["Test Scenario Builds", "Test Suite Builds", "Test Plan Builds"];
  const [activeTab, setActiveTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  // TODO : use create selector to create nested state access
  const {totalPages, total, isLoading} = useSelector(state => {
    let tabType = "testScenario";
    if (activeTab === 1) tabType = "testSuite";
    if (activeTab === 2) tabType = "testPlan";
    const stateObj = state[`${tabType}Build`];
    return {
      totalPages: stateObj.totalPages,
      total: stateObj.total,
      isLoading: stateObj.isLoading
    }
  });

  useEffect(() => {
    getEnvironmentVariableSets();
  }, []);

  const getEnvironmentVariableSets = async () => {
    await dispatch(getAllVariableSets({ projectId: projectId, isEnvironment: true, pageNumber: 1, pageSize: 10000 }));
  };

  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex);
    setPageNumber(1);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (<TestSuiteBuilds projectId={projectId} setPageNumber={setPageNumber} pageNumber={pageNumber} />);
      case 2:
        return <TestPlanBuilds projectId={projectId} setPageNumber={setPageNumber} pageNumber={pageNumber} />;
      default:
        return (<TestScenarioBuilds projectId={projectId} setPageNumber={setPageNumber} pageNumber={pageNumber} />);
    }
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Build reports"
      subtitleText="Use filter/s to get desired builds report"
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={(e, value) => {setPageNumber(value);}}
      contentStyle={{p: "16px"}}
    >
      <TabsComponent sx={{px: 4}} activeTab={activeTab} customTabStyle={{width: "auto"}} handleTabChange={handleTabChange} tabSubText={` (${total})`} tabLabels={tabLabels} tabsStyle={{width: "fit-content", minHeight: "36px"}} />
      <Box>
        {renderTabContent()}
      </Box>
    </MainLayout>
  )
}

export default BuildReportsPage;
