import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { ReportProblem } from '@mui/icons-material';

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  actionText,
  hasActions = true,
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <ReportProblem color="warning" sx={{ marginRight: '10px' }} /> {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {hasActions && (
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} color="error">
            {actionText || 'Delete'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
